import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MemberUserNotesService extends Service {
    constructor() {
        super();
        this.baseURL = 'memberuser/notes/';
    }


    async getNotesAndAllergies() {
        return fetch(SERVER_URL + this.baseURL , {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateWithoutNotification( orderNotes, allergies) {
        let data = {
            orderNotes:orderNotes,
            allergies:allergies
        };
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
        });
    }



}