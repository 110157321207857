import React from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {AvGroup, AvForm} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import MemberUserCardService from "../../shared/service/MemberUserCardService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";

const defaultMemberUserCard = {
    id: null,
    expireDate: '',
    crossReference:'',
    cardNo: '',
    cardHolderName: '',
    cardType: 'VISA',
    defaultCard: true
};

export default class MemberUserCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberUserCard: defaultMemberUserCard,
            memberUserCardTemp: defaultMemberUserCard,
            cardList: [],
            memberUserCardId: null
        }
        this.memberUserCardService = new MemberUserCardService();

    }

    componentDidMount() {
        this.refreshCombo();
    }

    refreshCombo() {
        this.memberUserCardService.findAll().then(response => this.setState({cardList: response}));
    }

    __handleChangeList = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.memberUserCardService.findById(value).then(response => this.setState({
                memberUserCard: response,
                memberUserCardTemp: Object.assign({}, response),
                memberUserCardId: value,
                memberUserCardSaveButtonRender: false
            }));


        } else {
            this.setState({
                memberUserCardSaveButtonRender: false,
                memberUserCard: defaultMemberUserCard,
                memberUserCardTemp: defaultMemberUserCard,
                memberUserCardId: null,

            });
        }
    }

    render() {
        return (
            <AvForm>

                <Row >
                    <Col md={3}>
                    </Col>

                    <Col md={4}>


                        <Fieldset legend={"My Cards"}>
                            <Row>
                                <Col md="12">
                                    <AvField
                                        type="select"
                                        disabled={false}
                                        className="form-control"
                                        name="tax"
                                        value={this.state.memberUserCardId}
                                        onChange={this.__handleChangeList}>

                                        <option value='' key=''/>
                                        {this.state.cardList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                    {!GeneralUtils.isNullOrEmpty(this.state.memberUserCardId) &&
                                        <Button color="outline-primary"
                                                style={{marginRight: '5px', height: '30px', width: '160px'}}
                                                onClick={() => {
                                                    this.memberUserCardService.updateDefaultCard(this.state.memberUserCardId)
                                                        .then((response) => {
                                                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                                this.setState({
                                                                    memberUserCardSaveButtonRender: false,
                                                                    memberUserCardId: null,
                                                                    memberUserCard: defaultMemberUserCard,
                                                                    memberUserCardTemp: defaultMemberUserCard

                                                                });
                                                                this.refreshCombo();
                                                            }
                                                        });

                                                }}>
                                            <Translate contentKey="entity.action.cancel">Set as default</Translate>
                                        </Button>
                                    }


                                    <ModalConfirm header='Warning' ok='Delete' onOkClick={this.deleteCard}
                                                  onCancelClick={() => this.setState({displayDeleteConfirmDialog: false})}
                                                  show={this.state.displayDeleteConfirmDialog}
                                                  message={this.state.noticeResponse}/>
                                    {!GeneralUtils.isNullOrEmpty(this.state.memberUserCardId) &&
                                        <Button color="outline-danger"
                                                style={{marginRight: '5px', height: '30px', width: '160px'}}
                                                onClick={() => {
                                                    this.showDeleteConfirmDialog();
                                                }}>
                                            <Translate contentKey="entity.action.delete">Remove</Translate>
                                        </Button>
                                    }

                                </Col>
                            </Row>

                        </Fieldset>

                        <Fieldset legend={"Card Details"}>

                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">Card No
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>

                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="cardNo"
                                            disabled={!this.state.memberUserCardSaveButtonRender}
                                            value={this.state.memberUserCard.cardNo}
                                            onChange={this.__handleChange}>
                                        </AvField>

                                    </AvGroup>
                                </Col>
                            </Row>


                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">Expire Date
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="expireDate"
                                            disabled={!this.state.memberUserCardSaveButtonRender}
                                            value={this.state.memberUserCard.expireDate}
                                            onChange={this.__handleChange}>
                                        </AvField>
                                    </AvGroup>
                                </Col>



                            </Row>

                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">Card Holder Name
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="cardHolderName"
                                            disabled={!this.state.memberUserCardSaveButtonRender}
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 100 characters in this field."
                                                }
                                            }}
                                            value={this.state.memberUserCard.cardHolderName}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>
                            </Row>



                        </Fieldset>

                    </Col>

                    <Col md={3}>
                    </Col>
                </Row>

            </AvForm>
        );
    }

    showDeleteConfirmDialog = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.memberUserCardId)) {
            return toast.warn("Select a card!");
        } else {
            this.setState({
                displayDeleteConfirmDialog: true,
                noticeResponse: "The card will be removed. Do you want to continue?"
            });
        }
    }

    deleteCard = () => {

        if (!GeneralUtils.isNullOrEmpty(this.state.memberUserCardId)) {
            this.memberUserCardService.delete(this.state.memberUserCardId)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            memberUserCardSaveButtonRender: false,
                            memberUserCardId: null,
                            memberUserCard: defaultMemberUserCard,
                            memberUserCardTemp: defaultMemberUserCard

                        });
                        this.refreshCombo();
                    }
                });
        }
    }

    saveMemberUserCard = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.memberUserCard.validMonth) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserCard.validYear) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserCard.cvv) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserCard.cardNo) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserCard.cardHolderName) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserCard.cardType)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.memberUserCard.id)) {
                this.memberUserCardService.update(this.state.memberUserCard)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                memberUserCardSaveButtonRender: false,
                                memberUserCard: response,
                                memberUserCardTemp: Object.assign({}, response)

                            });
                            this.refreshCombo();
                        }
                    });
            } else {
                this.memberUserCardService.create(this.state.memberUserCard)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                memberUserCardSaveButtonRender: false,
                                memberUserCard: response,
                                memberUserCardTemp: Object.assign({}, response)
                            });
                            this.refreshCombo();
                        }
                    });
            }
        }
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberUserCard = this.state.memberUserCard;
        memberUserCard[e.target.name] = value;
        this.setState({memberUserCard: memberUserCard});

    }

}


