import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {CREDIT_TYPE, STATUS} from "../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import MerchantBranchCreditService from "../../shared/service/MerchantBranchCreditService";
import {toast} from "react-toastify";

const defaultCredit = {
    id: '',
    status: 'ACTIVE',
    merchantBranchId: '',
    creditValue: '',
    description: '',
    creditType:''
};
export default class CreditSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            credit: defaultCredit,
            creditTemp: defaultCredit,
            pageMode: null
        }
        this.merchantBranchCreditService = new MerchantBranchCreditService();
    }

    render() {
        return (
            <div class="p-col-12">
                <Fieldset legend={"Credits"}>

                    <Row>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Description</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    disabled={!this.state.creditSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.credit.description}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">
                                        Status</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="status"
                                    disabled={!this.state.creditSaveButtonRender}
                                    value={this.state.credit.status}
                                    onChange={this.__handleChange}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">
                                        Credit Type</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="creditType"
                                    disabled={!this.state.creditSaveButtonRender}
                                    value={this.state.credit.creditType}
                                    onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {CREDIT_TYPE.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Credit (Must be a multiple of 100)</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>

                                <AvField
                                    type="number"
                                    value={this.state.credit.creditValue}
                                    disabled={!this.state.creditSaveButtonRender || !GeneralUtils.isNullOrEmpty(this.state.credit.id)}
                                    name="creditValue"
                                    min={100} max={1000000}
                                    className="input-group"
                                    onChange={this.__handleChange}
                                    showButtons/>


                            </AvGroup>


                        </Col>
                    </Row>



                    {!this.state.creditSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.setState({creditSaveButtonRender: true});
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.creditSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.saveCredit();
                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {this.state.creditSaveButtonRender && !GeneralUtils.isNullOrEmpty(this.state.credit.id) &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            let obj1 = this.state.creditTemp;
                            this.setState({
                                credit: Object.assign({}, obj1), creditSaveButtonRender: false
                            });
                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }

                    <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {

                        this.setState({
                            creditSaveButtonRender: false
                        });
                        this.props.closeDisplayDialog();

                    }}>
                        <Translate contentKey="entity.action.cancel">Close</Translate>
                    </Button>
                </Fieldset>
            </div>
        );

    }

    saveCredit = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.credit.creditValue) || GeneralUtils.isNullOrEmpty(this.state.credit.creditType)) {
            return toast.warn("Please fill the required fields!");
        } else  if (this.state.credit.creditValue < 100) {
            return toast.warn("Credit Must be Minimum 100");
        }  else  if (this.state.credit.creditValue%100!=0) {
            return toast.warn("Credit Must be a multiple of 100");
        }
        else
        {
            if (!GeneralUtils.isNullOrEmpty(this.state.credit.id)) {
                this.merchantBranchCreditService.update(this.state.credit)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                            this.setState({
                                credit: response,
                                creditTemp: Object.assign({}, response),
                                creditSaveButtonRender: false
                            })
                            this.props.refreshDataTable();

                        }
                    });
            } else {
                this.merchantBranchCreditService.create(this.state.credit)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                            this.setState({
                                credit: response,
                                creditTemp: Object.assign({}, response),
                                creditSaveButtonRender: false
                            })
                            this.props.refreshDataTable();

                        }
                    });
            }
        }

    }


    setData = (data) => {
        let saveButtonRender = false;
        if (GeneralUtils.isNullOrEmpty(data.id)) {
            saveButtonRender = true;
        }

        this.setState({credit: data, creditSaveButtonRender: saveButtonRender, creditTemp: Object.assign({}, data),});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultCredit);
        this.setState({credit: obj});
        // this.refForm.reset();
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let credit = this.state.credit;
        credit[e.target.name] = value;
        this.setState({credit: credit});
    }

}



