import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {
    BASKET_STATUS,
    BASKET_TYPE,
    ERROR_TYPE,
    MONTH,
    ORDER_STATUS,
    ORDER_TYPE,
    PAGE_MODE
} from "../../shared/utils/Constants";
import {TabView, TabPanel} from 'primereact/tabview';
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import OrderItemsColumn from "../../shared/component/dataTable/columns/OrderItemsColumn.json";
import OrderService from "../../shared/service/OrderService";
import Loading from "../../shared/component/others/Loading";
import {toast} from "react-toastify";
import MerchantUserService from "../../shared/service/MerchantUserService";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {InputTextarea} from "primereact/inputtextarea";

const defaultOrder = {
    id: null,
    orderNo: null,
    orderDate: null,
    basketStatus: null,
    syncStatus: null,
    orderStatus: null,
    acceptedDate: null,
    preparingDate: null,
    readyDate: null,
    deliveredDate: null,
    totalPrice: null,
    orderType: null,
    errorType: "INIT",
    errorMessage: null,
    basketDetailList: []
};
export default class OrdersDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: defaultOrder, pageMode: null, loading: false, driverList: []
        }
        this.orderService = new OrderService();
        this.merchantUserService = new MerchantUserService();
    }

    componentDidMount() {
        this.merchantUserService.getDrivers().then(response => this.setState({
            driverList: response
        }));
    }

    render() {
        return (
            <div>
                <ModalConfirm header='Warning' ok='OK' onOkClick={this.refund}
                              onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                              show={this.state.displayShowStatusConfirmDialog}
                              message={this.state.noticeResponse}/>
                <AvForm>
                    <Fieldset legend={"Order"}>

                        <TabView>
                            <TabPanel header="Order Detail">
                                <Row>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Order No</Translate>
                                            </Label>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="orderNo"
                                                disabled={true}
                                                value={this.state.order.orderNo}
                                                onChange={this.__handleChange}/>
                                        </AvGroup>
                                    </Col>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Order
                                                    Type</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="orderType"
                                                disabled={true}
                                                value={this.state.order.orderType}
                                                onChange={this.__handleChange}>
                                                {ORDER_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>



                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Basket Type</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="basketType"
                                                disabled={true}
                                                value={this.state.order.basketType}
                                                onChange={this.__handleChange}>
                                                {BASKET_TYPE.map(element => (<option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>





                                </Row>

                                <Row>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">
                                                    Status</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="basketStatus"
                                                disabled={true}
                                                value={this.state.order.basketStatus}
                                                onChange={this.__handleChange}>
                                                {BASKET_STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Order
                                                    Status</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="orderStatus"
                                                disabled={true}
                                                value={this.state.order.orderStatus}
                                                onChange={this.__handleChange}>
                                                {ORDER_STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Sync
                                                    Status</Translate>
                                            </Label>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="syncStatus"
                                                disabled={true}
                                                value={this.state.order.syncStatus}
                                                onChange={this.__handleChange}/>
                                        </AvGroup>
                                    </Col>
                                </Row>

                                {(this.state.order.orderType == "DELIVERY" || this.state.order.orderType == "COLLECTION") &&
                                    <Row>

                                        <Col md="4">
                                            <AvGroup>
                                                {this.state.order.orderType == "DELIVERY" &&

                                                    <Label for="tradingName">
                                                        <Translate contentKey="merchantBranch.tradingName">Deliver by
                                                        </Translate>
                                                    </Label>
                                                }

                                                {this.state.order.orderType == "COLLECTION" &&

                                                    <Label for="tradingName">
                                                        <Translate contentKey="merchantBranch.tradingName">Ready by
                                                        </Translate>
                                                    </Label>
                                                }
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="deliveryDateString"
                                                    disabled={true}
                                                    value={this.state.order.deliveryDateString}
                                                    onChange={this.__handleChange}/>
                                            </AvGroup>
                                        </Col>

                                        <Col md="4">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="merchantBranch.tradingName">Order
                                                        Date</Translate>
                                                </Label>

                                                <DateInput
                                                    name="orderDate"
                                                    onChange={this.__handleChange}
                                                    disabled={true}
                                                    value={this.state.order.orderDate}/>

                                            </AvGroup>
                                        </Col>

                                    </Row>
                                }

                                <Row>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Total
                                                    Price</Translate>
                                            </Label>

                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="totalPriceString"
                                                disabled={true}
                                                value={this.state.order.totalPriceString}
                                                onChange={this.__handleChange}/>


                                        </AvGroup>
                                    </Col>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Service
                                                    Charge</Translate>
                                            </Label>

                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="calculatedServiceChargeString"
                                                disabled={true}
                                                value={this.state.order.calculatedServiceChargeString}
                                                onChange={this.__handleChange}/>


                                        </AvGroup>
                                    </Col>
                                    {this.state.order.orderType == "DELIVERY" &&
                                        <Col md="4">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="merchantBranch.tradingName">Delivery
                                                        Fee</Translate>
                                                </Label>

                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="calculatedDeliveryFeeString"
                                                    disabled={true}
                                                    value={this.state.order.calculatedDeliveryFeeString}
                                                    onChange={this.__handleChange}/>


                                            </AvGroup>
                                        </Col>
                                    }
                                </Row>

                                <Row>

                                    {!GeneralUtils.isNullOrEmpty(this.state.order.customerFullName) &&
                                        <Col md="4">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="merchantBranch.tradingName">Customer
                                                    </Translate>
                                                </Label>
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="customerFullName"
                                                    disabled={true}
                                                    value={this.state.order.customerFullName}
                                                    onChange={this.__handleChange}/>
                                            </AvGroup>
                                        </Col>
                                    }
                                    <Col md="8">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Deliver Address
                                                </Translate>
                                            </Label>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="deliveryAddress"
                                                disabled={true}
                                                value={this.state.order.deliveryAddress}
                                                onChange={this.__handleChange}/>
                                        </AvGroup>
                                    </Col>
                                </Row>



                                <Row>


                                    <Col md={"4"}>

                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Order Notes
                                                </Translate>
                                            </Label>
                                            <AvField
                                                type="textarea"
                                                className="form-control textAreaPosmarketSmall"
                                                name="orderNotes"
                                                disabled={true}
                                                value={this.state.order.orderNotes}
                                                onChange={this.__handleChange}>
                                            </AvField>
                                        </AvGroup>


                                    </Col>

                                    <Col md={"4"}>

                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Allergies
                                                </Translate>
                                            </Label>
                                            <AvField
                                                type="textarea"
                                                className="form-control textAreaPosmarketSmall"
                                                name="allergies"
                                                disabled={true}
                                                value={this.state.order.allergies}
                                                onChange={this.__handleChange}>
                                            </AvField>
                                        </AvGroup>


                                    </Col>
                                </Row>

                                <Row>
                                    {((this.state.order.orderStatus == "ORDER_READY" && this.state.order.orderType == "DELIVERY") || (!GeneralUtils.isNullOrEmpty(this.state.order.driverId))) &&
                                        <Col md="4">
                                            <AvGroup>
                                                <Label for="mobilePhone">
                                                    <Translate
                                                        contentKey="contactManagement.mobilePhone">Driver</Translate>
                                                </Label>

                                                <AvField
                                                    type="select"
                                                    disabled={GeneralUtils.isNullOrEmpty(this.state.order.driverId) || (this.state.order.orderStatus == "ORDER_READY" && this.state.order.orderType == "DELIVERY") ? false : true}
                                                    className="form-control"
                                                    name="driverId"
                                                    value={this.state.order.driverId}
                                                    onChange={this.__handleChange}>

                                                    <option value='' key=''/>
                                                    {this.state.driverList.map(element => (
                                                        <option value={element.id} key={element.id}>
                                                            {element.fullName}
                                                        </option>))}
                                                </AvField>

                                            </AvGroup>
                                        </Col>
                                    }
                                </Row>
                            </TabPanel>
                            <TabPanel header="Dates & Fees">
                                <Row>


                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Accepted
                                                    Date</Translate>
                                            </Label>

                                            <DateInput
                                                name="acceptedDate"
                                                onChange={this.__handleChange}
                                                disabled={true}
                                                value={this.state.order.acceptedDate}/>

                                        </AvGroup>
                                    </Col>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Preparing
                                                    Date</Translate>
                                            </Label>

                                            <DateInput
                                                name="preparingDate"
                                                onChange={this.__handleChange}
                                                disabled={true}
                                                value={this.state.order.preparingDate}/>

                                        </AvGroup>
                                    </Col>

                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Ready
                                                    Date</Translate>
                                            </Label>

                                            <DateInput
                                                name="readyDate"
                                                onChange={this.__handleChange}
                                                disabled={true}
                                                value={this.state.order.readyDate}/>

                                        </AvGroup>
                                    </Col>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Delivered
                                                    Date</Translate>
                                            </Label>

                                            <DateInput
                                                name="deliveredDate"
                                                onChange={this.__handleChange}
                                                disabled={true}
                                                value={this.state.order.deliveredDate}/>

                                        </AvGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Items Total
                                                    Price</Translate>
                                            </Label>

                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="subTotalPriceString"
                                                disabled={true}
                                                value={this.state.order.subTotalPriceString}
                                                onChange={this.__handleChange}/>


                                        </AvGroup>
                                    </Col>

                                    <Col md="4">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Service
                                                    Charge</Translate>
                                            </Label>

                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="calculatedServiceChargeString"
                                                disabled={true}
                                                value={this.state.order.calculatedServiceChargeString}
                                                onChange={this.__handleChange}/>


                                        </AvGroup>
                                    </Col>
                                    {this.state.order.orderType == "DELIVERY" &&
                                        <Col md="4">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="merchantBranch.tradingName">Delivery
                                                        Fee</Translate>
                                                </Label>

                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="calculatedDeliveryFeeString"
                                                    disabled={true}
                                                    value={this.state.order.calculatedDeliveryFeeString}
                                                    onChange={this.__handleChange}/>


                                            </AvGroup>
                                        </Col>
                                    }
                                </Row>
                            </TabPanel>
                        </TabView>


                        {(this.state.order.errorType == "PRINTER" || this.state.order.errorType == "TABLE_NOT_FOUND") &&
                            <Fieldset legend={"Error Details"}>
                                <Row>

                                    <Col md="12">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Error
                                                    Type</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="errorType"
                                                disabled={true}
                                                value={this.state.order.errorType}
                                                onChange={this.__handleChange}>
                                                {ERROR_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md="12">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="merchantBranch.tradingName">Error
                                                    Message</Translate>
                                            </Label>
                                            <AvField
                                                type="textarea"
                                                className="form-control textAreaPosmarket"
                                                name="errorMessage"
                                                disabled={true}
                                                value={this.state.order.errorMessage}
                                                onChange={this.__handleChange}>
                                                {ERROR_TYPE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                </Row>
                            </Fieldset>}

                        <Fieldset legend={"Items"}>
                            <DataTableWithButton data={this.state.order.basketDetailList}
                                                 ref={(elem) => this.refDataTable = elem}
                                                 selectionMode='single'
                                                 paginator={false}
                                                 fields={OrderItemsColumn.fields}> </DataTableWithButton>


                        </Fieldset>


                        <Row>

                            {GeneralUtils.isClerk() && this.state.order.orderStatus == "ORDER_PLACED" && <Col>
                                <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                        onClick={() => {
                                            this.accept();
                                        }}>
                                    <Translate contentKey="entity.action.add">Accept Order</Translate>
                                </Button>
                            </Col>}

                            {GeneralUtils.isClerk() && this.state.order.orderStatus == "ORDER_ACCEPTED" && this.state.order.basketStatus == 'CANCELLED' &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.decline();
                                            }}>
                                        <Translate contentKey="entity.action.add">Decline Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isClerk() && this.state.order.orderStatus == "ORDER_PLACED" && <Col>
                                <Button color="outline-danger" style={{marginBottom: '5px', width: '100%'}}
                                        onClick={() => {
                                            this.decline();
                                        }}>
                                    <Translate contentKey="entity.action.add">Decline Order</Translate>
                                </Button>
                            </Col>}

                            {GeneralUtils.isClerk() && this.state.order.remote == true && this.state.order.orderStatus == "ORDER_DELIVERED" && this.state.order.basketStatus == 'PAUSED' &&
                                <Col>
                                    <Button color="outline-danger" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.decline();
                                            }}>
                                        <Translate contentKey="entity.action.add">Decline Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isKitchen() && this.state.order.orderStatus == "ORDER_ACCEPTED" && this.state.order.basketStatus != 'CANCELLED' &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.preparing();
                                            }}>
                                        <Translate contentKey="entity.action.add">Preparing Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isKitchen() && this.state.order.orderStatus == "ORDER_PREPARING" && <Col>
                                <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                        onClick={() => {
                                            this.ready();
                                        }}>
                                    <Translate contentKey="entity.action.add">Order Ready</Translate>
                                </Button>
                            </Col>}

                            {GeneralUtils.isWaiter() && this.state.order.orderStatus == "ORDER_READY" && (this.state.order.orderType == "EAT_IN" || this.state.order.orderType == "COLLECTION" || this.state.order.orderType == "TAKEAWAY") &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.deliver();
                                            }}>
                                        <Translate contentKey="entity.action.add">Deliver Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isDriver() && (this.state.order.basketStatus != "INPROGRESS") && this.state.order.orderStatus == "ORDER_DISPATCHED" && this.state.order.orderType == "DELIVERY" &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.deliver();
                                            }}>
                                        <Translate contentKey="entity.action.add">Order Delivered</Translate>
                                    </Button>
                                </Col>}

                            {
                                ((GeneralUtils.isClerk() && this.state.order.remote == false && (this.state.order.basketStatus == "INPROGRESS") && (this.state.order.orderStatus == "ORDER_DELIVERED")) ||
                                    (GeneralUtils.isDriver() && this.state.order.remote == false && (this.state.order.basketStatus == "INPROGRESS") && (this.state.order.orderStatus == "ORDER_DISPATCHED"))) &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.payByCardOrCash("CARD");
                                            }}>
                                        <Translate contentKey="entity.action.add">Pay By Card</Translate>
                                    </Button>
                                </Col>}

                            {
                                ((GeneralUtils.isClerk() && this.state.order.remote == false && (this.state.order.basketStatus == "INPROGRESS") && (this.state.order.orderStatus == "ORDER_DELIVERED")) ||
                                    (GeneralUtils.isDriver() && this.state.order.remote == false && (this.state.order.basketStatus == "INPROGRESS") && (this.state.order.orderStatus == "ORDER_DISPATCHED"))) &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.payByCardOrCash("CASH");
                                            }}>
                                        <Translate contentKey="entity.action.add">Pay By Cash</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isClerk() && this.state.order.orderStatus == "ORDER_READY" && this.state.order.orderType == "DELIVERY" &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.dispatch();
                                            }}>
                                        <Translate contentKey="entity.action.add">Dispatch Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isClerk() &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.resendToKitchen();
                                            }}>
                                        <Translate contentKey="entity.action.add">Print Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isClerk() && this.state.order.orderStatus != "ORDER_PLACED" &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.printReceipt();
                                            }}>
                                        <Translate contentKey="entity.action.add">Print Receipt</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isClerk() && this.state.order.remote == false && (this.state.order.orderStatus == "ORDER_CLOSED") && (this.state.order.syncStatus == "PROGRESS" || this.state.order.syncStatus == "FAILED") &&
                                <Col>
                                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.changeSyncStatusSuccess();
                                            }}>
                                        <Translate contentKey="entity.action.add">Finalise Order</Translate>
                                    </Button>
                                </Col>}

                            {GeneralUtils.isClerk() && this.state.order.remote == false && (this.state.order.basketStatus == "PAID") && (this.state.order.orderStatus == "ORDER_ACCEPTED" || this.state.order.orderStatus == "ORDER_PREPARING" || this.state.order.orderStatus == "ORDER_READY" || this.state.order.orderStatus == "ORDER_DISPATCHED") &&
                                <Col>
                                    <Button color="outline-danger" style={{marginBottom: '5px', width: '100%'}}
                                            onClick={() => {
                                                this.showChangeStatusConfirmDialog(true)
                                            }}>
                                        <Translate contentKey="entity.action.add">Refund</Translate>
                                    </Button>
                                </Col>}


                        </Row>


                        <Row>
                            <Col>
                                <Button color="outline-primary" style={{marginBottom: '5px', marginTop: '20px'}}
                                        onClick={() => {
                                            this.props.closeDisplayDialog();
                                        }}>
                                    <Translate contentKey="entity.action.cancel">Close</Translate>
                                </Button>

                            </Col>

                        </Row>

                    </Fieldset>
                </AvForm>
                {this.state.loading && <Loading/>}

            </div>);
    }

    accept = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.acceptOrder(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }

    showChangeStatusConfirmDialog = (value) => {
        this.setState({
            displayShowStatusConfirmDialog: true,
            noticeResponse: "Are you sure to refund?"
        });
    }

    printReceipt = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.printReceipt(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.closeDisplayDialog();
                }
                this.setState({loading: false});
            });
        }

    }

    changeSyncStatusSuccess = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.changeSyncStatusSuccess(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }

    preparing = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.preparingOrder(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }

    ready = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.readyOrder(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
            });
            this.setState({loading: false});
        }

    }

    deliver = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.deliverOrder(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }

    dispatch = () => {
        let driverId = -1;
        if(!GeneralUtils.isNullOrEmpty(this.state.order.driverId)){
            driverId = this.state.order.driverId;
        }

        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.dispatchOrder(this.state.order.id, driverId).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }


    }

    resendToKitchen = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.resendToKitchen(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }

    refund = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.refund(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false, displayShowStatusConfirmDialog: false});
            });
        }

    }

    payByCardOrCash = (paymentType) => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.getManuelPayment(this.state.order.id, paymentType).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }

    decline = () => {
        this.setState({loading: true});
        if (!GeneralUtils.isNullOrEmpty(this.state.order.id)) {
            this.orderService.declineOrder(this.state.order.id).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.props.refreshDataTable();
                }
                this.setState({loading: false});
            });
        }

    }


    setData = (data) => {
        this.setState({order: data});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultOrder);
        this.setState({order: obj});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let order = this.state.order;
        order[e.target.name] = value;
        this.setState({order: order});
    }

}

OrdersDetail.propTypes = {
    order: PropTypes.object
};
