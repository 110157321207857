import React from 'react';

import {Card, Col, Row} from "reactstrap";

import {Fieldset} from "primereact/fieldset";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import MerchantMenuService from "../../shared/service/MerchantMenuService";


export default class DashboardMerchant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {menuList: []};
        this.itemTemplate = this.itemTemplate.bind(this);
        this.merchantMenuService = new MerchantMenuService();


    }

    componentDidMount = () => {

        this.merchantMenuService.findMenus().then(data => {
            this.setState({menuList: data})
        });
    }


    render() {
        let form = [];
        if(!GeneralUtils.isNullOrEmpty(this.state.menuList)){
            this.state.menuList.forEach(item => {
                if (GeneralUtils.isNullOrEmpty(item.parentId)) {
                    form.push(
                        <Fieldset legend={item.description} >
                            <Row>
                                {this.itemTemplate(item)}
                            </Row>
                        </Fieldset>
                    );
                }

            });
        }

        return form;
    }

    menuItem(item) {
        let style = GeneralUtils.isMobile() ? {width: '25vw',height:'6vh', marginTop: '2vw',marginBottom: '2vw', marginLeft: '2vw', marginRight: '2vw',borderLeft: "6px solid #00bcd4 !important;"}  : {width: '18rem', marginBottom: '1em', marginLeft: '1em', borderLeft: "6px solid #00bcd4 !important;",marginRight: '1em'};
        let href = item.href;
        if (href.indexOf("#/merchantUserSave?") >= 0) {
            href = "#/merchantUserSave?" + localStorage.getItem("merchantAccountId");
        }
        return (<a href={href}>
            <Card title={item.description} style={style}>
                <p className="m-2" style={{lineHeight: '1', textAlign:'center'}}>{item.description}</p>
            </Card>
        </a>)
    }

    itemTemplate(item) {
        let form = [];
        this.state.menuList.forEach(itemSub => {
            if (item.id == itemSub.parentId) {
                form.push(
                    this.menuItem(itemSub)
                );
            }
        });
        return form;
    }

    filterParentMenus = (parentId) => {
        return this.state.menuList.filter(function (el) {
            return el.parentId == parentId;
        });
    }

}