import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class RestTableService extends Service {
    constructor() {
        super();
        this.baseURL = 'storemanagement/resttable/';
    }

    async resize(size, rowSize) {
        return fetch(SERVER_URL + this.baseURL + "resize/" + size + "/" + rowSize, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findByMerchantBranchId(merchantBranchId) {
        return fetch(SERVER_URL + this.baseURL + "merchantBranchId/" + merchantBranchId, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async getRestTableParam() {
        return fetch(SERVER_URL + this.baseURL + "param", {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }
}