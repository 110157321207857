import Service from './Service';

export default class MixAndMatchService extends Service {
    constructor() {
        super();
        this.baseURL = 'storemanagement/mixandmatch/';
    }




}