import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {AvGroup, AvForm} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import MemberUserSecurityService from "../../shared/service/MemberUserSecurityService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import {PhoneField} from "../../shared/component/form/PhoneField";
import {PERSON_TITLE} from "../../shared/utils/Constants";
import ReactCountdownClock from "react-countdown-clock";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GoogleLogin} from '@react-oauth/google';
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import BasketService from "../../shared/service/BasketService";

const defaultLogin = {
    email: '',
    firstName: '',
    lastName: '',
    birthDay: null,
    mobilePhone: '',
    title: 'MR',
    password: '',
    status: 'ACTIVE',
    domainName: null,
    verifyCode: null
};

export default class LoginMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: "new",
            renderVerifyCodeButton: false,
            seconds: 60,
            login: defaultLogin
        }
        this.memberUserSecurityService = new MemberUserSecurityService();
        this.merchantBranchService = new MerchantBranchService();
        this.basketService = new BasketService();

    }


    async componentDidMount() {
        let obj1 = this.state.login;
        let basketBssId = window.location.href.split('?')[1];
        if (!GeneralUtils.__isValueEmpty(basketBssId)) {
            obj1["basketBssId"] = basketBssId;
        }


        obj1["domainName"] = GeneralUtils.getDomainName()
        this.setState({
            login: obj1,
            selectedPage: "new"
        });

        const response = (await this.memberUserSecurityService.getGoogleClientId());
        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
            this.setState({googleClientId: response.key});
        }
    }


    failureGoogle = (response) => {
        console.log(response);
    }
    responseGoogle = (response) => {

        let responseGoogle = response;

        //const userObject = jwt_decode(responseGoogle.credential);

        this.memberUserSecurityService.loginWithGoogle(responseGoogle.credential, this.state.login)
            .then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                    if (GeneralUtils.isNullOrEmpty(response.userDTO.mobilePhone) || GeneralUtils.isNullOrEmpty(response.userDTO.title)) {
                        localStorage.setItem("googleCredential", responseGoogle.credential);
                        this.setState({
                            selectedPage: "registerGoogle",
                            login: response.userDTO
                        });
                    } else {
                        GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                        localStorage.setItem("memberUserId", response.userDTO.id);
                        localStorage.setItem("hsmUser", JSON.stringify(response.userDTO));
                        localStorage.setItem("isAuthenticated", true);
                        localStorage.setItem("userType", "member");
                        this.reloadPage();
                    }

                }
            });
    }

    renderGoogle() {
        return (
            <GoogleOAuthProvider
                clientId={this.state.googleClientId}
            >

                <GoogleLogin
                    render={(renderProps) => (
                        <button
                            type="button"
                            className=""
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                        >
                            Sign in with google
                        </button>
                    )}

                    onSuccess={this.responseGoogle}
                    onFailure={this.failureGoogle}
                    cookiePolicy="single_host_origin"
                />
            </GoogleOAuthProvider>

        );
    }

    renderNew() {
        return (<Fieldset legend={"Register or login to continue"}>

            <Row>
                <Col md="12" align={"center"}>
                    {this.renderGoogle()}
                </Col>
            </Row>
            <br></br>
            <p className="separator">&nbsp;&nbsp;OR&nbsp;&nbsp;</p>
            <br></br>
            {this.renderMail()}

            {!GeneralUtils.isNullOrEmpty(this.state.login.basketBssId) &&
                < div>


                    <br></br>
                    <p className="separator">&nbsp;&nbsp;OR&nbsp;&nbsp;</p>
                    <br></br>
                </div>
            }

            {!GeneralUtils.isNullOrEmpty(this.state.login.basketBssId) &&
                <Row>
                    <Col md="12" align={"center"}>
                        <Button color="outline-primary" style={{marginBottom: '5px', marginTop: '5px', width: '100%'}}
                                onClick={() => {
                                    window.location.href = "/confirmOrder";

                                }}>
                            <Translate contentKey="entity.action.cancel">Checkout as a guest</Translate>
                        </Button>
                    </Col>
                </Row>
            }
        </Fieldset>);
    }

    renderMail() {
        return (<div>

            <Row>
                <Col md="12" align={"center"}>
                    What is your e-mail address?
                </Col>
                <br></br>

            </Row>


            <Row>
                <Col md="12">
                    <AvGroup>
                        <AvField
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={false}
                            validate={{
                                minLength: {
                                    "value": 5, "errorMessage": "Not Valid Email"
                                }, maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.login.email}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
                <Col md="12" align={"center"}>
                    <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                            onClick={() => {

                                if (GeneralUtils.isNullOrEmpty(this.state.login.email) || this.state.login.email.length < 8 || this.state.login.email.indexOf("@") == -1) {
                                    return toast.warn("Please fill a validate email");
                                } else {
                                    this.memberUserSecurityService.checkEmail(this.state.login.email).then(response => {
                                        if (response == 1) {
                                            this.setState({
                                                selectedPage: "password"
                                            });
                                        }
                                        if (response == 2) {
                                            this.setState({
                                                selectedPage: "verifycode"
                                            });
                                        }
                                        if (response == 0) {
                                            this.setState({
                                                selectedPage: "register"
                                            });
                                        }

                                    });
                                }
                            }}>
                        <Translate contentKey="entity.action.cancel">Continue</Translate>
                    </Button>
                </Col>
            </Row>


        </div>)
    }

    renderPassword() {
        return (<Fieldset legend={"Login"}>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Mail
                            </Translate>
                        </Label>
                        <AvField
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={true}
                            validate={{
                                minLength: {
                                    "value": 5, "errorMessage": "Not Valid Email"
                                }, maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.login.email}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
            </Row>


            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Password
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>

                        <AvField
                            value={this.state.login.password}
                            style={{webkitTextSecurity: "disc", textSecurity: "disc"}}
                            name="password"
                            type={"text"}
                            className="form-control"
                            disabled={false}
                            onChange={this.__handleChange}

                        />
                    </AvGroup>
                </Col>


            </Row>
            <Row>
                <Col md="12">
                    <Button color="outline-primary"
                            style={{marginBottom: '5px', width: '100%'}}
                            onClick={() => {

                                if (
                                    GeneralUtils.isNullOrEmpty(this.state.login.password)

                                ) {
                                    return toast.warn("Please fill the password field!");
                                } else if (!GeneralUtils.checkPasswordFormat(this.state.login.password)) {
                                    return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
                                } else {

                                    this.memberUserSecurityService.login(this.state.login)
                                        .then((response) => {
                                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                this.memberUserSecurityService.getTokens(response)
                                                    .then((response) => {
                                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                            GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                                                            localStorage.setItem("memberUserId", response.userDTO.id);
                                                            localStorage.setItem("hsmUser", JSON.stringify(response.userDTO));
                                                            localStorage.setItem("userType", "member");
                                                            localStorage.setItem("isAuthenticated", true);
                                                            this.reloadPage();
                                                        }
                                                    });
                                            }
                                        });


                                }


                            }}>
                        <Translate contentKey="entity.action.cancel">Login</Translate>
                    </Button>
                </Col>
            </Row>


            <Row>
                <Col md="12">
                    <Button color="outline-primary"
                            style={{marginRight: '5px', width: '100%'}}
                            onClick={() => {

                                let url = GeneralUtils.getUiUrl();
                                let data = {
                                    email: this.state.login.email,
                                    url: url
                                };

                                this.memberUserSecurityService.resetPasswordInit(data);
                                this.reloadPage();


                            }}>
                        <Translate contentKey="entity.action.cancel">Forgot Password</Translate>
                    </Button>
                </Col>
            </Row>

        </Fieldset>);
    }

    renderLogin() {
        return (<Fieldset legend={"Verify Code & Login"}>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Mail
                            </Translate>
                        </Label>
                        <AvField
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={true}
                            validate={{
                                minLength: {
                                    "value": 5, "errorMessage": "Not Valid Email"
                                }, maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.login.email}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Verify Code
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="verifyCode"
                            disabled={false}
                            validate={{
                                maxLength: {
                                    value: 6,
                                    errorMessage: "You can enter up to 50 characters in this field."
                                }
                            }}
                            value={this.state.login.verifyCode}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
            </Row>


            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Password
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>

                        <AvField
                            value={this.state.login.password}
                            style={{webkitTextSecurity: "disc", textSecurity: "disc"}}
                            name="password"
                            type={"text"}
                            className="form-control"
                            disabled={false}
                            onChange={this.__handleChange}

                        />
                    </AvGroup>
                </Col>


                <Col md="12">
                    <Button color="outline-primary"
                            style={{marginBottom: '5px', width: '100%'}}
                            onClick={() => {
                                if (
                                    GeneralUtils.isNullOrEmpty(this.state.login.password)
                                ) {
                                    return toast.warn("Please fill the password field!");
                                } else if (!GeneralUtils.checkPasswordFormat(this.state.login.password)) {
                                    return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
                                }
                                if (
                                    GeneralUtils.isNullOrEmpty(this.state.login.verifyCode)
                                ) {
                                    return toast.warn("Please fill the Verify Code field!");
                                } else {

                                    this.memberUserSecurityService.login(this.state.login)
                                        .then((response) => {
                                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                this.memberUserSecurityService.getTokens(response)
                                                    .then((response) => {
                                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                            GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                                                            localStorage.setItem("memberUserId", response.userDTO.id);
                                                            localStorage.setItem("hsmUser", JSON.stringify(response.userDTO));
                                                            localStorage.setItem("isAuthenticated", true);
                                                            localStorage.setItem("userType", "member");
                                                            this.reloadPage();
                                                        }
                                                    });
                                            }
                                        });


                                }


                            }}>
                        <Translate contentKey="entity.action.cancel">Login</Translate>
                    </Button>
                </Col>


            </Row>

        </Fieldset>)
    }

    renderRegister() {
        return (

            <Fieldset legend={"Register"}>
                <Row className="justify-content-center">
                    <ReactCountdownClock seconds={this.state.seconds}
                                         color="#1a1a1a"
                                         showMilliseconds={false}
                                         alpha={0.9}
                                         size={50}
                                         onComplete={this.countDownClock}/>
                </Row>
                <Row>
                    <Col md="12" align={"center"}>
                        Let's start!
                    </Col>

                </Row>
                <Row>
                    <Col md="12" align={"center"}>
                        First, create your account
                    </Col>

                </Row>

                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Mail
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="email"
                                className="form-control"
                                name="email"
                                disabled={true}
                                validate={{
                                    minLength: {
                                        "value": 5, "errorMessage": "Not Valid Email"
                                    }, maxLength: {
                                        value: 255,
                                        errorMessage: "You can enter up to 50 characters in this field."
                                    }
                                }}
                                value={this.state.login.email}
                                onChange={this.__handleChange}/>
                        </AvGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="merchantBranch.tradingName">Title</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="title"
                                disabled={false}
                                value={this.state.login.title}
                                onChange={this.__handleChange}>
                                {PERSON_TITLE.map(element => (<option value={element.value} key={element.value}>
                                    {element.name}
                                </option>))}
                            </AvField>
                        </AvGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">First Name
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="firstName"
                                disabled={false}
                                validate={{
                                    maxLength: {
                                        value: 255,
                                        errorMessage: "You can enter up to 255 characters in this field."
                                    }
                                }}
                                value={this.state.login.firstName}
                                onChange={this.__handleChange}/>
                        </AvGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Last Name
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="lastName"
                                disabled={false}
                                validate={{
                                    maxLength: {
                                        value: 255,
                                        errorMessage: "You can enter up to 255 characters in this field."
                                    }
                                }}
                                value={this.state.login.lastName}
                                onChange={this.__handleChange}/>
                        </AvGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="mobilePhone">
                                <Translate contentKey="contactManagement.mobilePhone">Mobile
                                    Phone</Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <PhoneField kind={'mobile'}
                                        disabled={false}
                                        name="mobilePhone" errorMessage="Not Valid Phone Number"
                                        value={this.state.login.mobilePhone}
                                        onChange={this.__handleChange}
                            />
                        </AvGroup>
                    </Col>
                </Row>


                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Verify Code
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="verifyCode"
                                disabled={false}
                                validate={{
                                    maxLength: {
                                        value: 6,
                                        errorMessage: "You can enter up to 50 characters in this field."
                                    }
                                }}
                                value={this.state.login.verifyCode}
                                onChange={this.__handleChange}/>
                        </AvGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Password
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>

                            <AvField
                                value={this.state.login.password}
                                style={{webkitTextSecurity: "disc", textSecurity: "disc"}}
                                name="password"
                                type={"text"}
                                className="form-control"
                                disabled={false}
                                onChange={this.__handleChange}

                            />

                        </AvGroup>
                    </Col>
                    <Col md="12">
                        <AvGroup>
                            <Label for="email">
                                <Translate contentKey="merchantBranch.tradingName">Password Confirm
                                </Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>

                            <AvField
                                value={this.state.login.passwordConfirm}
                                style={{webkitTextSecurity: "disc", textSecurity: "disc"}}
                                name="passwordConfirm"
                                type={"text"}
                                className="form-control"
                                disabled={false}
                                onChange={this.__handleChange}

                            />

                        </AvGroup>
                    </Col>
                    {!this.state.renderVerifyCodeButton &&
                        <Col md="12">
                            <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                    onClick={() => {

                                        if (GeneralUtils.isNullOrEmpty(this.state.login.firstName) ||
                                            GeneralUtils.isNullOrEmpty(this.state.login.lastName) ||
                                            GeneralUtils.isNullOrEmpty(this.state.login.password) ||
                                            GeneralUtils.isNullOrEmpty(this.state.login.passwordConfirm) ||
                                            GeneralUtils.isNullOrEmpty(this.state.login.verifyCode)

                                        ) {
                                            return toast.warn("Please fill the required fields!");
                                        } else if (this.state.login.password.localeCompare(this.state.login.passwordConfirm) != 0) {
                                            return toast.warn("Your password and confirmation password do not match.");
                                        } else if (!GeneralUtils.checkPasswordFormat(this.state.login.password)) {
                                            return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
                                        } else {
                                            this.memberUserSecurityService.register(this.state.login)
                                                .then((response) => {
                                                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                        GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                                                        localStorage.setItem("memberUserId", response.userDTO.id);
                                                        localStorage.setItem("hsmUser", JSON.stringify(response.userDTO));
                                                        localStorage.setItem("isAuthenticated", true);
                                                        localStorage.setItem("userType", "member");
                                                        this.reloadPage();
                                                    }

                                                });
                                        }


                                    }}>
                                <Translate contentKey="entity.action.cancel">Register</Translate>
                            </Button>
                        </Col>
                    }

                    {this.state.renderVerifyCodeButton &&
                        <Col md="12" align={"center"}>
                            <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                                    onClick={() => {
                                        this.memberUserSecurityService.checkEmail(this.state.login.email).then(response => {
                                            if (response == 0) {
                                                this.setState({
                                                    seconds: this.state.seconds + 0.00000001,
                                                    renderVerifyCodeButton: false
                                                });
                                            }

                                        });
                                    }}>
                                <Translate contentKey="entity.action.cancel">Send Verify Code Again</Translate>
                            </Button>
                        </Col>
                    }

                </Row>


            </Fieldset>)
    }

    renderRegisterGoogle() {
        return (<Fieldset legend={"Register with Google"}>
            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Mail
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={true}
                            validate={{
                                minLength: {
                                    "value": 5, "errorMessage": "Not Valid Email"
                                }, maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.login.email}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="tradingName">
                            <Translate contentKey="merchantBranch.tradingName">Title</Translate>
                        </Label>
                        <AvField
                            type="select"
                            className="form-control"
                            name="title"
                            disabled={false}
                            value={this.state.login.title}
                            onChange={this.__handleChange}>
                            {PERSON_TITLE.map(element => (<option value={element.value} key={element.value}>
                                {element.name}
                            </option>))}
                        </AvField>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">First Name
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="firstName"
                            disabled={true}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.login.firstName}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="email">
                            <Translate contentKey="merchantBranch.tradingName">Last Name
                            </Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <AvField
                            type="text"
                            className="form-control"
                            name="lastName"
                            disabled={true}
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: "You can enter up to 255 characters in this field."
                                }
                            }}
                            value={this.state.login.lastName}
                            onChange={this.__handleChange}/>
                    </AvGroup>
                </Col>
            </Row>

            <Row>
                <Col md="12">
                    <AvGroup>
                        <Label for="mobilePhone">
                            <Translate contentKey="contactManagement.mobilePhone">Mobile
                                Phone</Translate>
                            <span style={{color: 'red'}}> (*)</span>
                        </Label>
                        <PhoneField kind={'mobile'}
                                    disabled={false}
                                    name="mobilePhone" errorMessage="Not Valid Phone Number"
                                    value={this.state.login.mobilePhone}
                                    onChange={this.__handleChange}
                        />
                    </AvGroup>
                </Col>
            </Row>


            <Col md="12">
                <Button color="outline-primary" style={{marginBottom: '5px', width: '100%'}}
                        onClick={() => {

                            if (GeneralUtils.isNullOrEmpty(this.state.login.mobilePhone)
                            ) {
                                return toast.warn("Please enter  Mobile Phone!");
                            } else {
                                this.memberUserSecurityService.loginWithGoogle(localStorage.getItem("googleCredential"), this.state.login)
                                    .then((response) => {
                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                            GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                                            localStorage.setItem("memberUserId", response.userDTO.id);
                                            localStorage.setItem("hsmUser", JSON.stringify(response.userDTO));
                                            localStorage.setItem("isAuthenticated", true);
                                            localStorage.setItem("userType", "member");
                                            localStorage.removeItem("googleCredential");
                                            this.reloadPage();

                                        }
                                    });
                            }


                        }}>
                    <Translate contentKey="entity.action.cancel">Register</Translate>
                </Button>
            </Col>

        </Fieldset>)
    }

    render() {
        return (
            <AvForm>
                <div>
                    <Row>
                        <Col md={3}>

                        </Col>
                        <Col md={4}>

                            {this.state.selectedPage == "new" &&
                                this.renderNew()
                            }

                            {this.state.selectedPage == "registerGoogle" &&
                                this.renderRegisterGoogle()
                            }

                            {this.state.selectedPage == "password" &&
                                this.renderPassword()
                            }

                            {this.state.selectedPage == "verifycode" &&
                                this.renderLogin()
                            }

                            {this.state.selectedPage == "register" &&
                                this.renderRegister()
                            }
                        </Col>
                        <Col md={3}>

                        </Col>
                    </Row>


                </div>

            </AvForm>
        );
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let login = this.state.login;
        login[e.target.name] = value;
        this.setState({login: login});

    }

    countDownClock = () => {
        this.setState({
            renderVerifyCodeButton: true
        });
    }

    async returnToHomePage() {
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId")) && localStorage.getItem("merchantBranchId") != -1) {
            const response = (await this.merchantBranchService.getStoreInfo(localStorage.getItem("merchantBranchId")));
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && !GeneralUtils.isNullOrEmpty(response.domainName)) {
                if (GeneralUtils.isServedInWebMarketUrl()) {
                    window.location.href = response.domainName;
                } else {
                    window.location.href = "/"
                }

            } else {
                window.location.href = "/"
            }
        } else {
            window.location.href = "/"
        }
    }

    async reloadPage() {
        let basketBssId = window.location.href.split('?')[1];
        if (GeneralUtils.isNullOrEmpty(basketBssId)){
            this.returnToHomePage();
        } else {
            this.basketService.setBasketToLoginUser(localStorage.getItem("merchantBranchId"), basketBssId).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && !GeneralUtils.isNullOrEmpty(response.id)) {
                    window.location.href = "/approveOrder"
                } else {
                    this.returnToHomePage();
                }
            });


        }

    }


}


