import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MemberUserService extends Service {
    constructor() {
        super();
        this.baseURL = 'memberuser/';
    }

    async changeUserPassword(password) {
        let data = {
            "password" : password
        };
        return fetch(SERVER_URL + this.baseURL + "password/change" , {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findUser() {
        return fetch(SERVER_URL + this.baseURL, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}