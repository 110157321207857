import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {
    FONT,
    FONT_WEIGHT,
    SERVER_URL_PRODUCT_DATAWAREHOUSE,
    STATUS,
    TEXT_ALIGN,
    TILL_IMAGE,
    WEB_MENU_SMALL_IMAGE
} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import ProductGroupService from "../../shared/service/ProductGroupService";
import {Button as ButtonSearch} from "primereact/components/button/Button";
import {AutoComplete} from "primereact/autocomplete";
import {FileUpload} from "primereact/fileupload";
import {Checkbox} from "primereact/checkbox";
import {MultiSelect} from "primereact/multiselect";
import WebMenuService from "../../shared/service/WebMenuService";
import TagService from "../../shared/service/TagService";
import ProductService from "../../shared/service/ProductService";
import Dialog from "../../shared/component/dialog/Dialog";
import ProductGroupDialog from "./ProductGroupDialog";
import DeviceService from "../../shared/service/DeviceService";
import {ColorPicker} from "primereact/colorpicker";

const defaultWebMenu = {
    id: '',
    parentId: '',
    productId: '',
    longname: '',
    name: '',
    status: 'ACTIVE',
    fileType: '',
    fileName: 'Choose',
    fileTypeTill: '',
    fileNameTill: 'Choose',
    grpContent: null,
    grpContentX: null,
    hasSubMenu: true,
    subTitle: null,
    productTags: [],
    grpMain: null,
    grpOption1: null,
    grpOption2: null,
    grpOption3: null,
    grpOption4: null,
    grpOption1Label: 'opt1',
    grpOption2Label: 'opt2',
    grpOption3Label: 'opt3',
    grpOption4Label: 'opt4',
    kitchenPrinter1: '',
    kitchenPrinter2: '',
    kitchenPrinter3: '',
    kitchenPrinter4: '',
    fontSize: '3.0vmin',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
    menuStyleHeightFlex: 1,
    rowNo: 1,
    colNo: 1,
    borderRadius: '1vmin',
    pageSize: 1,
    rowSize: 1,
    spaceLength: null,
    pinned: false,
    lining: false,
    dummy: false,
    displaySiblings: false,
    displayGrandChilds: false,
    tillIdList: '',
    showInline: false,
    singleLine: false,
    condiment: false,
    printOrder: 0,
    webEnabled: true


};

export default class WebMenuSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webMenu: defaultWebMenu,
            webMenuTemp: defaultWebMenu,
            webMenuSaveButtonRender: true,
            defaultStatus: 'ACTIVE',
            mainGroupList: [],
            subMenuList: [],
            webMenuList: [],
            kitchenPrinterList: [],
            productTagList: [],
            productList: []
        }
        this.webMenuService = new WebMenuService();
        this.productService = new ProductService();
        this.productGroupService = new ProductGroupService();
        this.tagService = new TagService();
        this.deviceService = new DeviceService();
    }


    componentDidMount() {

        let id = window.location.href.split('?')[1];
        let parentId = window.location.href.split('?')[2];
        let webMenuTemp = this.state.webMenu;
        webMenuTemp['parentId'] = parentId;

        this.setState({
            webMenu: webMenuTemp, webMenuTemp: webMenuTemp
        });

        if (!GeneralUtils.__isValueEmpty(id)) {
            this.webMenuService.findById(id).then(response => {
                    let responseObject = Object.assign({}, response);
                    let responseObjectTemp = Object.assign({}, response);
                    this.setState({
                        webMenu: responseObject, webMenuTemp: responseObjectTemp, webMenuSaveButtonRender: false
                    });
                }
            );
        } else {
            let obj1 = Object.assign({}, defaultWebMenu);
            let obj2 = Object.assign({}, defaultWebMenu);
            this.setState({
                webMenu: obj1, webMenuTemp: obj2, webMenuSaveButtonRender: true
            });

        }

        this.refresh();
    }

    refresh() {
        this.webMenuService.findAllSubMenus().then(response => this.setState({webMenuList: response}));
        this.productGroupService.findAll().then(response => this.setState({mainGroupList: response}));
        this.tagService.findAllActive().then(response => this.setState({productTagList: response}));
        this.deviceService.findAll().then(response => this.setState({kitchenPrinterList: response}));
        this.productService.findAllMenu().then(response => this.setState({subMenuList: response}));
    }

    render() {
        return (<div>
            <AvForm>
                {this.webMenus()}
            </AvForm>
        </div>);
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.webMenu.id)) {
            this.webMenuService.updateStatus(this.state.webMenu.id, this.state.defaultStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            webMenu: response,
                            webMenuSaveButtonRender: false,
                            webMenuTemp: Object.assign({}, response)
                        });
                        this.refresh();
                    }
                });
        } else {
            let webMenu = this.state.webMenu;
            webMenu["status"] = this.state.webMenu.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({webMenu: webMenu, webMenuTemp: Object.assign({}, webMenu)});
        }

        return undefined;
    }

    showDeleteConfirmDialog = (value) => {
        this.setState({
            displayDeleteConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }

    returnListPage = () => {
        window.location.href = "#webMenuList?1";
    }
    deleteWebMenu = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.webMenu.id)) {
            this.webMenuService.delete(this.state.webMenu.id)
                .then((response) => {
                    this.returnListPage();
                });
        }
    }


    webMenus() {

        return (<div class="p-col-12">
            <Fieldset legend="Web Menu Details">
                <ModalConfirm header='Warning' ok='OK' onOkClick={this.deleteWebMenu}
                              onCancelClick={() => this.setState({displayDeleteConfirmDialog: false})}
                              show={this.state.displayDeleteConfirmDialog}
                              message={this.state.noticeResponse}/>

                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="2">
                                <Fieldset legend="Status">
                                    <Col md="3">
                                    </Col>
                                    <Col md="9">
                                        <Row>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="status"
                                                disabled={true}
                                                value={this.state.webMenu.status}
                                                onChange={this.__handleChange}>
                                                {STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>


                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                          show={this.state.displayShowStatusConfirmDialog}
                                                          message={this.state.noticeResponse}/>

                                            <InputSwitch checked={this.state.webMenu.status == "ACTIVE" ? true : false}
                                                         tooltip={"Change Status"}
                                                         disabled={!this.state.webMenuSaveButtonRender}
                                                         onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                        </Row>

                                    </Col>

                                </Fieldset>


                            </Col>


                            <Col md="2">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="webMenu.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.webMenuSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.webMenu.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="webMenu.tradingName">SubTitle</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="subTitle"
                                        disabled={!this.state.webMenuSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.webMenu.subTitle}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>


                            <Col md="2">
                                <AvGroup>
                                    <Label for="cityId">
                                        <Translate
                                            contentKey="location.cityId">Parent</Translate>
                                    </Label>
                                    <AvField type="select" className="form-control" name="parentId"
                                             disabled={true}
                                             value={this.state.webMenu.parentId}
                                             onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {this.state.webMenuList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">WebEnabled?&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("webEnabled", e.checked)}
                                              name="webEnabled"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.webEnabled}
                                              checked={this.state.webMenu.webEnabled}></Checkbox>


                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Has Submenu?&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("hasSubMenu", e.checked)}
                                              name="hasSubMenu"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.hasSubMenu}
                                              checked={this.state.webMenu.hasSubMenu}></Checkbox>


                                </AvGroup>
                            </Col>


                            <Col md="2">
                                <Fieldset legend="Image(Web)">
                                    <Row>
                                        <Col md="1">

                                        </Col>

                                            <FileUpload name="demo"
                                                        accept="image/*"
                                                        maxFileSize={50000}
                                                        onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                        invalidFileSizeMessageDetail="Maximum upload size is 50K."
                                                        chooseLabel={this.state.webMenu.fileName == null ? "Choose" : this.state.webMenu.fileName}
                                                        disabled={!this.state.webMenuSaveButtonRender}
                                                        ref={elem => this.refUploadInputImage = elem}
                                                        mode="basic"></FileUpload>

                                            <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}} onClick={(e) => {
                                                if (e.detail == 1) {//sayfada enter e basınca resim acılmasın diye
                                                    try {
                                                        GeneralUtils.previewDocument(this.refUploadInputImage);
                                                    } catch (e) {
                                                        this.downloadDocument(WEB_MENU_SMALL_IMAGE);
                                                    }
                                                }
                                            }}>
                                            </ButtonSearch>

                                            <ButtonSearch icon="pi pi-minus" disabled={!this.state.webMenuSaveButtonRender}
                                                          style={{marginLeft: '5px'}} onClick={(e) => {
                                                this.removeDocument(WEB_MENU_SMALL_IMAGE)
                                            }}>
                                            </ButtonSearch>


                                    </Row>

                                </Fieldset>




                            </Col>


                            <Col md="2">
                                <Fieldset legend="Data Warehouse Image">
                                    <img
                                        src={SERVER_URL_PRODUCT_DATAWAREHOUSE + "product/image/barcode/"+this.state.webMenu.productBarcode+"/name/"+ GeneralUtils.removeSlashes(this.state.webMenu.productName)}
                                        onError={(e) => e.target.src = 'assets/layout/images/white.png'}
                                        style={{maxWidth:"30px",maxHeight:"30px"}}
                                        />

                                </Fieldset>

                            </Col>




                            {this.state.webMenu.hasSubMenu == false &&
                                <Col md="2">
                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Product Tag</Translate>
                                        </Label>
                                        <MultiSelect
                                            value={this.state.webMenu.tags}
                                            options={this.state.productTagList}
                                            name="tags"
                                            disabled={!this.state.webMenuSaveButtonRender}
                                            onChange={this.__handleChange}
                                            optionLabel="name"
                                            placeholder="Select a Product Tag" maxSelectedLabels={4}/>

                                    </AvGroup>
                                </Col>
                            }


                        </Row>
                    </Col>


                </Row>

                <Row>
                    <Col md={6}>
                        <Fieldset legend={"Select Product"}>

                            {!GeneralUtils.isNullOrEmpty(this.state.webMenu.productId) && <Fieldset legend="Product">

                                <Col md="6">
                                    <Row>
                                        <AvField
                                            type="text"
                                            onChange={this.__handleChange}
                                            className="form-control"
                                            name="longname"
                                            disabled={true}
                                            value={this.state.webMenu.longname}
                                        />


                                        <Row>
                                            <Col md="1">
                                            </Col>
                                            <ButtonSearch disabled={!this.state.webMenuSaveButtonRender}
                                                          icon="pi pi-copy" style={{marginRight: '5px'}}
                                                          onClick={() => {
                                                              let webMenu = this.state.webMenu;
                                                              webMenu["name"] = webMenu["longname"];
                                                              this.setState({webMenu: webMenu});
                                                          }}>
                                            </ButtonSearch>

                                            <ButtonSearch disabled={!this.state.webMenuSaveButtonRender}
                                                          icon="pi pi-times-circle" onClick={() => {
                                                let webMenu = this.state.webMenu;
                                                webMenu["longname"] = "";
                                                webMenu["productId"] = null;
                                                webMenu["productName"] = null;
                                                webMenu["productBarcode"] = null;
                                                this.setState({webMenu: webMenu});
                                            }}>
                                            </ButtonSearch>

                                        </Row>
                                    </Row>
                                </Col>
                            </Fieldset>}
                            <Row>
                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Barcode</Translate>
                                        </Label>
                                        <AvField
                                            type="number"
                                            name="barcode"
                                            className="form-control"
                                            disabled={!this.state.webMenuSaveButtonRender}
                                            max="99999999999999"
                                            onKeyDown={this._handleKeyDown}
                                            value={this.state.barcode}
                                            onChange={this.__handleChangeBarcode}/>
                                    </AvGroup>
                                </Col>

                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Name</Translate>
                                        </Label>
                                        <AutoComplete value={this.state.selectedProduct}
                                                      suggestions={this.state.filteredProducts}
                                                      completeMethod={this.searchProduct}
                                                      field="longname"
                                                      disabled={!this.state.webMenuSaveButtonRender}
                                                      onChange={(e) => {
                                                          let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
                                                          this.setState({selectedProduct: value});
                                                          if (!GeneralUtils.isNullOrEmpty(e.value.id)) {
                                                              this.setState({selectedProduct: e.value});
                                                              let data = e.value;
                                                              let webMenu = this.state.webMenu;
                                                              webMenu["longname"] = data.longname;
                                                              webMenu["name"] = data.longname;
                                                              webMenu["productId"] = data.id;
                                                              webMenu["productName"] = data.name;
                                                              webMenu["productBarcode"] = data.barcode;

                                                              this.setState({
                                                                  webMenu: webMenu,
                                                                  barcode: '',
                                                                  selectedProduct: null,
                                                                  productId: null,
                                                                  productList: []
                                                              });
                                                          }
                                                      }}

                                        />
                                    </AvGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6">

                                    <AvGroup>

                                        <Dialog visible={false}
                                                header={"Product Group"}
                                                onHide={() => this.setState({displayDialog: false})}
                                                style={{width: '40vw', height: '35vw'}}>

                                            <ProductGroupDialog setData={this.setData}
                                                                closeDisplayDialog={this.closeDisplayDialog}
                                                                ref={(elem) => this.refModelSave = elem}
                                            />
                                        </Dialog>

                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Main Group</Translate>
                                        </Label>
                                        <AvField type="select" className="form-control" name="grpMain"
                                                 disabled={!this.state.webMenuSaveButtonRender}
                                                 value={this.state.webMenu.grpMain}
                                                 onChange={this.__handleChangeMainGroup}>
                                            <option value='' key=''/>
                                            {this.state.mainGroupList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>

                                <Col md="6">
                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Product</Translate>
                                        </Label>
                                        <AvField type="select" className="form-control" name="productId"
                                                 disabled={!this.state.webMenuSaveButtonRender}
                                                 value={this.state.webMenu.productId}
                                                 onChange={this.__handleChangeProduct}>
                                            <option value='' key=''/>
                                            {this.state.productList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.longname}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>

                            </Row>

                        </Fieldset>

                    </Col>
                    {this.state.webMenu.hasSubMenu == false &&
                        <Col md={6}>
                            <Fieldset legend={"Groups"}>
                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Button color="outline-primary" style={{marginBottom: '5px'}}
                                                    onClick={() => {
                                                        this.setState({
                                                            displayDialog: true,
                                                            productGroupName: "grpContent"
                                                        })
                                                    }}>
                                                <Translate contentKey="entity.action.add">Content</Translate>
                                            </Button>

                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>

                                            <AvField type="select" className="form-control" name="grpContent"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.grpContent}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.mainGroupList.map(element => (
                                                    <option value={element.id} key={element.id}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Button color="outline-primary" style={{marginBottom: '5px'}}
                                                    onClick={() => {
                                                        this.setState({
                                                            displayDialog: true,
                                                            productGroupName: "grpContentX"
                                                        })
                                                    }}>
                                                <Translate contentKey="entity.action.add">Extra</Translate>
                                            </Button>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField type="select" className="form-control" name="grpContentX"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.grpContentX}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.mainGroupList.map(element => (
                                                    <option value={element.id} key={element.id}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Button color="outline-primary" style={{marginBottom: '5px'}}
                                                    onClick={() => {
                                                        this.setState({
                                                            displayDialog: true,
                                                            productGroupName: "grpOption1"
                                                        })
                                                    }}>
                                                <Translate contentKey="entity.action.add">Group 1</Translate>
                                            </Button>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField type="select" className="form-control" name="grpOption1"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.grpOption1}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.mainGroupList.map(element => (
                                                    <option value={element.id} key={element.id}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="grpOption1Label"
                                                disabled={!this.state.webMenuSaveButtonRender}
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: "You can enter up to 255 characters in this field."
                                                    }
                                                }}
                                                value={this.state.webMenu.grpOption1Label}
                                                onChange={this.__handleChange}/>

                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>

                                            <AvField type="select" className="form-control"
                                                     name="kitchenPrinterGrp1"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.kitchenPrinterGrp1}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.kitchenPrinterList.map(element => (
                                                    <option value={element.bssId} key={element.bssId}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Button color="outline-primary" style={{marginBottom: '5px'}}
                                                    onClick={() => {
                                                        this.setState({
                                                            displayDialog: true,
                                                            productGroupName: "grpOption2"
                                                        })
                                                    }}>
                                                <Translate contentKey="entity.action.add">Group 2</Translate>
                                            </Button>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField type="select" className="form-control" name="grpOption2"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.grpOption2}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.mainGroupList.map(element => (
                                                    <option value={element.id} key={element.id}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="grpOption2Label"
                                                disabled={!this.state.webMenuSaveButtonRender}
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: "You can enter up to 255 characters in this field."
                                                    }
                                                }}
                                                value={this.state.webMenu.grpOption2Label}
                                                onChange={this.__handleChange}/>

                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>

                                            <AvField type="select" className="form-control"
                                                     name="kitchenPrinterGrp2"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.kitchenPrinterGrp2}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.kitchenPrinterList.map(element => (
                                                    <option value={element.bssId} key={element.bssId}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Button color="outline-primary" style={{marginBottom: '5px'}}
                                                    onClick={() => {
                                                        this.setState({
                                                            displayDialog: true,
                                                            productGroupName: "grpOption3"
                                                        })
                                                    }}>
                                                <Translate contentKey="entity.action.add">Group 3</Translate>
                                            </Button>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField type="select" className="form-control" name="grpOption3"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.grpOption3}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.mainGroupList.map(element => (
                                                    <option value={element.id} key={element.id}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="grpOption3Label"
                                                disabled={!this.state.webMenuSaveButtonRender}
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: "You can enter up to 255 characters in this field."
                                                    }
                                                }}
                                                value={this.state.webMenu.grpOption3Label}
                                                onChange={this.__handleChange}/>

                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>

                                            <AvField type="select" className="form-control"
                                                     name="kitchenPrinterGrp3"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.kitchenPrinterGrp3}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.kitchenPrinterList.map(element => (
                                                    <option value={element.bssId} key={element.bssId}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>


                                <Row>
                                    <Col md="3">
                                        <AvGroup>
                                            <Button color="outline-primary" style={{marginBottom: '5px'}}
                                                    onClick={() => {
                                                        this.setState({
                                                            displayDialog: true,
                                                            productGroupName: "grpOption4"
                                                        })
                                                    }}>
                                                <Translate contentKey="entity.action.add">Group 4</Translate>
                                            </Button>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField type="select" className="form-control" name="grpOption4"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.grpOption4}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.mainGroupList.map(element => (
                                                    <option value={element.id} key={element.id}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="grpOption4Label"
                                                disabled={!this.state.webMenuSaveButtonRender}
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: "You can enter up to 255 characters in this field."
                                                    }
                                                }}
                                                value={this.state.webMenu.grpOption4Label}
                                                onChange={this.__handleChange}/>

                                        </AvGroup>
                                    </Col>

                                    <Col md="3">
                                        <AvGroup>

                                            <AvField type="select" className="form-control"
                                                     name="kitchenPrinterGrp4"
                                                     disabled={!this.state.webMenuSaveButtonRender}
                                                     value={this.state.webMenu.kitchenPrinterGrp4}
                                                     onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {this.state.kitchenPrinterList.map(element => (
                                                    <option value={element.bssId} key={element.bssId}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>

                            </Fieldset>
                        </Col>
                    }
                </Row>


                <Fieldset legend={"Kitchen Printers"}>
                    <Row>
                        <Col md="2">
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Kp 1</Translate>
                                </Label>
                                <AvField type="select" className="form-control"
                                         name="kitchenPrinter1"
                                         disabled={!this.state.webMenuSaveButtonRender}
                                         value={this.state.webMenu.kitchenPrinter1}
                                         onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {this.state.kitchenPrinterList.map(element => (
                                        <option value={element.bssId} key={element.bssId}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>
                        <Col md="2">
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Kp 2</Translate>
                                </Label>
                                <AvField type="select" className="form-control"
                                         name="kitchenPrinter2"
                                         disabled={!this.state.webMenuSaveButtonRender}
                                         value={this.state.webMenu.kitchenPrinter2}
                                         onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {this.state.kitchenPrinterList.map(element => (
                                        <option value={element.bssId} key={element.bssId}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>

                        <Col md="2">
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Kp 3</Translate>
                                </Label>
                                <AvField type="select" className="form-control"
                                         name="kitchenPrinter3"
                                         disabled={!this.state.webMenuSaveButtonRender}
                                         value={this.state.webMenu.kitchenPrinter3}
                                         onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {this.state.kitchenPrinterList.map(element => (
                                        <option value={element.bssId} key={element.bssId}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>
                        <Col md="2">
                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Kp 4</Translate>
                                </Label>
                                <AvField type="select" className="form-control"
                                         name="kitchenPrinter4"
                                         disabled={!this.state.webMenuSaveButtonRender}
                                         value={this.state.webMenu.kitchenPrinter4}
                                         onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {this.state.kitchenPrinterList.map(element => (
                                        <option value={element.bssId} key={element.bssId}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>

                        <Col md="2">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">KPOrder</Translate>
                                </Label>

                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="printOrder"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.printOrder}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>
                    </Row>


                </Fieldset>


                <Fieldset legend={"Till"}>
                    <Row>


                        <Col md="2">
                            <Fieldset legend="Image(Till)">
                                <Row>
                                    <Col md="1">
                                    </Col>
                                    <FileUpload name="demo"
                                                accept="image/*"
                                                maxFileSize={50000}
                                                onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                invalidFileSizeMessageDetail="Maximum upload size is 50K."
                                                chooseLabel={this.state.webMenu.fileNameTill == null ? "Choose" : this.state.webMenu.fileNameTill}
                                                disabled={!this.state.webMenuSaveButtonRender}
                                                ref={elem => this.refUploadInputImageTill = elem}
                                                mode="basic"></FileUpload>

                                    <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}} onClick={(e) => {
                                        if (e.detail == 1) {//sayfada enter e basınca resim acılmasın diye
                                            try {
                                                GeneralUtils.previewDocument(this.refUploadInputImageTill);
                                            } catch (e) {
                                                this.downloadDocument(TILL_IMAGE);
                                            }
                                        }
                                    }}>
                                    </ButtonSearch>

                                    <ButtonSearch icon="pi pi-minus" disabled={!this.state.webMenuSaveButtonRender}
                                                  style={{marginLeft: '5px'}} onClick={(e) => {
                                        this.removeDocument(TILL_IMAGE)
                                    }}>
                                    </ButtonSearch>
                                </Row>
                            </Fieldset>
                        </Col>

                        <Col md="2">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Tills</Translate>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="tillIdList"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.tillIdList}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>
                        <Col md="1">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="productTag.tradingName">Color&nbsp;</Translate>
                                </Label>
                                <ColorPicker value={this.state.webMenu.color}
                                             format="hex"
                                             name="color"
                                             onChange={this.__handleChange}/>


                            </AvGroup>
                        </Col>

                        <Col md="1">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="productTag.tradingName">Bckgrnd&nbsp;</Translate>
                                </Label>
                                <ColorPicker value={this.state.webMenu.background}
                                             format="hex"
                                             name="background"
                                             onChange={this.__handleChange}/>


                            </AvGroup>
                        </Col>
                        <Col md="1">
                            <AvGroup>
                                <Label for="firstName">
                                    <Translate contentKey="productfirstName">Pinned&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("pinned", e.checked)}
                                          name="pinned"
                                          disabled={!this.state.webMenuSaveButtonRender}
                                          value={this.state.webMenu.pinned}
                                          checked={this.state.webMenu.pinned}></Checkbox>


                            </AvGroup>
                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Border Radius</Translate>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="borderRadius"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.borderRadius}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">
                            <AvGroup>
                                <Label for="firstName">
                                    <Translate contentKey="productfirstName">Lining&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("lining", e.checked)}
                                          name="lining"
                                          disabled={!this.state.webMenuSaveButtonRender}
                                          value={this.state.webMenu.lining}
                                          checked={this.state.webMenu.lining}></Checkbox>


                            </AvGroup>
                        </Col>

                        <Col md="1">
                            <AvGroup>
                                <Label for="firstName">
                                    <Translate contentKey="productfirstName">Condiment&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("condiment", e.checked)}
                                          name="condiment"
                                          disabled={!this.state.webMenuSaveButtonRender}
                                          value={this.state.webMenu.condiment}
                                          checked={this.state.webMenu.condiment}></Checkbox>


                            </AvGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Font</Translate>
                                </Label>

                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="fontSize"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.fontSize}
                                    onChange={this.__handleChange}>
                                    {FONT.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Font Weight</Translate>
                                </Label>

                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="fontWeight"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.fontWeight}
                                    onChange={this.__handleChange}>
                                    {FONT_WEIGHT.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Text Align</Translate>
                                </Label>

                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="textAlign"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.textAlign}
                                    onChange={this.__handleChange}>
                                    {TEXT_ALIGN.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Width</Translate>
                                </Label>

                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="flex"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.flex}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Height</Translate>
                                </Label>

                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="menuStyleHeightFlex"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.menuStyleHeightFlex}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Row</Translate>
                                </Label>

                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="rowNo"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.rowNo}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>

                        <Col md="1">

                            <AvGroup>
                                <Label for="cityId">
                                    <Translate
                                        contentKey="location.cityId">Col</Translate>
                                </Label>

                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="colNo"
                                    disabled={!this.state.webMenuSaveButtonRender}
                                    value={this.state.webMenu.colNo}
                                    onChange={this.__handleChange}>

                                </AvField>
                            </AvGroup>

                        </Col>


                        {this.state.webMenu.hasSubMenu == false &&

                            <Col md="1">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">InLine&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("showInline", e.checked)}
                                              name="showInline"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.showInline}
                                              checked={this.state.webMenu.showInline}></Checkbox>


                                </AvGroup>
                            </Col>
                        }

                        {this.state.webMenu.hasSubMenu == false &&

                            <Col md="1">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Single Line&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("singleLine", e.checked)}
                                              name="singleLine"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.singleLine}
                                              checked={this.state.webMenu.singleLine}></Checkbox>


                                </AvGroup>
                            </Col>
                        }

                    </Row>
                    {this.state.webMenu.hasSubMenu == true &&


                        <Row>

                            <Col md="1">

                                <AvGroup>
                                    <Label for="cityId">
                                        <Translate
                                            contentKey="location.cityId">Page Size</Translate>
                                    </Label>

                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="pageSize"
                                        disabled={!this.state.webMenuSaveButtonRender}
                                        value={this.state.webMenu.pageSize}
                                        onChange={this.__handleChange}>

                                    </AvField>
                                </AvGroup>

                            </Col>

                            <Col md="1">

                                <AvGroup>
                                    <Label for="cityId">
                                        <Translate
                                            contentKey="location.cityId">Row Size</Translate>
                                    </Label>

                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="rowSize"
                                        disabled={!this.state.webMenuSaveButtonRender}
                                        value={this.state.webMenu.rowSize}
                                        onChange={this.__handleChange}>

                                    </AvField>
                                </AvGroup>

                            </Col>

                            <Col md="1">

                                <AvGroup>
                                    <Label for="cityId">
                                        <Translate
                                            contentKey="location.cityId">Space Length</Translate>
                                    </Label>

                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="spaceLength"
                                        disabled={!this.state.webMenuSaveButtonRender}
                                        value={this.state.webMenu.spaceLength}
                                        onChange={this.__handleChange}>

                                    </AvField>
                                </AvGroup>

                            </Col>


                            <Col md="1">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Dummy&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("dummy", e.checked)}
                                              name="dummy"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.dummy}
                                              checked={this.state.webMenu.dummy}></Checkbox>


                                </AvGroup>
                            </Col>

                            <Col md="1">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Sibling&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("displaySiblings", e.checked)}
                                              name="displaySiblings"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.displaySiblings}
                                              checked={this.state.webMenu.displaySiblings}></Checkbox>


                                </AvGroup>
                            </Col>

                            <Col md="1">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">GrandChild&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("displayGrandChilds", e.checked)}
                                              name="displayGrandChilds"
                                              disabled={!this.state.webMenuSaveButtonRender}
                                              value={this.state.webMenu.displayGrandChilds}
                                              checked={this.state.webMenu.displayGrandChilds}></Checkbox>


                                </AvGroup>
                            </Col>


                        </Row>
                    }
                </Fieldset>


                {GeneralUtils.checkPrivilege("WebMenu", "Create") && this.state.webMenuSaveButtonRender == false &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({webMenuSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("WebMenu", "Create") && this.state.webMenuSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveWebMenu();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("WebMenu", "Delete") && this.state.webMenuSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.webMenu.id) &&

                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.showDeleteConfirmDialog(true)
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("WebMenu", "Create") && this.state.webMenuSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                        this.returnListPage();

                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }


            </Fieldset>
        </div>)
    };

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    setData = (value) => {
        this.productGroupService.findAll().then(response => this.setState({mainGroupList: response}));
        let webMenu = this.state.webMenu;
        webMenu[this.state.productGroupName] = value;
        this.setState({displayDialog: false, webMenu: webMenu});
    }

    setChecked = (name, value) => {
        let webMenu = this.state.webMenu;
        webMenu[name] = value;
        this.setState({webMenu: webMenu});
    }

    __handleChangeBarcode = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({barcode: value});
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.findByBarcode();
        }
    }

    searchProduct = (event) => {
        setTimeout(() => {
            this.productService.searchProductsByName(event.query).then(response => {
                this.setState({filteredProducts: response});

            });
        }, 250);
    }

    downloadDocument = (type) => {
        if (!GeneralUtils.isNullOrEmpty(this.state.webMenu.id)) {
            this.webMenuService.downloadDocument(this.state.webMenu.id, type).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.convertToDownloadableFile(response);
                }
            });
        }
    }

    findByBarcode() {
        if (!GeneralUtils.isNullOrEmpty(this.state.barcode)) {
            this.productService.findProductByBarcode(this.state.barcode)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let data = response;
                        let webMenu = this.state.webMenu;
                        webMenu["longname"] = data.longname;
                        webMenu["name"] = data.longname;
                        webMenu["productId"] = data.id;
                        this.setState({
                            webMenu: webMenu,
                            barcode: '',
                            selectedProduct: null,
                            productId: null,
                            productList: []
                        });
                    } else {
                        this.setState({
                            barcode: '', selectedProduct: null, productId: null, productList: []
                        });
                        return toast.warn("Barcode not Found!");
                    }
                });
        }
        this.setState({barcode: ''});

    }

    __handleChangeMainGroup = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let webMenu = this.state.webMenu;
        webMenu[e.target.name] = value;
        this.setState({webMenu: webMenu});

        if (!GeneralUtils.isNullOrEmpty(value) && !GeneralUtils.isNullOrEmpty(this.state.mainGroupList)) {
            for (var i = 0; i < this.state.mainGroupList.length; i++) {
                if (this.state.mainGroupList[i].id == value) {
                    this.setState({productList: this.state.mainGroupList[i].products});
                }

            }
        } else {
            this.setState({productList: []});
        }
    }
    __handleChangeProduct = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.productService.findById(value).then((response) => {
            let webMenu = this.state.webMenu;
            webMenu["longname"] = response.longname;
            webMenu["name"] = response.longname;
            webMenu["productId"] = value;
            webMenu["productName"] = response.name;
            webMenu["productBarcode"] = response.barcode;

            this.setState({
                webMenu: webMenu,
                barcode: '',
                selectedProduct: null,
                productId: null,
                productList: []
            });
        });
    }

    saveWebMenu = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.webMenu.name)) {
            return toast.warn("Please fill the required fields!");
        } else if (this.state.webMenu.hasSubMenu == false && GeneralUtils.isNullOrEmpty(this.state.webMenu.productId)) {
            return toast.warn("Product is missing!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.webMenu.id)) {
                this.webMenuService.update(this.state.webMenu)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                webMenuSaveButtonRender: false,
                                webMenu: response,
                                webMenuTemp: Object.assign({}, response)
                            });
                            this.uploadDocument(response.id);
                            this.returnListPage();
                        }
                    });
            } else {
                this.webMenuService.create(this.state.webMenu)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                webMenuSaveButtonRender: false,
                                webMenu: response,
                                webMenuTemp: Object.assign({}, response)
                            });
                            this.uploadDocument(response.id);
                            this.returnListPage();
                        }
                    });
            }
        }
    }

    removeDocument(type) {
        if (!GeneralUtils.isNullOrEmpty(this.state.webMenu.id)) {
            this.webMenuService.removeDocument(this.state.webMenu.id, type).then(response => {
                this.setState({
                    webMenuSaveButtonRender: false,
                    webMenu: response,
                })
            });
        } else {
            if (type == WEB_MENU_SMALL_IMAGE) {
                this.refUploadInputImage.clear();
            } else {
                this.refUploadInputImageTill.clear();
            }

        }

    }

    uploadDocument(id) {
        let image = this.refUploadInputImage.files;
        let imageTill = this.refUploadInputImageTill.files;
        if (!(GeneralUtils.isNullOrEmpty(image) || GeneralUtils.isNullOrEmpty(image.length))) {
            let formData = new FormData();
            if (!GeneralUtils.isNullOrEmpty(image) && !GeneralUtils.isNullOrEmpty(image.length)) {
                formData.append("file", image[0]);
                this.webMenuService.uploadDocument(id, formData, WEB_MENU_SMALL_IMAGE).then(response => {
                    this.setState({
                        webMenuSaveButtonRender: false
                    })
                });
            }
        }

        if (!(GeneralUtils.isNullOrEmpty(imageTill) || GeneralUtils.isNullOrEmpty(imageTill.length))) {
            let formData = new FormData();
            if (!GeneralUtils.isNullOrEmpty(imageTill) && !GeneralUtils.isNullOrEmpty(imageTill.length)) {
                formData.append("file", imageTill[0]);
                this.webMenuService.uploadDocument(id, formData, TILL_IMAGE).then(response => {
                    this.setState({
                        webMenuSaveButtonRender: false
                    })
                });
            }
        }
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let webMenu = this.state.webMenu;
        webMenu[e.target.name] = value;
        this.setState({webMenu: webMenu});
    }

}

