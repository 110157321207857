import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantBranchCredentialsService extends Service {
    constructor() {
        super();
        this.baseURL = 'merchant/credentials/';
    }



}