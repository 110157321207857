import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class CustomerService extends Service {
    constructor() {
        super();
        this.baseURL = 'customer/';
    }

    async findAddressesOfUser(userId) {
        return fetch(SERVER_URL + this.baseURL + "address/user/"+userId, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findByAddressId(id) {
        return fetch(SERVER_URL + this.baseURL + "address/"+id, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}