import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {
    APP_DATE_FORMAT,
    convertDateTimeWithFormat,
    HOME_PAGE, PERSON_TITLE,
    STATUS,
    YESNO
} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import MerchantUserService from "../../shared/service/MerchantUserService";
import {PhoneField} from "../../shared/component/form/PhoneField";
import MerchantUserColumn from "../../shared/component/dataTable/columns/MerchantUserColumn.json";
import {PickList} from "primereact/picklist";
import MerchantAccountService from "../../shared/service/MerchantAccountService";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";
import MerchantBranchService from "../../shared/service/MerchantBranchService";

const defaultMerchantUser = {
    id: '',
    userName: '',
    status: 'ACTIVE',
    mobilePhone: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    branches: [],
    locked: 'N',
    pin: null,
    password: '',
    wrongEntryNumbers: 0,
    superUser: false,
    activationDate: null,
    passwordChangeDate: null,
    merchantAccountId: null,
    landingPage: null,
    title: 'MR',
    sendNotification: true,
    url: GeneralUtils.getUiUrl()
};
export default class MerchantUserSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantUser: defaultMerchantUser,
            merchantUserTemp: defaultMerchantUser,
            merchantUserList: [],
            merchantUserDetailsSaveButtonRender: false,
            tillUserDetailsSaveButtonRender: false,
            passwordSaveButtonRender: null,
            merchantStatus: 'ACTIVE',
            roleList: [],
            availableRoleList: [],
            selectedRoleList: [],
            branchList: [],
            branchId: null,
            tillUserId: null,
            tillUserList: [],
            availableBranchList: [],
            selectedBranchList: [],
            merchantAccountId: null

        }
        this.merchantUserService = new MerchantUserService();
        this.merchantAccountService = new MerchantAccountService();
        this.merchantBranchService = new MerchantBranchService();
    }

    componentDidMount() {

        let obj1 = Object.assign({}, defaultMerchantUser);
        let merchantAccountIdTemp = window.location.href.split('?')[1];
        obj1.merchantAccountId = merchantAccountIdTemp;

        this.merchantUserService.merchantRolesFindAll().then(response => {
            let roleListTemp = response;
            this.merchantBranchService.findBranchesByAccountIdForCombo(merchantAccountIdTemp).then(response => {

                this.setState({
                    merchantUser: obj1,
                    merchantUserTemp: Object.assign({}, obj1),
                    branchList: response,
                    roleList: roleListTemp,
                    merchantAccountId: merchantAccountIdTemp,
                    availableRoleList: roleListTemp,
                    availableBranchList: response

                });
                this.refresh(merchantAccountIdTemp);
            });
        });


    }

    refresh(merchantAccountId) {
        this.merchantUserService.findByMerchantAccountId(merchantAccountId).then(response => this.setState({
            merchantUserList: response
        }));
    }

    render() {
        return (<div>
            <AvForm autocomplete="off">
                {this.merchantUserDetails()}
            </AvForm>
        </div>);
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantUser.id)) {
            this.merchantUserService.updateStatus(this.state.merchantUser.id, this.state.merchantStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            merchantUser: response,
                            merchantUserTemp: Object.assign({}, response),
                            merchantUserDetailsSaveButtonRender: false
                        });
                        this.refresh(this.state.merchantAccountId);

                    }
                });
        } else {
            let merchantUser = this.state.merchantUser;
            merchantUser["status"] = this.state.merchantUser.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({merchantUser: merchantUser, merchantUserTemp: Object.assign({}, merchantUser),});
        }

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let merchantStatus = 'PASSIVE';
        if (value) {
            merchantStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            merchantStatus: merchantStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }

    merchantUserDetails() {

        return (<div class="p-col-12">
                <Fieldset legend="Merchant User Details">
                    <Row>


                        <Col md="12">

                            <Row>
                                <Col md="3">
                                    <Fieldset legend="Status">
                                        <Col md="3">
                                        </Col>
                                        <Col md="9">
                                            <Row>
                                                <AvField
                                                    type="select"
                                                    className="form-control"
                                                    name="status"
                                                    disabled={true}
                                                    value={this.state.merchantUser.status}
                                                    onChange={this.__handleChange}>
                                                    {STATUS.map(element => (
                                                        <option value={element.value} key={element.value}>
                                                            {element.name}
                                                        </option>))}
                                                </AvField>


                                                <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                              onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                              show={this.state.displayShowStatusConfirmDialog}
                                                              message={this.state.noticeResponse}/>

                                                <InputSwitch
                                                    checked={this.state.merchantUser.status == "ACTIVE" ? true : false}
                                                    tooltip={"Change Status"}
                                                    disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                                    onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                            </Row>

                                        </Col>


                                    </Fieldset>

                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantAccount.tradingName">User Name</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="userName"
                                            disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.merchantUser.userName}
                                            onChange={this.__handleChange}/>

                                    </AvGroup>
                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="emailAddress">
                                            <Translate contentKey="merchantAccount.emailAddress">Email</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                            validate={{
                                                minLength: {
                                                    "value": 5, "errorMessage": "Not Valid Email"
                                                }, maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.merchantUser.email}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>


                                <Col md="3">
                                    <AvGroup>
                                        <Label for="lastName">
                                            <Translate contentKey="merchantAccount.lastName">Password</Translate>
                                        </Label>

                                        <AvField
                                            value={this.state.merchantUser.password}
                                            style={{webkitTextSecurity: "disc", textSecurity: "disc"}}
                                            name="password"
                                            type={"text"}
                                            className="form-control"
                                            disabled={!(this.state.passwordSaveButtonRender)}
                                            onChange={this.__handleChange}
                                        />


                                    </AvGroup>
                                </Col>


                            </Row>

                            <Row>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Title</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="title"
                                            disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                            value={this.state.merchantUser.title}
                                            onChange={this.__handleChange}>
                                            {PERSON_TITLE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}

                                        </AvField>
                                    </AvGroup>
                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="firstName">
                                            <Translate contentKey="merchantAccount.firstName">First Name</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.merchantUser.firstName}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="lastName">
                                            <Translate contentKey="merchantAccount.lastName">Last Name</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.merchantUser.lastName}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>


                                <Col md="3">
                                    <AvGroup>
                                        <Label for="mobilePhone">
                                            <Translate contentKey="contactManagement.mobilePhone">Mobile
                                                Phone</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <PhoneField kind={'mobile'}
                                                    disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                                    name="mobilePhone" errorMessage="Not Valid Phone Number"
                                                    value={this.state.merchantUser.mobilePhone}
                                                    onChange={this.__handleChange}
                                        />
                                    </AvGroup>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Fieldset legend="Roles">
                                <AvGroup>
                                    <PickList source={this.state.availableRoleList} target={this.state.selectedRoleList}
                                              itemTemplate={this.pickListTemplateRole}
                                              disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                              onChange={(e) => {
                                                  if (this.state.merchantUserDetailsSaveButtonRender)
                                                      this.setState({
                                                          availableRoleList: e.source, selectedRoleList: e.target
                                                      });
                                              }}
                                              sourceHeader="Available Roles" targetHeader="Selected Roles"
                                              responsive={true}
                                              showSourceControls={false} showTargetControls={false}
                                              sourceStyle={{height: '100px'}} targetStyle={{height: '100px'}}/>
                                </AvGroup>

                            </Fieldset>
                        </Col>
                        <Col md="6">
                            <Fieldset legend="Branches">
                                <AvGroup>
                                    <PickList source={this.state.availableBranchList}
                                              target={this.state.selectedBranchList}
                                              disabled
                                              itemTemplate={this.pickListTemplateBranch}
                                              disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                              onChange={(e) => {
                                                  if (this.state.merchantUserDetailsSaveButtonRender)
                                                      this.setState({
                                                          availableBranchList: e.source, selectedBranchList: e.target
                                                      });
                                              }}
                                              sourceHeader="Available Branches" targetHeader="Selected Branches"
                                              responsive={true}
                                              showSourceControls={false} showTargetControls={false}
                                              sourceStyle={{height: '100px'}} targetStyle={{height: '100px'}}/>
                                </AvGroup>
                            </Fieldset>

                        </Col>

                        <Col md="3">
                            <AvGroup>
                                <Label for="lastName">
                                    <Translate contentKey="merchantAccount.lastName">Landing Page</Translate>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="landingPage"
                                    disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                    value={this.state.merchantUser.landingPage}
                                    onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {HOME_PAGE.map(element => (<option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>

                        <Col md="3">
                            <AvGroup>
                                <Label for="lastName">
                                    <Translate contentKey="merchantAccount.lastName">Send Notification?</Translate>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="sendNotification"
                                    disabled={!this.state.merchantUserDetailsSaveButtonRender}
                                    value={this.state.merchantUser.sendNotification}
                                    onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {YESNO.map(element => (<option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>

                    </Row>


                    {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.merchantUserDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.merchantUser.id) &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.setState({
                                merchantUserDetailsSaveButtonRender: true,
                                passwordSaveButtonRender: null,
                                tillUserDetailsSaveButtonRender: false
                            });
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}

                    {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.passwordSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.merchantUser.id) &&
                        <Button color="outline-primary" style={{width: '200px', height: "27px"}} onClick={() => {
                            this.setState({passwordSaveButtonRender: true});
                        }}>
                            <Translate contentKey="entity.action.add">Change Password</Translate>
                        </Button>}

                    {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.passwordSaveButtonRender == true &&
                        <Button color="outline-primary" style={{width: '200px', height: "27px"}} onClick={() => {

                            this.changePassword();
                        }}>
                            <Translate contentKey="entity.action.add">Save Password</Translate>
                        </Button>
                    }

                    {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.merchantUserDetailsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                            this.saveMerchantUser();
                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.merchantUserDetailsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                            let obj1 = this.state.merchantUserTemp;

                            this.setState({
                                merchantUser: Object.assign({}, obj1),
                                merchantUserDetailsSaveButtonRender: false,
                                passwordSaveButtonRender: false
                            });

                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }
                </Fieldset>

                {!GeneralUtils.isNullOrEmpty(this.state.merchantUser.id) &&
                    <Fieldset legend="Till Users">

                        <Row>

                            <Col md="3">

                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Branch</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.tillUserDetailsSaveButtonRender}
                                        className="form-control"
                                        name="branchId"
                                        value={this.state.branchId}
                                        onChange={this.__handleChangeBranch}>

                                        <option value='' key=''/>
                                        {this.state.selectedBranchList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.tradingName}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>
                            <Col md="3">


                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Till User
                                        </Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.tillUserDetailsSaveButtonRender}
                                        className="form-control"
                                        name="tillUserId"
                                        value={this.state.tillUserId}
                                        onChange={this.__handleChangeTillUser}>

                                        <option value='' key=''/>
                                        {this.state.tillUserList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>
                            <Col md="3">


                                {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.tillUserDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.merchantUser.id) &&
                                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                        this.setState({
                                            tillUserDetailsSaveButtonRender: true,
                                            merchantUserDetailsSaveButtonRender: false
                                        });
                                    }}>
                                        <Translate contentKey="entity.action.add">Edit Till User</Translate>
                                    </Button>}


                                {GeneralUtils.checkPrivilege("MerchantUser", "Create") && this.state.tillUserDetailsSaveButtonRender == true && !GeneralUtils.isNullOrEmpty(this.state.merchantUser.id) &&
                                    <Button color="outline-primary" style={{marginRight: '5px', width: '120px'}}
                                            onClick={() => {
                                                this.updateTillUser();
                                            }}>
                                        <Translate contentKey="entity.action.add">Save Till User</Translate>
                                    </Button>}

                            </Col>

                        </Row>
                    </Fieldset>


                }

                <Fieldset legend={"Merchant Users"}>
                    {GeneralUtils.checkPrivilege("MerchantUser", "Add") &&
                        <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                            this.addNew();
                        }}>
                            <Translate contentKey="entity.action.add">Add</Translate>
                        </Button>
                    }

                    {GeneralUtils.checkPrivilege("MerchantUser", "View") &&
                        <DataTableAll ref={(elem) => this.refDataTable = elem} fields={MerchantUserColumn.fields}
                                      objectName={MerchantUserColumn.name}
                                      data={this.state.merchantUserList}
                                      rows={5}
                                      selectionMode="single" onDataSelect={this.onDataSelect}
                                      loading={this.state.loading}/>
                    }
                </Fieldset>


            </div>
        )
    };


    setChecked = (name, value) => {
        let merchantUser = this.state.merchantUser;
        merchantUser[name] = value;
        this.setState({merchantUser: merchantUser});
    }


    getDisabledFields() {
        return <>
            <Col md="9">
                <AvGroup>
                    <Label for="wrongEntryNumbers">
                        <Translate contentKey="userManagement.wrongEntryNumbers">Wrong Entry
                            Numbers</Translate>
                    </Label>
                    <AvField type="text" className="form-control" name="wrongEntryNumbers" disabled
                             value={!this.state.merchantUser.wrongEntryNumbers ? "0" : this.state.merchantUser.wrongEntryNumbers}/>{' '}
                </AvGroup>

            </Col>
            <Col md="9">
                <AvGroup>
                    <AvGroup>
                        <Label for="activationDate">
                            Activation Date
                        </Label>
                        <AvField type="text" className="form-control" name="activationDate" disabled
                                 value={convertDateTimeWithFormat(this.state.merchantUser.activationDate, APP_DATE_FORMAT)}/>{' '}
                    </AvGroup>
                    <AvGroup>
                        <Label for="passwordChangeDate">
                            Password Change Date
                        </Label>
                        <AvField type="text" className="form-control" name="passwordChangeDate"
                                 disabled
                                 value={convertDateTimeWithFormat(this.state.merchantUser.passwordChangeDate, APP_DATE_FORMAT)}/>{' '}
                    </AvGroup>
                </AvGroup>
            </Col>


            <Col md="9">

                <AvGroup>
                    <Label for="mobilePhone">
                        <Translate contentKey="contactManagement.mobilePhone">Super User</Translate>
                        <span style={{color: 'red'}}> (*)</span>
                    </Label>
                    <AvField
                        type="select"
                        className="form-control"
                        name="superUser"
                        disabled={true}
                        value={this.state.merchantUser.superUser}
                        onChange={this.__handleChange}>
                        {YESNO.map(element => (<option value={element.value} key={element.value}>
                            {element.name}
                        </option>))}
                    </AvField>

                </AvGroup>


            </Col>
        </>;
    }

    setMultiSelectValueRole = (data) => {
        let availableRoleList = Object.assign([], this.state.roleList);
        let selectedRoleList = []
        data.roles.forEach((element) => {
            selectedRoleList.push(element);
            availableRoleList.forEach((data) => {
                if (data.id === element.id) {
                    availableRoleList.splice(availableRoleList.indexOf(data), 1);
                }
            });
        });

        this.setState({
            availableRoleList: availableRoleList, selectedRoleList: selectedRoleList
        });
    }

    setMultiSelectValueBranch = (data) => {
        let availableBranchList = Object.assign([], this.state.branchList);
        let selectedBranchList = []
        data.branches.forEach((element) => {
            availableBranchList.forEach((role) => {

                if (role.id === element.branchId) {
                    availableBranchList.splice(availableBranchList.indexOf(role), 1);
                }
            });
        });

        let availableBranchList1 = Object.assign([], this.state.branchList);

        availableBranchList1.forEach((element) => {

            data.branches.forEach((role) => {
                if (role.branchId === element.id) {
                    selectedBranchList.push(element);
                }
            });

        });

        this.setState({
            availableBranchList: availableBranchList,
            selectedBranchList: selectedBranchList
        });
    }

    onDataSelect = (data) => {
        this.merchantUserService.findById(data.id).then(response => {
            this.setMultiSelectValueRole(response);
            this.setMultiSelectValueBranch(response);
            this.setState({
                merchantUser: response,
                branchId: null,
                tillUserId: null,
                merchantUserTemp: Object.assign({}, response),
                merchantUserDetailsSaveButtonRender: false,
                tillUserDetailsSaveButtonRender: false,
                passwordSaveButtonRender: false
            });

            if (!GeneralUtils.isNullOrEmpty(this.state.selectedBranchList) && this.state.selectedBranchList.length > 0) {
                this.setState({
                    branchId: this.state.selectedBranchList[0].id,
                });
                this.getTillUsers(this.state.selectedBranchList[0].id);
            } else {
                this.setState({
                    branchId: null,
                });
            }


        });

    }
    getData = () => {
        let merchantUser = this.state.merchantUser;
        merchantUser.roles = this.state.selectedRoleList;
        merchantUser.branches = this.state.selectedBranchList.map(x => Object.assign({}, x, {"branchId": x.id}))
        return merchantUser;
    }

    pickListTemplateRole(data) {
        return (<div className="p-clearfix">
            <div>{data.name}</div>
        </div>);
    }

    pickListTemplateBranch(data) {
        return (<div className="p-clearfix">
            <div>{data.tradingName}</div>
        </div>);
    }

    changePassword = () => {
        if (
            GeneralUtils.isNullOrEmpty(this.state.merchantUser.password)
        ) {
            return toast.warn("Please fill the password field!");
        } else if (!GeneralUtils.checkPasswordFormat(this.state.merchantUser.password)) {
            return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
        } else if (!GeneralUtils.isNullOrEmpty(this.state.merchantUser.id)) {
            this.merchantUserService.changeUserPassword(this.state.merchantUser.id, this.state.merchantUser.password)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            passwordSaveButtonRender: null,
                            merchantUser: response,
                            merchantUserTemp: Object.assign({}, response),
                        });
                    }
                });
        }
    }

    updateTillUser = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.merchantUser.id) || GeneralUtils.isNullOrEmpty(this.state.branchId)) {
            return toast.warn("Please select a branch and a till user!");
        } else if (!GeneralUtils.isNullOrEmpty(this.state.merchantUser.id)) {
            this.merchantUserService.updateTillUser(this.state.merchantUser.id, this.state.branchId, this.state.tillUserId)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            tillUserDetailsSaveButtonRender: false,

                        });
                        response.forEach(itemSub => {
                            if (itemSub.userId == this.state.merchantUser.id) {
                                this.setState({
                                    tillUserId: itemSub.id,
                                });
                            }
                        });
                        this.setState({
                            tillUserList: response,
                        });
                    }
                });
        }
    }

    checkRoleGroupsNumber() {
        var result = false;
        var accountRoleNumbers = 0;
        this.state.selectedRoleList.forEach(item => {
            if (item.name.indexOf("ACCOUNT") >= 0) {
                accountRoleNumbers++;
            }
        });
        if (accountRoleNumbers ==0 || accountRoleNumbers >=2) {
            result = true;
        }
        return result;
    }

    saveMerchantUser = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.merchantUser.userName) ||
            GeneralUtils.isNullOrEmpty(this.state.merchantUser.mobilePhone) ||
            GeneralUtils.isNullOrEmpty(this.state.merchantUser.firstName) ||
            GeneralUtils.isNullOrEmpty(this.state.merchantUser.lastName) ||
            GeneralUtils.isNullOrEmpty(this.state.merchantUser.email)
        ) {
            return toast.warn("Please fill the required fields!");
        } else if (this.state.selectedBranchList.length == 0) {
            return toast.warn("Please select a branch!");
        } else if (this.state.selectedRoleList.length == 0) {
            return toast.warn("Please select a role!");
        } else if (this.checkRoleGroupsNumber()) {
            return toast.warn("You must choose one role from 'ACCOUNT ROLES' ");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.merchantUser.id)) {
                this.merchantUserService.update(this.getData())
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                merchantUserDetailsSaveButtonRender: false,
                                merchantUser: response,
                                merchantUserTemp: Object.assign({}, response)

                            });

                            this.setMultiSelectValueRole(response);
                            this.setMultiSelectValueBranch(response);
                            this.refresh(this.state.merchantAccountId);
                        }
                    });
            } else {
                this.merchantUserService.create(this.getData())
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                            this.setState({
                                merchantUserDetailsSaveButtonRender: false,
                                merchantUser: response,
                                merchantUserTemp: Object.assign({}, response),
                            });

                            this.setMultiSelectValueRole(response);
                            this.setMultiSelectValueBranch(response);
                            this.refresh(this.state.merchantAccountId);
                        }
                    });
            }
        }

    }

    addNew() {
        let obj1 = Object.assign({}, defaultMerchantUser);
        obj1.merchantAccountId = this.state.merchantAccountId;
        this.setState({
            merchantUser: obj1,
            merchantUserTemp: Object.assign({}, obj1),
            merchantUserDetailsSaveButtonRender: true,
            passwordSaveButtonRender: null,
            selectedRoleList: [],
            selectedBranchList: [],
            availableRoleList: this.state.roleList,
            availableBranchList: this.state.branchList
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let merchantUser = this.state.merchantUser;
        merchantUser[e.target.name] = value;
        this.setState({merchantUser: merchantUser});
    }

    getTillUsers(branchId) {
        this.merchantUserService.getTillUsers(branchId).then(response => {
            response.forEach(itemSub => {
                if (itemSub.userId == this.state.merchantUser.id) {
                    this.setState({
                        tillUserId: itemSub.id,
                    });
                }
            });
            this.setState({
                tillUserList: response,
            });
        });
    }

    __handleChangeBranch = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.getTillUsers(value);
        } else {
            this.setState({tillUserList: []});
        }
        this.setState({branchId: value});
    }

    __handleChangeTillUser = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({tillUserId: value});
    }

}

