import React from 'react';
import {Button, Col, Label, Row, Card} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import PdfDocument from "../merchant/PdfDocument";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import Loading from "../../shared/component/others/Loading";
import {SERVER_URL} from '../../shared/utils/Constants';



const defaultBarcode = {
    url: null
};
export default class BarcodeGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barcode: defaultBarcode,
            loading: false,
            barcodeSource: ''

        }
        this.merchantBranchService = new MerchantBranchService();
    }


    componentDidMount() {
        let obj1 = Object.assign({}, defaultBarcode);
        obj1.url=SERVER_URL.replace("/posmarket/","");
        this.setState({
            barcode: obj1
        });

    }


    render() {
        return (<div>
            <AvForm>
                {this.barcodeDetails()}
                {this.barcodeReport()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    barcodeDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Qr Code">

                <Row>

                    <Col md="4">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="tax.tradingName">Url</Translate>
                                <span style={{color: 'red'}}> (*)</span>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="url"
                                disabled={false}
                                validate={{
                                    maxLength: {
                                        value: 255,
                                        errorMessage: "You can enter up to 255 characters in this field."
                                    }
                                }}
                                value={this.state.barcode.url}
                                onChange={this.__handleChange}/>

                        </AvGroup>
                    </Col>
                </Row>

                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.downloadBarcode();
                }}>
                    <Translate contentKey="entity.action.add">Download</Translate>
                </Button>

                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.printBarcode();
                }}>
                    <Translate contentKey="entity.action.add">Print</Translate>
                </Button>

            </Fieldset>
        </div>)
    };

    printBarcode = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.barcode.url)) {
            return toast.warn("Empty Url");
        } else {

            this.setState({loading: true});
            this.merchantBranchService.generateBarcodeForAddress(this.state.barcode).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.printFile(response);
                    this.setState({loading: false});
                }
            });
        }

    }
    downloadBarcode = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.barcode.url)) {
            return toast.warn("Empty Url");
        } else {
            this.setState({loading: true});
            this.merchantBranchService.generateBarcodeForAddress(this.state.barcode).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.downloadFile(response);
                }
                this.setState({loading: false});
            });
        }

    }
    barcodeReport() {
        if (!GeneralUtils.isNullOrEmpty(this.state.barcodeSource)) {
            return (
                <div>
                    <Fieldset legend="Barcode">
                        <div className="all-page-container">
                            <PdfDocument pdf={this.state.barcodeSource}
                            />
                        </div>
                    </Fieldset>
                </div>
            );
        } else {
            return ''
        }
    }




    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let barcode = this.state.barcode;
        barcode[e.target.name] = value;
        this.setState({barcode: barcode});
    }


}

