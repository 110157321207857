import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeicons/primeicons.css';
import '../layout/layout.css';
import HashRouterCompMerchant from "../HashRouterCompMerchant";
import GeneralUtils from "../../utils/GeneralUtils";
import {AppTopbarMerchant} from "./AppTopbarMerchant";
import PageSettingsService from "../../service/PageSettingsService";
import {AppInlineProfileMerchant} from "./AppInlineProfileMerchant";
import {Col, Row} from "reactstrap";
import {AppFooter} from "./AppFooter";


class AppMasterMerchant extends Component {
    constructor() {
        super();
        this.state = {
            logo: '',
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: null
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.pageSettingsService = new PageSettingsService();

    }

    componentDidMount = () => {

        if(!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId"))){
            this.pageSettingsService.getImage(localStorage.getItem("merchantBranchId"), "LOGO").then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    this.setState({logo: GeneralUtils.convertForImageSource(response.file, response.fileType, response.fileName)});
                }
            });
        }


    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;
        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            } else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        } else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
        setTimeout(() => {
        }, 500);
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {
        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });
        let sidebarClassName = classNames("layout-sidebar", {'layout-sidebar-dark': this.state.layoutColorMode === 'dark'});

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick} >
                <AppTopbarMerchant onToggleMenu={this.onToggleMenu} logout={this.props.logout}/>

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

                    <div className="layout-sidebar-scroll-content">
                        <div className="layout-logo">

                            {this.state.logo != '' &&
                                <a href='#/'>
                                    <img alt="Logo" src={this.state.logo} style={{height: '65px',marginTop:'50px'}}/>
                                </a>
                            }
                        </div>
                        <AppInlineProfileMerchant logout={this.props.logout}/>
                    </div>

                </div>
                <div className="layout-main">
                    <HashRouterCompMerchant/>
                </div>
                <AppFooter></AppFooter>
            </div>

        );
    }
}

AppMasterMerchant.propTypes = {
    logout: PropTypes.func,
};


export default AppMasterMerchant;
