import React from 'react';
import {AvForm} from 'availity-reactstrap-validation';
import OrdersDisplayColumn from "../../shared/component/dataTable/columns/OrdersDisplayColumn.json";
import {Fieldset} from "primereact/fieldset";
import ReactCountdownClock from "react-countdown-clock";
import {Row} from "reactstrap";
import OrderService from "../../shared/service/OrderService";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";

export default class OrdersDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 10,
            pageNumber: 0,
            maxOrderDateLong: null
        };
        this.orderService = new OrderService();
    }

    componentDidMount() {
        this.getOrders();
    }


    getOrders() {

        this.orderService.displayOrders(this.state.pageNumber).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({
                    pageNumber: this.state.pageNumber + 1
                })
                this.setState({
                    orderList: response
                })
            } else {
                if (response.type == "ERROR") {
                    this.setState({
                        pageNumber: -1
                    })
                    return toast.warn(response.details);
                } else {
                    if (this.state.pageNumber > 0) {
                        this.orderService.displayOrders(0).then(response => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                this.setState({
                                    orderList: response
                                })
                            }
                        })

                    }
                    this.setState({
                        pageNumber: 0
                    })
                }

            }
        })


        this.setState({
            seconds: this.state.seconds + 0.00000001
        });
    }

    render() {
        return (<div>
            <AvForm>
                {this.newOrderList()}
            </AvForm>
        </div>);
    }

    countDownClock = () => {
        this.getOrders();
    }

    newOrderList() {

        return (this.state.pageNumber != -1 && <div>
            <div style={{display: "none"}}>
                <Row className="justify-content-center">
                    <ReactCountdownClock seconds={this.state.seconds}
                                         color="#1a1a1a"
                                         showMilliseconds={false}
                                         alpha={0.9}
                                         size={50}
                                         onComplete={this.countDownClock}/>
                </Row>
            </div>
                <DataTableAll ref={(elem) => this.refDataTable = elem} fields={OrdersDisplayColumn.fields}
                              objectName={OrdersDisplayColumn.name}
                              data={this.state.orderList}
                              emptyMessage="Not any order"
                              selectionMode="single"
                              loading={this.state.loading}/>

        </div>)
    };

    refreshDataTable = () => {
        this.getOrders();
    }

}

