import React, {Component} from 'react';

import {Badge, Button, Col, Label, Row} from "reactstrap";
import GeneralUtils from "../../utils/GeneralUtils";
import Translate from "../../language/Translate";
import {Dialog} from "primereact/dialog";
import {AvForm} from 'availity-reactstrap-validation';


export class AppFooterMember extends Component {
    constructor() {
        super();
        this.state = {
        };



    }

    componentDidMount = () => {

    }

    render() {
        return (
            <AvForm>
                <footer className="footerMember">
                    <div className="p-col-12" style={{display: 'block', padding: 15, textAlign: 'center'}}>
                        {!GeneralUtils.isMobile() &&
                            <Row>
                                <Col md={12}>
                                    {this.renderRefundPolicy()}
                                </Col>
                            </Row>
                        }

                        {GeneralUtils.isMobile() &&
                            <Row>
                                <Col md={12}>
                                    {this.renderRefundPolicy()}
                                </Col>

                            </Row>
                        }
                    </div>
                    {GeneralUtils.isMobile() &&
                        <div>
                            <br></br>
                            <br></br>
                        </div>
                    }
                </footer>
            </AvForm>

        );
    }


    renderRefundPolicy() {
        return (<div><label htmlFor="openRefundPolicyDialog"><a
            id="myLink"
            href="javascript:void(0);" onClick={() => {
            this.setState({openRefundPolicyDialog: true})

        }} style={{zoom: 0.7}}>Return/Refund
            Policy</a></label>
            <Dialog header="Return/Refund Policy"
                    visible={this.state.openRefundPolicyDialog}
                    style={GeneralUtils.getDialogStyleForDialog()}
                    onHide={() => this.setState({openRefundPolicyDialog: false})}>

                <p style={{textAlign: "left"}}>
                    {this.getTermsAndConditionText()}

                </p>

                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                        onClick={() => {
                            this.setState({openRefundPolicyDialog: false})

                        }}>
                    <Translate contentKey="entity.action.cancel">Close</Translate>
                </Button>

            </Dialog></div>);
    }

    getTermsAndConditionText() {
        let form = [];
        form.push(<div dangerouslySetInnerHTML={{
            __html: "<b><p>Refund Policy Online Orders</p></b>\n" +
                "<p>We value your satisfaction and aim to provide the best products and services possible. We understand that there may be instances where you need to request a refund for your online order. Please take a moment to review our refund policy to understand the procedures and conditions.</p>\n" +
                "<b><p>1. Refund Eligibility:</p></b>\n" +
                "<p>We offer refunds under the following circumstances:</p>\n" +
                "<p>a. Order Cancellation: If you wish to cancel your order before it has been processed or shipped, please contact us as soon as possible. We will issue a full refund for orders canceled within 3 days of placement.</p>\n" +
                "<p>b. Damaged or Defective Products: If you receive a damaged or defective product, please notify us within 3 days of receiving your order. We will arrange for a refund or replacement, depending on your preference and product availability.</p>\n" +
                "<p>c. Incorrect Items: If you receive items that are different from what you ordered, please contact us within 3 days of receiving your order. We will either send the correct items or issue a refund.</p>\n" +
                "<b><p>2. Refund Process:</p></b>\n" +
                "<p>To request a refund, please follow these steps:</p>\n" +
                "<p>a. Contact Customer Support: Reach out to our customer support team via store contact email or phone number with your order number and a clear description of the issue.</p>\n" +
                "<p>b. Provide Evidence: For damaged, defective, or incorrect items, please provide clear photographs of the problem to help us assess the situation accurately.</p>\n" +
                "<p>c. Review and Approval: Our team will review your refund request and may require additional information if necessary. We will notify you of our decision regarding your refund request.</p>\n" +
                "<p>d. Refund Issuance: If your refund is approved, we will initiate the refund process. Refunds will be processed through the same payment method used for the original order unless otherwise specified.</p>\n" +
                "<b><p>3. Non-Refundable Items:</p></b>\n" +
                "<p>a. Change of Mind: We do not offer refunds for orders where the customer changes their mind or is dissatisfied with the product but the product is as described and not defective.</p>\n" +
                "<p>b. Shipping Costs: Shipping fees are non-refundable unless there was an error on our part in shipping the incorrect items or if the items arrived damaged or defective.</p>\n" +
                "<b><p>4. Refund Timelines:</p></b>\n" +
                "<p>Refunds will typically be processed within 3 business days after approval. Please note that the time it takes for the refund to appear in your account may vary depending on your bank or payment provider.</p>\n" +
                "<b><p>5. Contact Us:</p></b>\n" +
                "<p>If you have any questions or need to initiate a refund request, please contact our customer support team.</p>\n" +
                "<b><p>6. Policy Changes:</p></b>\n" +
                "<p>We reserve the right to update or modify this refund policy at any time. Any changes will be effective immediately upon posting on our website.</p>\n" +
                "<p>By placing an order on our website, you acknowledge that you have read and agree to our refund policy.</p>\n" +
                "<p>Thank you for choosing us for your online orders. We appreciate your trust in our service.</p>\n" +
                "<p><br></p>"
        }}/>);
        return form;
    }
}
