import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantUserService extends Service {
    constructor() {
        super();
        this.baseURL = 'merchantuser/';
    }
    async merchantRolesFindAll() {
        return fetch(SERVER_URL + this.baseURL + "roles/all", {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findByMerchantAccountId(merchantAccountId) {
        return fetch(SERVER_URL + this.baseURL + "findByMerchantAccountId/" + merchantAccountId, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async changeUserPassword(id, password) {
        let data = {
            "id" : id,
            "password" : password
        };
        return fetch(SERVER_URL + this.baseURL +  "password/change" , {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getDrivers() {
        return fetch(SERVER_URL + this.baseURL + "role/driver", {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getTillUsers(merchantBranchId) {
        return fetch(SERVER_URL + this.baseURL + "tilluser/branchId/"+ merchantBranchId, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateTillUser(userId, merchantBranchId,tillUserId) {
        tillUserId =  GeneralUtils.isNullOrEmpty(tillUserId) ?  -1 : tillUserId;
        return fetch(SERVER_URL + this.baseURL + "tilluser/userId/" + userId + "/merchantBranchId/"+ merchantBranchId + "/tillUserId/" + tillUserId, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}