import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class AdminZXReportService extends Service {
    constructor() {
        super();
        this.baseURL = 'admin/zxreport/';
    }
    async calculateAgain(id) {
        return fetch(SERVER_URL + this.baseURL + "calculate/" + id, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getSummary(merchantBranchId,startDate,endDate) {
        return fetch(SERVER_URL + this.baseURL + "summary/merchantBranchId/" + merchantBranchId + "/" +startDate + "/" + endDate, {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}