import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {BASKET_STATUS, ERROR_TYPE, MONTH, ORDER_STATUS, ORDER_TYPE, PAGE_MODE} from "../../shared/utils/Constants";
import {InputNumber} from "primereact/inputnumber";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import OrderItemsColumn from "../../shared/component/dataTable/columns/OrderItemsColumn.json";
import OrderService from "../../shared/service/OrderService";

const defaultOrder = {
    id: null,
    orderNo: null,
    orderDate: null,
    basketStatus: null,
    syncStatus: null,
    orderStatus: null,
    acceptedDate: null,
    preparingDate: null,
    readyDate: null,
    deliveredDate: null,
    totalPrice: null,
    orderType: null,
    errorType: "INIT",
    errorMessage: null,
    basketDetailList: []
};
export default class ItemsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: defaultOrder, pageMode: null
        }
        this.orderService = new OrderService();
    }

    render() {
        return (<div class="p-col-12">
            <Fieldset legend={"Order"}>
                <Row>

                    <Col md="4">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="merchantBranch.tradingName">Order No</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="orderNo"
                                disabled={true}
                                value={this.state.order.orderNo}
                                onChange={this.__handleChange}/>
                        </AvGroup>
                    </Col>

                    <Col md="4">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="merchantBranch.tradingName">Order
                                    Type</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="orderType"
                                disabled={true}
                                value={this.state.order.orderType}
                                onChange={this.__handleChange}>
                                {ORDER_TYPE.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                            </AvField>
                        </AvGroup>
                    </Col>

                    <Col md="4">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="merchantBranch.tradingName">Order
                                    Status</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="orderStatus"
                                disabled={true}
                                value={this.state.order.orderStatusMember}
                                onChange={this.__handleChange}>
                                {ORDER_STATUS.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                            </AvField>
                        </AvGroup>
                    </Col>

                    <Col md="4">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="merchantBranch.tradingName">Total Price</Translate>
                            </Label>

                            <AvField
                                type="text"
                                className="form-control"
                                name="totalPriceString"
                                disabled={true}
                                value={this.state.order.totalPriceString}
                                onChange={this.__handleChange}/>

                        </AvGroup>
                    </Col>
                </Row>


                <Row>

                    <Col md="12">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="merchantBranch.tradingName">Delivery
                                    To</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="deliveryAddress"
                                disabled={true}
                                value={this.state.order.deliveryAddress}
                                onChange={this.__handleChange}/>
                        </AvGroup>
                    </Col>




                </Row>

                <Fieldset legend={"Items"}>
                    <DataTableWithButton data={this.state.order.basketDetailList}
                                         ref={(elem) => this.refDataTable = elem}
                                         selectionMode='single'
                                         paginator={false}
                                         fields={OrderItemsColumn.fields}> </DataTableWithButton>


                </Fieldset>




                <Row>
                    <Col>
                        <Button color="outline-dark" style={{marginTop: '20px'}} onClick={() => {
                            this.props.closeDisplayDialog();
                        }}>
                            <Translate contentKey="entity.action.cancel">Close</Translate>
                        </Button>

                    </Col>

                </Row>

            </Fieldset>
        </div>);
    }


    setData = (data) => {
        this.setState({order: data});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultOrder);
        this.setState({order: obj});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let order = this.state.order;
        order[e.target.name] = value;
        this.setState({order: order});
    }

}

ItemsDetail.propTypes = {
    order: PropTypes.object
};
