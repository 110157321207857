import React from 'react';
import MerchantAccountColumn from "../../shared/component/dataTable/columns/MerchantAccountColumn.json";
import {Button, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import {Fieldset} from "primereact/fieldset";
import MerchantAccountService from "../../shared/service/MerchantAccountService";

export default class MerchantAccountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            accountList:[]
        };
        this.merchantAccountService = new MerchantAccountService();
    }

    componentDidMount() {
        this.refreshDataTable();
    }

    render() {
        return (
            <Fieldset legend="Merchant Accounts">
                <Button color="outline-primary" style={{marginBottom:'5px'}} onClick={() => {
                    window.location.href = "#merchantAccountSave?"
                }}>
                    <Translate contentKey="entity.action.add">Add</Translate>
                </Button>

                <DataTableWithButton ref={(elem) => this.refDataTable = elem} fields={MerchantAccountColumn.fields}
                                     objectName={MerchantAccountColumn.name}
                                     data={this.state.accountList}
                                     view={this.view}
                                     rows={10}
                                     user={this.user}
                                     selectionMode="single"
                                     loading={this.state.loading}/>
            </Fieldset>
        );
    }
    user = (data) => {
        window.location.href = "#merchantUserSave?" + data.id;
    }

    view = (data) => {
        window.location.href = "#merchantAccountSave?" + data.id;
    }
    refresh = () => {
        this.refreshDataTable();
    }

    refreshDataTable = () => {
        this.merchantAccountService.findAll().then(response => this.setState({
            accountList: response
        }));
    }



}
