import React from 'react';
import {Button} from 'reactstrap';
import {AvForm} from 'availity-reactstrap-validation';
import CustomerColumn from "../../shared/component/dataTable/columns/CustomerColumn.json";
import {Fieldset} from "primereact/fieldset";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import DataTable from "../../shared/component/dataTable/DataTable";

export default class CustomerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.refDataTable.refresh();
    }

    render() {
        return (<div>
            <AvForm>
                {this.customerList()}
            </AvForm>
        </div>);
    }

    customerList() {
        var url = "customer/all";
        return (<div>
            <Fieldset legend="Customers">

                {GeneralUtils.checkPrivilege("Customer", "View") &&
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={CustomerColumn.fields}
                                  objectName={CustomerColumn.name}
                                  dataURL={url}
                                  selectionMode="single" onDataSelect={this.onDataSelect}
                                  loading={this.state.loading}/>
                }
            </Fieldset>


        </div>)
    };

    onDataSelect = (data) => {
        window.location.href = "#customerSave?" + data.id;
    }


}

