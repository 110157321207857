import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class BasketService extends Service {
    constructor() {
        super();
        this.baseURL = 'storemanagement/basket/';
    }

    async findBasketCreatedByMerchantBranchId(merchantBranchId, orderType) {
        return fetch(SERVER_URL + this.baseURL + "status/created/merchantBranchId/" + merchantBranchId + "/orderType/" + orderType + "/basketBssId/" +localStorage.getItem(localStorage.getItem("merchantBranchId")), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async getSetMenuItemDetails(id) {
        return fetch(SERVER_URL + this.baseURL + "setmenuitemdetails/id/" + id, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async addItemToBasket(data) {
        data.basketBssId = localStorage.getItem(localStorage.getItem("merchantBranchId"));
        return fetch(SERVER_URL + this.baseURL + "addItemToBasket", {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async addOneQuantityToItem(data) {
        data.basketBssId = localStorage.getItem(localStorage.getItem("merchantBranchId"));
        return fetch(SERVER_URL + this.baseURL + "addOneQuantityToItem", {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    async setBasketToLoginUser(merchantBranchId, basketBssId) {
        return fetch(SERVER_URL + this.baseURL + "setBasketToLoginUser/merchantBranchId/" + merchantBranchId +  "/basketBssId/" + basketBssId, {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async addServiceChargeToBasket(merchantBranchId, serviceCharge) {
        return fetch(SERVER_URL + this.baseURL + "addServiceCharge/merchantBranchId/" + merchantBranchId + "/serviceCharge/" + serviceCharge.replace("%", "")+ "/basketBssId/" +localStorage.getItem(localStorage.getItem("merchantBranchId")), {
            crossDomain: true,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async addSetMenuItemToBasket(data) {
        data.basketBssId = localStorage.getItem(localStorage.getItem("merchantBranchId"));
        return fetch(SERVER_URL + this.baseURL + "addSetMenuItemToBasket", {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async removeItemFromBasket(data) {
        data.basketBssId = localStorage.getItem(localStorage.getItem("merchantBranchId"));
        return fetch(SERVER_URL + this.baseURL + "removeItemFromBasket", {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getPreviousOrders(nLastDays) {
        return fetch(SERVER_URL + this.baseURL + "previousOrders/nlastday/" + nLastDays, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    async placeOrder(data) {
        return fetch(SERVER_URL + this.baseURL + "place", {
            crossDomain: true,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async cancelOrder(branchId) {
        return fetch(SERVER_URL + this.baseURL + "cancel/branchId/" + branchId + "/basketBssId/" +localStorage.getItem(localStorage.getItem("merchantBranchId")), {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async ageRestricted(branchId) {
        return fetch(SERVER_URL + this.baseURL + "ageRestricted/branchId/" + branchId+ "/basketBssId/" +localStorage.getItem(localStorage.getItem("merchantBranchId")), {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async checkAgeRestricted(branchId) {
        return fetch(SERVER_URL + this.baseURL + "checkAgeRestricted/branchId/" + branchId + "/basketBssId/" +localStorage.getItem(localStorage.getItem("merchantBranchId")), {
            crossDomain: true,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}