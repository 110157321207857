import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MarketPlaceService extends Service {
    constructor() {
        super();
        this.baseURL = 'marketplace/';
    }

    async findAll() {
        return fetch(SERVER_URL + this.baseURL, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async updateFavoriteBranch(branchId, favorite) {
        return fetch(SERVER_URL + this.baseURL + "branchid/" + branchId + "/favorite/"+ favorite, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findAllBySearchText( name) {
        return fetch(SERVER_URL + this.baseURL + "name/" + name, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}