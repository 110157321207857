import React from "react";
import NumberFormat from 'react-number-format';
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class NumberFormatInput extends React.Component {
    render() {
        return (
            <NumberFormat {...this.props}  value={GeneralUtils.__isValueEmpty(this.props.value) ? '' : this.props.value}/>
        );
    }
}