import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantAccountService extends Service {
    constructor() {
        super();
        this.baseURL = 'merchant/account/';
    }


    async updateContact(accountId, contact) {
        return fetch(SERVER_URL + this.baseURL + accountId + "/contact", {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(contact)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }




}