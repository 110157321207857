import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppInlineProfile} from './AppInlineProfile';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeicons/primeicons.css';
import '../layout/layout.css';
import HashRouterComp from '../HashRouterComp';
import {Col, Row} from "reactstrap";



class AppMaster extends Component {

    constructor() {
        super();
        this.state = {

            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu : null
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);


    }

    componentDidMount = () => {

    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;

    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }


    addMenu(response){
        let content = [{
            label: 'Menü Mod Seçimi', icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Açık Menü',
                    icon: 'pi pi-fw pi-bars',
                    command: () => this.setState({layoutMode: 'static'})
                },
                {
                    label: 'Gizli Menü',
                    icon: 'pi pi-fw pi-bars',
                    command: () => this.setState({layoutMode: 'overlay'})
                }
            ]
        },
            {
                label: 'Menu Renk Seçimi', icon: 'pi pi-fw pi-align-left',
                items: [
                    {label: 'Koyu', icon: 'pi pi-fw pi-bars', command: () => this.setState({layoutColorMode: 'dark'})},
                    {label: 'Açık', icon: 'pi pi-fw pi-bars', command: () => this.setState({layoutColorMode: 'light'})}
                ]
            }];
        if(response){
            return response.concat(content);
        }else{
            return content;
        }
    }



    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {
        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });
        let sidebarClassName = classNames("layout-sidebar", {'layout-sidebar-dark': this.state.layoutColorMode === 'dark'});

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar onToggleMenu={this.onToggleMenu} logout={this.props.logout}/>

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                        <div className="layout-sidebar-scroll-content">
                            <div className="layout-logo">
                                <a href='#/'>
                                    <img alt="Logo" src="assets/layout/images/dispatchio.png"style={{marginTop:'50px',height:'40px', width:'160px'}}/>
                                </a>
                            </div>
                            <AppInlineProfile logout={this.props.logout}/>
                            <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick}/>
                        </div>
                </div>
                <div className="layout-main">
                    <HashRouterComp/>
                </div>

                <AppFooter></AppFooter>
            </div>
        );
    }
}

AppMaster.propTypes = {
    logout: PropTypes.func,
};


export default AppMaster;
