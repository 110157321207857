import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class CheckPageService extends Service {
    constructor() {
        super();
        this.baseURL = 'checkpage/';
    }

    async checkPage() {
        return fetch(SERVER_URL + this.baseURL , {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => response.json()).catch(error => {
            //GeneralUtils.notification(error);
        });
    }


}