import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {TILL_IMAGE, WEB_MENU_SMALL_IMAGE} from "../../shared/utils/Constants";
import ProductTagColumn from "../../shared/component/dataTable/columns/ProductTagColumn.json";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import {FileUpload} from "primereact/fileupload";
import {Button as ButtonSearch} from "primereact/components/button/Button";
import TagService from "../../shared/service/TagService";
import {ColorPicker} from 'primereact/colorpicker';
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";

const defaultProductTag = {
    id: '',
    name: '',
    status: 'ACTIVE',
    fileType: '',
    fileName: 'Choose',
    shortName: '',
    tagColor: null
};
export default class ProductTagSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productTag: defaultProductTag,
            productTagTemp: defaultProductTag,
            productTagDetailsSaveButtonRender: true,
            defaultStatus: 'ACTIVE',
            taxList: [],
            productTagList: []
        }
        this.productTagService = new TagService();
    }

    componentDidMount() {
        let obj1 = Object.assign({}, defaultProductTag);
        this.setState({
            productTag: obj1, productTagDetailsSaveButtonRender: false, productTagTemp: Object.assign({}, obj1)
        });

        this.refresh();
    }

    refresh() {
        this.productTagService.findAll().then(response => this.setState({
            productTagList: response
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.productTagDetails()}
            </AvForm>
        </div>);
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.productTag.id)) {
            this.productTagService.delete(this.state.productTag.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let obj1 = Object.assign({}, defaultProductTag);
                        this.setState({
                            productTag: obj1, productTagDetailsSaveButtonRender: false,
                            productTagTemp: Object.assign({}, obj1)
                        });
                        this.refresh();
                    }
                });
        }

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "Are you sure to delete?"
        });
    }

    productTagDetails() {

        return (<div class="p-col-12">
            <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                          show={this.state.displayShowStatusConfirmDialog}
                          message={this.state.noticeResponse}/>
            <Fieldset legend="Product Tag Details">
                <Row>
                    <Col md="12">
                        <Row>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="productTag.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.productTagDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.productTag.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="productTag.tradingName">Short Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="shortName"
                                        disabled={!this.state.productTagDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.productTag.shortName}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="productTag.tradingName">Tag Color&nbsp;</Translate>
                                    </Label>
                                    <ColorPicker value={this.state.productTag.tagColor}
                                                 format="hex"
                                                 name="tagColor"
                                                 onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>


                            <Col md="4">
                                <Fieldset legend="Product Tag Image">
                                    <Row>
                                        <Col md="1">
                                        </Col>
                                        <FileUpload name="demo"
                                                    accept="image/*"
                                                    maxFileSize={50000}
                                                    onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                    invalidFileSizeMessageDetail="Maximum upload size is 50K."
                                                    chooseLabel={this.state.productTag.fileName == null ? "Choose" : this.state.productTag.fileName}
                                                    disabled={!this.state.productTagDetailsSaveButtonRender}
                                                    ref={elem => this.refUploadInputImage = elem}
                                                    mode="basic"></FileUpload>

                                        <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}} onClick={() => {
                                            try {
                                                GeneralUtils.previewDocument(this.refUploadInputImage);
                                            } catch (e) {
                                                this.downloadDocument();
                                            }
                                        }}>
                                        </ButtonSearch>

                                        <ButtonSearch icon="pi pi-minus"
                                                      disabled={!this.state.productTagDetailsSaveButtonRender}
                                                      style={{marginLeft: '5px'}} onClick={(e) => {
                                            this.removeDocument(TILL_IMAGE)
                                        }}>
                                        </ButtonSearch>
                                    </Row>
                                </Fieldset>
                            </Col>

                        </Row>

                    </Col>
                </Row>

                {GeneralUtils.checkPrivilege("Product Tag", "Create") && this.state.productTagDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.productTag.id) &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({productTagDetailsSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("Product Tag", "Create") && this.state.productTagDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveProductTag();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("Product Tag", "Delete") && this.state.productTagDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.productTag.id) &&

                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.showChangeStatusConfirmDialog(true)
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("Product Tag", "Create") && this.state.productTagDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.productTagTemp;
                        this.setState({
                            productTag: Object.assign({}, obj1), productTagDetailsSaveButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>

            <Fieldset legend="Product Tags">
                {GeneralUtils.checkPrivilege("Product Tag", "Add") &&
                    <Button color="outline-primary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => {
                        this.addNew();
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("Product Tag", "View") &&
                    <DataTableWithButton ref={(elem) => this.refDataTable = elem} fields={ProductTagColumn.fields}
                                         objectName={ProductTagColumn.name}
                                         data={this.state.productTagList}
                                         rows={10}
                                         selectionMode="single" onDataSelect={this.onDataSelect}
                                         loading={this.state.loading}/>
                }
            </Fieldset>


        </div>)
    };

    onDataSelect = (data) => {
        this.productTagService.checkHealth().then(response => {
            this.productTagService.findById(data.id).then(response => {
                if (data.id < 0) {
                    this.setState({
                        productTag: response,
                        productTagDetailsSaveButtonRender: null,
                        productTagTemp: Object.assign({}, response)
                    });
                } else {
                    this.setState({
                        productTag: response,
                        productTagDetailsSaveButtonRender: false,
                        productTagTemp: Object.assign({}, response)
                    });
                }


            });
        });


    }

    removeDocument(type) {
        if (!GeneralUtils.isNullOrEmpty(this.state.productTag.id)) {
            this.productTagService.removeDocument(this.state.productTag.id, type).then(response => {
                this.setState({
                    productTagDetailsSaveButtonRender: false,
                    productTag: response,
                })
            });
        } else {
            this.refUploadInputImage.clear();

        }

    }

    downloadDocument = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.productTag.id)) {
            this.productTagService.downloadDocument(this.state.productTag.id, WEB_MENU_SMALL_IMAGE).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.convertToDownloadableFile(response);
                }

            });
        }

    }
    saveProductTag = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.productTag.name) || GeneralUtils.isNullOrEmpty(this.state.productTag.shortName)) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.productTag.id)) {
                this.productTagService.update(this.state.productTag)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                productTagDetailsSaveButtonRender: false,
                                productTag: response,
                                productTagTemp: Object.assign({}, response)
                            });
                            this.uploadDocument(response.id);
                            this.refresh();
                        }
                    });
            } else {
                this.productTagService.create(this.state.productTag)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                productTagDetailsSaveButtonRender: false,
                                productTag: response,
                                productTagTemp: Object.assign({}, response)
                            });
                            this.uploadDocument(response.id);
                            this.refresh();
                        }
                    });
            }
        }

    }


    uploadDocument(id) {
        let image = this.refUploadInputImage.files;
        if (!(GeneralUtils.isNullOrEmpty(image) || GeneralUtils.isNullOrEmpty(image.length))) {
            let formData = new FormData();
            if (!GeneralUtils.isNullOrEmpty(image) && !GeneralUtils.isNullOrEmpty(image.length)) {
                formData.append("file", image[0]);
                this.productTagService.uploadDocument(id, formData, WEB_MENU_SMALL_IMAGE).then(response => {
                    this.setState({
                        productTagDetailsSaveButtonRender: false
                    })
                });
            }
        }
    }

    addNew() {
        let obj1 = Object.assign({}, defaultProductTag);
        this.setState({
            productTag: obj1, productTagDetailsSaveButtonRender: true, productTagTemp: Object.assign({}, obj1)
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let productTag = this.state.productTag;
        productTag[e.target.name] = value;
        this.setState({productTag: productTag});
    }

}

