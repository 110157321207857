import React from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {AvGroup, AvForm} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import ComplaintSuggestionService from "../../shared/service/ComplaintSuggestionService";
import MarketPlaceService from "../../shared/service/MarketPlaceService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import {
    FEEDBACK_TYPE,
} from "../../shared/utils/Constants";

const defaultFeedback = {
    merchantBranchId: null,
    feedbackType: 'Technical',
    description: '',
};

export default class ComplaintSuggestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: Object.assign({}, defaultFeedback),
            branches: [],
        }
        this.complaintSuggestionService = new ComplaintSuggestionService();
        this.marketPlaceService = new MarketPlaceService();

    }

    componentDidMount() {
        this.marketPlaceService.findAll().then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({
                    branches: response,
                    loading: false
                });

            } else {
                this.setState({
                    branches: [],
                    loading: false
                });
            }
        });

        this.setMerchantBranchIdToState();

    }

    setMerchantBranchIdToState() {
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId"))) {
            let feedback = this.state.feedback;
            feedback["merchantBranchId"] = localStorage.getItem("merchantBranchId");
            this.setState({feedback: feedback});
        }
    }


    render() {
        return (
            <AvForm>

                <Row>
                    <Col md={3}>
                    </Col>

                    <Col md={4}>


                        <Fieldset legend={"Complaint / Suggestion"}>
                            <Row>


                                <Col md="12">

                                    <AvGroup>
                                        <Label for="emailAddress">
                                            <Translate contentKey="merchantAccount.emailAddress">Type</Translate>
                                        </Label>

                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="feedbackType"
                                            disabled={false}
                                            value={this.state.feedback.feedbackType}
                                            onChange={this.__handleChange}>
                                            {FEEDBACK_TYPE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>

                                    </AvGroup>

                                </Col>

                                <Col md={"12"}>

                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Description
                                            </Translate>
                                        </Label>
                                        <AvField
                                            type="textarea"
                                            className="form-control textAreaPosmarket"
                                            placeholder="Leave a complaint / suggestion for the store"
                                            name="description"
                                            disabled={false}
                                            rows={6} cols={50}
                                            maxLength={200}
                                            value={this.state.feedback.description}
                                            onChange={this.__handleChange}>
                                        </AvField>
                                    </AvGroup>


                                </Col>


                            </Row>
                            <Col md={"12"}>
                                <Button color="outline-primary"
                                        style={{margin: 'auto', width: "100%"}} onClick={() => {
                                    this.sendFeedback();
                                }}>
                                    <Translate contentKey="entity.action.add">Send</Translate>
                                </Button>

                            </Col>

                        </Fieldset>


                    </Col>

                    <Col md={3}>
                    </Col>
                </Row>

            </AvForm>
        );
    }

    sendFeedback = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.feedback.description)) {
            return toast.warn("Please fill the required fields!");
        } else {
            this.complaintSuggestionService.create(this.state.feedback)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            feedback: Object.assign({}, defaultFeedback)
                        });
                        this.setMerchantBranchIdToState();

                    }
                });
        }

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let feedback = this.state.feedback;
        feedback[e.target.name] = value;
        this.setState({feedback: feedback});

    }

}


