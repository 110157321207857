import React from 'react';
import moment from "moment";
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import 'moment/locale/tr';
import 'react-datepicker/dist/react-datepicker.css';

export default class DateInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            value: this.props.value,
            placeholder: this.props.placeholder,
            format: this.props.format,
            locale: this.props.locale,
            disabled: this.props.disabled
        };
    }

    componentWillMount() {
        let value = this.props.value;
        if (value != undefined && value != "")
            this.setState({ value: moment(this.props.value) });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value != undefined && nextProps.value != "")
            this.setState({ value: moment(nextProps.value), disabled: nextProps.disabled });
        else
            this.setState({ value: nextProps.value, disabled: nextProps.disabled });
    }

    render() {
        let format = this.state.format;


        if (!format)
            format = "DD/MM/YYYY HH:mm";

        let disabled = this.state.disabled;
        let placeholder = this.state.placeholder;

        if(disabled){
            placeholder = "";
        }
        else if (!placeholder && placeholder !== "")
            placeholder = format;

        let locale = this.state.locale;
        if (!locale)
            locale = "tr"

        let dateComp = (
            <DatePicker 
                className="form-control is-touched is-pristine av-valid form-control"
                selected={this.state.value}
                onChange={(e) => this.onChange(e)}
                onSelect={(e) => this.onChange(e)}
                timeFormat="HH:mm:ss"
                dateFormat={format}
                timeIntervals={15}
                timeCaption="Saat"
                placeholderText={placeholder}
                locale={locale}
                disabled={disabled}
            />
        );
        return (dateComp);
    }

    onChange(date) {
        let dateObj;
        if (date !== undefined && date !== null && date !== "") {
            dateObj = new Date(date);
            dateObj = dateObj.getTime();
        } else {
            dateObj = "";
        }
        this.setState({ value: date });
        let e = {
            target: { value: dateObj, name: this.state.name }
        }
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
}

DateInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
    placeholder: PropTypes.string,
    format: PropTypes.string,
    locale: PropTypes.string
};