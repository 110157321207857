import React from "react";
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default class ModalConfirm extends React.Component {

    static propTypes = {
        onOkClick: PropTypes.func,
        onCancelClick: PropTypes.func,
        header: PropTypes.string,
        message: PropTypes.string,
        show: PropTypes.bool,
        ok: PropTypes.string,
        cancel: PropTypes.string,
    };


    static defaultProps = {
        ok: "OK",
        cancel: "Cancel"
    };

    render() {
        return (
            <Modal isOpen={this.props.show}>
                <ModalHeader toggle={this.props.onCancelClick}>{this.props.header}</ModalHeader>
                <ModalBody>
                    {this.props.message}
                </ModalBody>
                <ModalFooter>
                    {this.props.onCancelClick && <Button onClick={this.props.onCancelClick}>{this.props.cancel}</Button>}
                    <Button color="danger" onClick={()=> {
                        this.props.onOkClick();
                        if(this.props.onCancelClick) {
                            this.props.onCancelClick();
                        }
                    }}>{this.props.ok}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
