import React from 'react';
import {RadioButton} from 'primereact/radiobutton';
import {Checkbox} from 'primereact/checkbox';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {Fieldset} from "primereact/fieldset";
import Translate from "../../shared/language/Translate";
import {Button as ButtonImage} from "primereact/button";
import {Button, Col, Row} from 'reactstrap';
import WebMenuService from "../../shared/service/WebMenuService";
import {PAGE_MODE, SERVER_URL_PRODUCT_DATAWAREHOUSE} from "../../shared/utils/Constants";
import {Chip} from "@material-ui/core";
import InputText from "../../shared/component/form/InputText";

const defaultItem = {
    id: null,
    longname: null,
    price: null,
    totalPrice: null,
    itemPrice: null,
    originalPrice: null,
    quantity: 1,
    description: null,
    code: null,
    pageMode: PAGE_MODE.CREATE
};

export default class ItemDetailsCheckOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: defaultItem,
            showTop: true,
            viewMode: false
        }
        this.webMenuService = new WebMenuService();
    }

    componentDidMount() {
        this.setState({showTop: true});
    }

    setData = (data) => {
        this.setState({showTop: true});
        data.itemPrice = data.originalPrice;
        data.pageMode = PAGE_MODE.UPDATE;
        data.totalPrice = data.originalPrice * data.quantity;
        data.originalPrice = data.price;

        this.setState({item: data});
        this.calculatePrice();
    }

    clearData = (data, viewMode) => {
        this.setState({showTop: true, viewMode: viewMode});
        data.pageMode = PAGE_MODE.CREATE;
        data.description = data.longname;
        if (!GeneralUtils.isNullOrEmpty(data.grpContent)) {
            data.grpContent.products.forEach(product => {
                product.selectedValue = product.id;
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpContentX)) {
            data.grpContentX.products.forEach(product => {
                product.selectedValue = null;
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpMain)) {
            data.grpMain.products.forEach(product => {
                if (data.productId == product.id) {
                    product.selectedValue = data.productId;
                } else {
                    product.selectedValue = null;
                }
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption1)) {
            data.grpOption1.products.forEach(product => {
                product.selectedValue = null;
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption2)) {
            data.grpOption2.products.forEach(product => {
                product.selectedValue = null;
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption3)) {
            data.grpOption3.products.forEach(product => {
                product.selectedValue = null;
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption4)) {
            data.grpOption4.products.forEach(product => {
                product.selectedValue = null;
            });
        }
        data.totalPrice = data.price;
        data.originalPrice = data.price;
        data.itemPrice = data.price;
        data.code = data.id;
        data.quantity = 1;
        data.extraNotes = '';

        this.webMenuService.checkHealth()
            .then((response) => {
                this.setState({item: data});
                this.calculatePrice();
            });


    }

    sumOfOptions = (grpOption, data) => {
        var result = 0;
        if (grpOption.selectionType == "MULTIPLE") {
            if (grpOption.pricingType == "SET_PRICE") {
                let zeroPriceLimit = grpOption.zeroPriceLimit;
                if (zeroPriceLimit == null) {
                    zeroPriceLimit = 0;
                }
                let i = 1;
                var priceOfEach = grpOption.price;
                grpOption.products.forEach(product => {
                    if (product.selectedValue != null) {
                        data.description = data.description + " # " + product.longname;
                        data.code = data.code + "E" + product.id + "";
                        if (i > zeroPriceLimit) {
                            result = result + priceOfEach;
                        }
                        i++;
                    }
                });
            }
            if (grpOption.pricingType == "PRODUCT_PRICE") {
                let zeroPriceLimit = grpOption.zeroPriceLimit;
                if (zeroPriceLimit == null) {
                    zeroPriceLimit = 0;
                }
                let i = 1;
                grpOption.products.forEach(product => {
                    if (product.selectedValue != null) {
                        data.description = data.description + " # " + product.longname;
                        data.code = data.code + "E" + product.id + "";
                        if (i > zeroPriceLimit) {
                            result = result + product.price;
                        }
                        i++;
                    }

                });
            }
        }
        if (grpOption.selectionType == "SINGLE") {
            if (grpOption.pricingType == "PRODUCT_PRICE") {
                grpOption.products.forEach(product => {
                    if (product.selectedValue != null) {
                        data.description = data.description + " # " + product.longname;
                        data.code = data.code + "E" + product.id + "";
                        result = result + product.price;
                    }
                });
            }

            if (grpOption.pricingType == "SET_PRICE") {
                var priceOfEach = grpOption.price;
                grpOption.products.forEach(product => {
                    if (product.selectedValue != null) {
                        data.description = data.description + " # " + product.longname;
                        data.code = data.code + "E" + product.id + "";
                        result = result + priceOfEach;
                    }
                });

            }


        }
        return result;
    }

    calculatePrice = () => {
        var totalPriceOfOneItem = 0;
        let data = this.state.item;
        data.description = data.name;
        data.code = "";


        if (!GeneralUtils.isNullOrEmpty(data.grpContent)) {
            data.grpContent.products.forEach(product => {
                if (product.selectedValue == null) {
                    data.description = data.description + " # No";
                    data.code = data.code + "N" + product.id + "";
                    data.description = data.description + " " + product.name;
                } else {
                    data.code = data.code + "Y" + product.id + "";
                }
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpContentX)) {
            data.grpContentX.products.forEach(product => {
                if (product.selectedValue != null) {
                    data.description = data.description + " # With";
                    data.code = data.code + "W" + product.id + "";
                    data.description = data.description + " " + product.name;
                    data.selectedValue = product.id;
                }
            });
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpMain)) {
            let sumOf = this.sumOfOptions(data.grpMain, data);
            totalPriceOfOneItem = totalPriceOfOneItem + sumOf;
        } else {
            if (GeneralUtils.isNullOrEmpty(data.itemPrice)) {
                totalPriceOfOneItem = totalPriceOfOneItem + data.originalPrice;
            } else {
                totalPriceOfOneItem = totalPriceOfOneItem + data.itemPrice;
            }

        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption1)) {
            let sumOf = this.sumOfOptions(data.grpOption1, data);
            totalPriceOfOneItem = totalPriceOfOneItem + sumOf;
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption2)) {
            let sumOf = this.sumOfOptions(data.grpOption2, data);
            totalPriceOfOneItem = totalPriceOfOneItem + sumOf;
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption3)) {
            let sumOf = this.sumOfOptions(data.grpOption3, data);
            totalPriceOfOneItem = totalPriceOfOneItem + sumOf;
        }
        if (!GeneralUtils.isNullOrEmpty(data.grpOption4)) {
            let sumOf = this.sumOfOptions(data.grpOption4, data);
            totalPriceOfOneItem = totalPriceOfOneItem + sumOf;
        }
        data.price = totalPriceOfOneItem;

        data.totalPrice = (totalPriceOfOneItem * data.quantity);
        this.setState({item: data});
    }

    renderPriceDetails() {
        let form = [];

        if (!GeneralUtils.isNullOrEmpty(this.state.item.price) && (GeneralUtils.isSetMenu(this.state.item))) {
            form.push(<p></p>);
            form.push(<label htmlFor="productItem">
                Any specific preferences? Let the restaurant know.</label>);
            form.push(
                <InputText value={this.state.item.extraNotes}
                           style={{borderRadius: "10px", width: "-webkit-fill-available"}}
                           name="extraNotes"
                           placeholder="Notes"
                           disabled={this.state.viewMode}
                           rows={2} cols={60}
                           maxLength={50}
                           onChange={this.__handleChange}/>
            );

        }
        if (!GeneralUtils.isNullOrEmpty(this.state.item.price)) {

            form.push(<p></p>);
            form.push(<ButtonImage icon={"pi pi-minus"} disabled={this.state.item.quantity <= 1}
                                   style={{
                                       height: '30px',
                                       border: '0px solid #1982D9',
                                       boxShadow: '0px 0px 0px 0px #ccc'
                                   }}
                                   onClick={() => {
                                       let data = this.state.item;
                                       if (data.quantity > 1) {
                                           if (data.weighable == true && data.unit == "kg") {
                                               data.quantity = parseFloat(data.quantity) - parseFloat(0.1);
                                               data.quantity = data.quantity.toFixed(2)
                                           } else {
                                               data.quantity = data.quantity - 1;
                                           }
                                       }
                                       this.setState({item: data});
                                       this.calculatePrice();
                                   }}>

            </ButtonImage>);
            if (this.state.item.weighable == true && this.state.item.unit == "kg") {
                form.push(<label
                    htmlFor="productItem">&nbsp;&nbsp;&nbsp;{this.state.item.quantity}&nbsp;{this.state.item.unit}&nbsp;&nbsp;&nbsp;</label>);
            } else {
                form.push(<label
                    htmlFor="productItem">&nbsp;&nbsp;&nbsp;{this.state.item.quantity}&nbsp;&nbsp;&nbsp;</label>);
            }


            form.push(<ButtonImage icon={"pi pi-plus"} style={{
                height: '30px',
                border: '0px solid #1982D9',
                boxShadow: '0px 0px 0px 0px #ccc'
            }}
                                   onClick={() => {
                                       let data = this.state.item;
                                       if (data.weighable == true && data.unit == "kg") {
                                           data.quantity = parseFloat(data.quantity) + parseFloat(0.1);
                                           data.quantity = data.quantity.toFixed(2)
                                       } else {
                                           data.quantity = data.quantity + 1;
                                       }

                                       this.setState({item: data});
                                       this.calculatePrice();
                                   }}>

            </ButtonImage>);
            form.push(<label htmlFor="productItem">&nbsp;&nbsp;&nbsp;Unit Price:
                £{this.state.item.price.toFixed(2)}</label>);
            form.push(<p></p>);
            form.push(<label htmlFor="productItem">Total Price: £{this.state.item.totalPrice.toFixed(2)}</label>);

            form.push(<p></p>);
        }
        form.push(this.renderButtons());
        form.push(<p></p>);

        return form;
    }

    render() {
        let form = [];
        if (this.state.showTop == false) {

        } else {
            form.push(<Fieldset legend={this.state.item.longname}>
                <div className="orderlist-demo">
                    {this.renderItemDetails()}
                    {this.renderProductTags()}
                    {this.renderPriceDetails()}
                </div>
            </Fieldset>);
        }


        return form;
    }

    renderButtons() {
        return (<div><Button color="outline-primary" style={{marginRight: '5px', height: '30px', minWidth: "48%"}}
                             onClick={() => {
                                 this.props.closeDisplayDialog();
                                 this.setState({showTop: false});
                             }}>
            <Translate contentKey="entity.action.add">Close</Translate>
        </Button>
            {this.state.viewMode == false &&
                <Button color="outline-primary" style={{marginRight: '5px', height: '30px', minWidth: "48%"}}
                        onClick={() => {
                            this.calculatePrice();

                            let data = this.state.item;
                            if (data.pageMode == PAGE_MODE.CREATE) {
                                data.id = null;
                                this.setState({item: data});
                            }
                            if (GeneralUtils.isSetMenu(this.state.item)) {
                                this.props.addSetMenuItem(this.state.item);
                            } else {
                                this.props.addProduct(this.state.item);
                            }

                            this.setState({showTop: false});
                        }}>
                    <Translate contentKey="entity.action.add">Add to order</Translate>
                </Button>
            }
        </div>);
    }

    renderProductTags() {
        if (!GeneralUtils.isNullOrEmpty(this.state.item.tag4Name) || !GeneralUtils.isNullOrEmpty(this.state.item.tag5Name) || !GeneralUtils.isNullOrEmpty(this.state.item.tag1Name) || !GeneralUtils.isNullOrEmpty(this.state.item.tag2Name) || !GeneralUtils.isNullOrEmpty(this.state.item.tag3Name)) {

            return (<Fieldset legend={"Product Tags"}>
                <div className="orderlist-demo">

                    {!GeneralUtils.isNullOrEmpty(this.state.item.tag1Name) &&
                        <Chip label={this.state.item.tag1Name}
                              style={{backgroundColor: '#1982D9', color: 'white'}}/>
                    }
                    {!GeneralUtils.isNullOrEmpty(this.state.item.tag2Name) &&
                        <Chip label={this.state.item.tag2Name}
                              style={{backgroundColor: '#1982D9', color: 'white'}}/>
                    }
                    {!GeneralUtils.isNullOrEmpty(this.state.item.tag3Name) &&
                        <Chip label={this.state.item.tag3Name}
                              style={{backgroundColor: '#1982D9', color: 'white'}}/>
                    }

                    {!GeneralUtils.isNullOrEmpty(this.state.item.tag4Name) &&
                        <Chip label={this.state.item.tag4Name}
                              style={{backgroundColor: '#1982D9', color: 'white'}}/>
                    }

                    {!GeneralUtils.isNullOrEmpty(this.state.item.tag5Name) &&
                        <Chip label={this.state.item.tag5Name}
                              style={{backgroundColor: '#1982D9', color: 'white'}}/>
                    }


                </div>
            </Fieldset>);
        } else {
            return "";
        }

    }


    renderProductTextDetails() {
        let form = [];
        if (!GeneralUtils.isNullOrEmpty(this.state.item.subTitle)) {
            form.push(<div align={"center"}>
                <label htmlFor="productItem">{this.state.item.subTitle}</label>
            </div>)
            form.push(<Row></Row>);
        }

        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpContent)) {

            form.push(<Fieldset legend={"Served With"}>
                <div className="orderlist-demo">
                    {this.renderContentAndExtra(this.state.item.grpContent)}
                </div>
            </Fieldset>);
        }

        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpContentX)) {
            form.push(<Fieldset legend={"Extra(s)"}>
                <div className="orderlist-demo">
                    {this.renderContentAndExtra(this.state.item.grpContentX)}
                </div>
            </Fieldset>);
            form.push()
        }

        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpMain)) {
            let grpOption = this.state.item.grpMain
            grpOption.selectionType = "SINGLE";
            form.push(<Fieldset legend={"Main Product"}>
                <div className="orderlist-demo">
                    {this.renderOption(this.state.item.grpMain, this.state.item.grpMainText, "")}
                </div>
            </Fieldset>);

        }

        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpOption1)) {
            form.push(<Fieldset legend={this.state.item.grpOption1Label}>
                <div className="orderlist-demo">
                    {this.renderOption(this.state.item.grpOption1, this.state.item.grpOption1Text, this.state.item.grpOption1Label)}
                </div>
            </Fieldset>);

        }
        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpOption2)) {
            form.push(<Fieldset legend={this.state.item.grpOption2Label}>
                <div className="orderlist-demo">
                    {this.renderOption(this.state.item.grpOption2, this.state.item.grpOption2Text, this.state.item.grpOption2Label)}
                </div>
            </Fieldset>);
        }
        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpOption3)) {
            form.push(<Fieldset legend={this.state.item.grpOption3Label}>
                <div className="orderlist-demo">
                    {this.renderOption(this.state.item.grpOption3, this.state.item.grpOption3Text, this.state.item.grpOption3Label)}
                </div>
            </Fieldset>);
        }
        if (!GeneralUtils.isNullOrEmpty(this.state.item.grpOption4)) {
            form.push(<Fieldset legend={this.state.item.grpOption4Label}>
                <div className="orderlist-demo">
                    {this.renderOption(this.state.item.grpOption4, this.state.item.grpOption4Text, this.state.item.grpOption4Label)}
                </div>
            </Fieldset>);
        }
        return form;
    }

    renderItemDetails() {
        let form = [];


        let styleSetMenu = {
            maxWidth: '15vw',
            maxHeight: '15vw'
        };
        let styleProduct = {
            maxWidth: '5vw',
            maxHeight: '5vw'
        };

        if (GeneralUtils.isMobile()) {
            styleSetMenu = {
                maxWidth: '60vw',
                maxHeight: '60vw'
            };
            styleProduct = {
                maxWidth: '20vw',
                maxHeight: '20vw'
            };
        }

        if (GeneralUtils.isSetMenu(this.state.item)) {
            if (!GeneralUtils.isNullOrEmpty(this.state.item.content) && !GeneralUtils.isNullOrEmpty(this.state.item.fileType) && !GeneralUtils.isNullOrEmpty(this.state.item.fileName)) {

                form.push(<div className="image-container" align={"center"}>
                    <img
                        src={GeneralUtils.convertForImageSource(this.state.item.content, this.state.item.fileType, this.state.item.fileName)}
                        style={styleSetMenu}
                        onError={(e) => e.target.src = 'assets/layout/images/white.png'}
                        alt={this.state.item.longname}/>
                </div>)
            }
            else{
                if(this.state.item.productBarcode!=undefined && this.state.item.productName != undefined){
                    form.push(<div className="image-container" align={"center"}>
                        <img
                            src={SERVER_URL_PRODUCT_DATAWAREHOUSE + "product/image/barcode/"+this.state.item.productBarcode+"/name/"+ GeneralUtils.removeSlashes(this.state.item.productName)}
                            style={styleSetMenu}
                            onError={(e) => e.target.src = 'assets/layout/images/white.png'}
                            alt={this.state.item.longname}/>
                    </div>)
                }


            }


        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.item.content) && !GeneralUtils.isNullOrEmpty(this.state.item.fileType) && !GeneralUtils.isNullOrEmpty(this.state.item.fileName)) {
                form.push(<div className="image-container" align={"center"}>
                    <img
                        src={GeneralUtils.convertForImageSource(this.state.item.content, this.state.item.fileType, this.state.item.fileName)}
                        style={styleProduct}
                        onError={(e) => e.target.src = 'assets/layout/images/white.png'}
                        alt={this.state.item.longname}/>
                </div>)
            }
            else{
                if(this.state.item.productBarcode!=undefined && this.state.item.productName != undefined){
                    form.push(<div className="image-container" align={"center"}>
                        <img
                            src={SERVER_URL_PRODUCT_DATAWAREHOUSE + "product/image/barcode/"+this.state.item.productBarcode+"/name/"+ GeneralUtils.removeSlashes(this.state.item.productName)}
                            style={styleProduct}
                            onError={(e) => e.target.src = 'assets/layout/images/white.png'}
                            alt={this.state.item.longname}/>
                    </div>)
                }

            }

        }
        form.push(this.renderProductTextDetails());
        return form;
    }

    setFalseToUnselected = (itemList, data) => {
        for (var i = 0; i < itemList.length; i++) {
            if (itemList[i].id != data.id) {
                itemList[i].selectedValue = null;

            }
        }

    }

    renderContentAndExtra(groupList) {
        let data = this.state.item;
        let form = [];
        groupList.products.forEach(product => {
            form.push(
                <Checkbox name={product.name} value={product.id}
                          onChange={(e) => {
                              product.selectedValue = e.checked ? e.value : null;
                              this.setState({
                                  item: data
                              });
                              this.calculatePrice();

                          }}
                          checked={product.selectedValue == product.id}/>
            );

            form.push(<label htmlFor="productItem">&nbsp;&nbsp;&nbsp;</label>);
            form.push(<label htmlFor="productItem">
                <h8 style={{overflow: 'hidden'}}>{product.name}</h8>
            </label>);
            form.push(<p></p>);

        });
        return form;

    }

    renderOption(grpOption, grpOptionText, grpOptionLabel) {
        let form = [];
        let optionDescription = grpOptionText.replace(grpOptionLabel, "");
        try {
            if (optionDescription.indexOf("(") < 0 && optionDescription.indexOf(")") < 0) {
                optionDescription = "";
            } else {
                optionDescription = optionDescription.substring(optionDescription.indexOf("(") + 1, optionDescription.indexOf(")"));
            }
        } catch (e) {
            optionDescription = grpOptionText.replace(grpOptionLabel, "");
        }
        if (!GeneralUtils.isNullOrEmpty(optionDescription)) {
            form.push(<label htmlFor="productItem">{optionDescription}</label>);
            form.push(<p></p>);
        }

        grpOption.products.forEach(product => {

            if (grpOption.selectionType == "MULTIPLE") {
                form.push(
                    <Checkbox inputId={product.id} name={product.longname} value={product.id}
                              onChange={(e) => {
                                  product.selectedValue = e.checked ? e.value : null;
                                  let item = this.state.item;
                                  this.setState({
                                      item: item
                                  });
                                  this.calculatePrice();

                              }}
                              checked={product.selectedValue == product.id}/>
                );
                form.push(<label htmlFor="productItem">&nbsp;&nbsp;&nbsp;</label>);
                form.push(<label htmlFor="productItem">{product.longname}</label>);

                if (grpOption.pricingType == "PRODUCT_PRICE" && !GeneralUtils.isNullOrEmpty(product.price)) {
                    form.push(" (£");
                    form.push(<label htmlFor="productItem">{product.price.toFixed(2)}</label>);
                    form.push(")");
                } else if (grpOption.pricingType == "SET_PRICE" && !GeneralUtils.isNullOrEmpty(grpOption.price)) {
                    form.push(" (£");
                    form.push(<label htmlFor="productItem">{grpOption.price.toFixed(2)}</label>);
                    form.push(")");

                }
                form.push(<p></p>);
            } else {
                form.push(
                    <RadioButton inputId={product.id} name={product.longname} value={product.id}
                                 onChange={(e) => {
                                     product.selectedValue = e.value;
                                     let item = this.state.item;
                                     this.setState({
                                         item: item
                                     });
                                     this.setFalseToUnselected(grpOption.products, product);
                                     this.calculatePrice();
                                 }}
                                 checked={product.selectedValue == product.id}/>
                );
                form.push(<label htmlFor="productItem">&nbsp;&nbsp;&nbsp;</label>);
                form.push(<label htmlFor="productItem">{product.longname}</label>);
                if (grpOption.pricingType == "PRODUCT_PRICE" && !GeneralUtils.isNullOrEmpty(product.price)) {
                    form.push(" (£");
                    form.push(<label htmlFor="productItem">{product.price.toFixed(2)}</label>);
                    form.push(")");

                } else if (grpOption.pricingType == "SET_PRICE" && !GeneralUtils.isNullOrEmpty(grpOption.price)) {
                    form.push(" (£");
                    form.push(<label htmlFor="productItem">{grpOption.price.toFixed(2)}</label>);
                    form.push(")");

                }
                form.push(<p></p>);
            }

        });
        return form;

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let item = this.state.item;
        item[e.target.name] = value;
        this.setState({item: item});

    }

}


