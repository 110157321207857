import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import MerchantBranchSettingsService from "../../shared/service/MerchantBranchSettingsService";
import {
    END_OF_DAY_TIME,
    HOUR_AND_MINUTE, MENU_DISPLAY_TEMPLATE, MENU_DISPLAY_TYPE,
    OPENCLOSE, PRICE_CALCULATION_METHOD,
    SERVICE_CHARGE_DEFAULT_VALUE,
    SERVICE_CHARGE_OPTIONS,
    YESNO
} from "../../shared/utils/Constants";
import {InputNumber} from "primereact/inputnumber";
import {TabPanel, TabView} from "primereact/tabview";
import {ColorPicker} from "primereact/colorpicker";

const defaultMerchantBranchSettings = {
    id: '', email: null, endOfDayHour: "00", isAutoEndOfDay: true
};

export default class BranchSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantBranchSettings: defaultMerchantBranchSettings,
            merchantBranchSettingsTemp: defaultMerchantBranchSettings,
            marketPlaceSaveButtonRender: true,
            endOfDaySaveButtonRender: true,
            deliverySaveButtonRender: true,
            branchSchedulesButtonRender: true,
            productPriceButtonRender: true,
            orderDisplaySettingsButtonRender: true
        }
        this.merchantBranchSettingsService = new MerchantBranchSettingsService();
    }

    componentDidMount() {
        let obj1 = Object.assign({}, defaultMerchantBranchSettings);
        this.setState({
            merchantBranchSettings: obj1,
            marketPlaceSaveButtonRender: false,
            endOfDaySaveButtonRender: false,
            deliverySaveButtonRender: false,
            branchSchedulesButtonRender: false,
            productPriceButtonRender: false,
            orderDisplaySettingsButtonRender: false,
            merchantBranchSettingsTemp: Object.assign({}, obj1)
        });


        this.findByBranchId(-1);


    }

    findByBranchId = (id) => {
        this.merchantBranchSettingsService.findByBranchId(id).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({
                    merchantBranchSettings: response,
                    merchantBranchSettingsTemp: Object.assign({}, response)
                });
            }
        });
    }

    render() {
        return (<div>
            <AvForm>
                <TabView>
                    <TabPanel header="EOD Settings">
                        {this.endOfSettingDetails()}
                    </TabPanel>
                    <TabPanel header="Basket Settings">
                        {this.basketSettingDetails()}
                    </TabPanel>
                    <TabPanel header="Branch Schedules">
                        {this.branchSchedules()}
                    </TabPanel>
                    <TabPanel header="Product Price">
                        {this.productPrice()}
                    </TabPanel>

                    <TabPanel header="Market Place Settings">
                        {this.marketPlaceSettings()}
                    </TabPanel>
                    <TabPanel header="Order Display Settings">
                        {this.orderDisplaySettings()}
                    </TabPanel>
                </TabView>

            </AvForm>
        </div>);
    }

    endOfSettingDetails() {

        return (<div>
            <Row>

                <Col md={4}>
                    <Fieldset legend="EOD Settings">
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md="12">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">EOD Email
                                                    Address</Translate>
                                                <span style={{color: 'red'}}> (*)</span>
                                            </Label>
                                            <AvField
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                disabled={!this.state.endOfDaySaveButtonRender}
                                                validate={{
                                                    minLength: {
                                                        "value": 5, "errorMessage": "Not Valid Email"
                                                    }, maxLength: {
                                                        value: 50,
                                                        errorMessage: "You can enter up to 50 characters in this field."
                                                    }
                                                }}
                                                value={this.state.merchantBranchSettings.email}
                                                onChange={this.__handleChange}/>
                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="12">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Auto End Of
                                                    Day</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="isAutoEndOfDay"
                                                disabled={!this.state.endOfDaySaveButtonRender}
                                                value={this.state.merchantBranchSettings.isAutoEndOfDay}
                                                onChange={this.__handleChange}>
                                                {YESNO.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="12">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">End Of Day
                                                    Hour</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="endOfDayHour"
                                                disabled={!this.state.endOfDaySaveButtonRender}
                                                value={this.state.merchantBranchSettings.endOfDayHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {END_OF_DAY_TIME.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>


                                </Row>
                            </Col>
                        </Row>

                        {this.state.endOfDaySaveButtonRender == false &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({
                                    endOfDaySaveButtonRender: true,
                                    deliverySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}

                        {this.state.endOfDaySaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.saveMerchantBranchSettings("EOF");
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>
                        }

                        {this.state.endOfDaySaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.merchantBranchSettingsTemp;
                                this.setState({
                                    merchantBranchSettings: Object.assign({}, obj1),
                                    endOfDaySaveButtonRender: false,
                                    deliverySaveButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                        }
                    </Fieldset>
                </Col>
                <Col md={3}>
                </Col>
            </Row>

        </div>)
    };

    productPrice() {

        return (<div>
            <Row>

                <Col md={4}>
                    <Fieldset legend="Product Price Calculation">
                        <Row>


                            <Col md="12">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Calculation
                                            Method</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="priceCalculationMethod"
                                        disabled={!this.state.productPriceButtonRender}
                                        value={this.state.merchantBranchSettings.priceCalculationMethod}
                                        onChange={this.__handleChange}>
                                        {PRICE_CALCULATION_METHOD.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Calculation Rate</Translate>
                                    </Label>

                                    <AvField
                                        name="priceCalculationRate"
                                        type="number"
                                        disabled={!this.state.productPriceButtonRender || this.state.merchantBranchSettings.priceCalculationMethod == "NO_CALCULATION"}
                                        value={this.state.merchantBranchSettings.priceCalculationRate}
                                        onChange={this.__handleChange}
                                        className="table-responsive"

                                    />


                                </AvGroup>
                            </Col>


                        </Row>

                        {this.state.productPriceButtonRender == false &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({
                                    productPriceButtonRender: true,
                                    deliverySaveButtonRender: false,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}

                        {this.state.productPriceButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.saveMerchantBranchSettings("PRODUCTPRICE");
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>
                        }

                        {this.state.productPriceButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.merchantBranchSettingsTemp;
                                this.setState({
                                    merchantBranchSettings: Object.assign({}, obj1),
                                    deliverySaveButtonRender: false,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                        }
                    </Fieldset>
                </Col>
                <Col md={3}>
                </Col>
            </Row>

        </div>)
    };

    orderDisplaySettings() {

        return (<div>
            <Row>

                <Col md={4}>
                    <Fieldset legend="Order Display Settings">
                        <Row>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Menu Display Template</Translate>
                                    </Label>


                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="menuDisplayTemplate"
                                        disabled={!this.state.orderDisplaySettingsButtonRender}
                                        value={this.state.merchantBranchSettings.menuDisplayTemplate}
                                        onChange={this.__handleChange}>
                                        {MENU_DISPLAY_TEMPLATE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>


                                </AvGroup>
                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Menu Sorting</Translate>
                                    </Label>


                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="menuDisplayType"
                                        disabled={!this.state.orderDisplaySettingsButtonRender}
                                        value={this.state.merchantBranchSettings.menuDisplayType}
                                        onChange={this.__handleChange}>
                                        {MENU_DISPLAY_TYPE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>


                                </AvGroup>
                            </Col>



                                <Col md="12">
                                    <AvGroup>
                                        <Label for="firstName">
                                            <Translate contentKey="productfirstName">Show Email Address In The Store Card</Translate>
                                        </Label>


                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="showEmailAddressInTheStoreCard"
                                            disabled={!this.state.orderDisplaySettingsButtonRender}
                                            value={this.state.merchantBranchSettings.showEmailAddressInTheStoreCard}
                                            onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {YESNO.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>


                                    </AvGroup>
                                </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="productTag.tradingName">Menu Border Color&nbsp;</Translate>
                                    </Label>
                                    <ColorPicker value={this.state.merchantBranchSettings.menuBorderColor}
                                                 format="hex"
                                                 name="menuBorderColor"
                                                 onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>




                        </Row>

                        {this.state.orderDisplaySettingsButtonRender == false &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({
                                    deliverySaveButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: true,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                });
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}

                        {this.state.orderDisplaySettingsButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.saveMerchantBranchSettings("ORDERDISPLAY");
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>
                        }

                        {this.state.orderDisplaySettingsButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.merchantBranchSettingsTemp;
                                this.setState({
                                    merchantBranchSettings: Object.assign({}, obj1),
                                    deliverySaveButtonRender: false,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                        }
                    </Fieldset>
                </Col>
                <Col md={3}>
                </Col>
            </Row>

        </div>)
    };

    marketPlaceSettings() {

        return (<div>
            <Row>

                <Col md={4}>
                    <Fieldset legend="Market Place Settings">
                        <Row>



                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Extra Information(Market
                                            Place)</Translate>
                                    </Label>

                                    <AvField
                                        name="serviceableAreas"
                                        type="text"
                                        disabled={!this.state.marketPlaceSaveButtonRender}
                                        value={this.state.merchantBranchSettings.serviceableAreas}
                                        onChange={this.__handleChange}
                                        className="table-responsive"

                                    />


                                </AvGroup>
                            </Col>


                        </Row>

                        {this.state.marketPlaceSaveButtonRender == false &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({
                                    deliverySaveButtonRender: false,
                                    marketPlaceSaveButtonRender: true,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}

                        {this.state.marketPlaceSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.saveMerchantBranchSettings("MARKETPLACE");
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>
                        }

                        {this.state.marketPlaceSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.merchantBranchSettingsTemp;
                                this.setState({
                                    merchantBranchSettings: Object.assign({}, obj1),
                                    deliverySaveButtonRender: false,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                        }
                    </Fieldset>
                </Col>
                <Col md={3}>
                </Col>
            </Row>

        </div>)
    };

    basketSettingDetails() {

        return (<div>
            <Row>

                <Col md={4}>
                    <Fieldset legend="Basket Settings">
                        <Row>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Distance(miles) From Postcode that can
                                            be serviced</Translate>
                                    </Label>

                                    <AvField
                                        name="distanceFromPostalCodeThatCanBeServiced"
                                        type="number"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.distanceFromPostalCodeThatCanBeServiced}
                                        onChange={this.__handleChange}
                                        className="table-responsive"

                                    />


                                </AvGroup>
                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Minimum Delivery Basket
                                            Total</Translate>
                                    </Label>

                                    <InputNumber
                                        name="minimumDeliveryBasketPrice"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.minimumDeliveryBasketPrice}
                                        onChange={this.__handleChange}
                                        mode="currency"
                                        className="table-responsive"
                                        currency="GBP" locale="en-US"
                                    />


                                </AvGroup>
                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Delivery Fee</Translate>
                                    </Label>

                                    <InputNumber
                                        name="deliveryFee"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.deliveryFee}
                                        onChange={this.__handleChange}
                                        mode="currency"
                                        className="table-responsive"
                                        currency="GBP" locale="en-US"
                                    />


                                </AvGroup>
                            </Col>

                            <Col md="12">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Free Delivery Amount</Translate>
                                    </Label>

                                    <InputNumber
                                        name="freeDeliveryAmount"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.freeDeliveryAmount}
                                        onChange={this.__handleChange}
                                        mode="currency"
                                        className="table-responsive"
                                        currency="GBP" locale="en-US"
                                    />


                                </AvGroup>
                            </Col>




                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Collection Service Charge
                                            Options</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="serviceChargeOptions"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.serviceChargeOptions}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SERVICE_CHARGE_OPTIONS.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Collection Default Service
                                            Charge</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="defaultServiceCharge"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.defaultServiceCharge}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SERVICE_CHARGE_DEFAULT_VALUE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Table Order Service Charge
                                            Options</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="serviceChargeOptionsTableOrder"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.serviceChargeOptionsTableOrder}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SERVICE_CHARGE_OPTIONS.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Table Order Default Service
                                            Charge</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="defaultServiceChargeTableOrder"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.defaultServiceChargeTableOrder}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SERVICE_CHARGE_DEFAULT_VALUE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Delivery Service Charge
                                            Options</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="serviceChargeOptionsDelivery"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.serviceChargeOptionsDelivery}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SERVICE_CHARGE_OPTIONS.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Delivery Default Service
                                            Charge</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="defaultServiceChargeDelivery"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.defaultServiceChargeDelivery}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SERVICE_CHARGE_DEFAULT_VALUE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Scheduled for Delivery?</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="scheduledForDelivery"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.scheduledForDelivery}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {YESNO.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="6">
                            </Col>
                            <Col md="6">
                            <AvGroup>
                                <Label for="firstName">
                                    <Translate contentKey="productfirstName">Minimum Delivery Duration(Min)</Translate>
                                </Label>

                                <AvField
                                    name="minimumDeliveryDeliveryDuration"
                                    type="number"
                                    disabled={!this.state.deliverySaveButtonRender}
                                    value={this.state.merchantBranchSettings.minimumDeliveryDeliveryDuration}
                                    onChange={this.__handleChange}
                                    className="table-responsive"

                                />


                            </AvGroup>
                            </Col>
                            <Col md="6">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Maximum Delivery Duration(Min)</Translate>
                                    </Label>

                                    <AvField
                                        name="maximumDeliveryDeliveryDuration"
                                        type="number"
                                        disabled={!this.state.deliverySaveButtonRender}
                                        value={this.state.merchantBranchSettings.maximumDeliveryDeliveryDuration}
                                        onChange={this.__handleChange}
                                        className="table-responsive"

                                    />


                                </AvGroup>
                            </Col>

                        </Row>

                        {this.state.deliverySaveButtonRender == false &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({
                                    deliverySaveButtonRender: true,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}

                        {this.state.deliverySaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.saveMerchantBranchSettings("DELIVERY");
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>
                        }

                        {this.state.deliverySaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.merchantBranchSettingsTemp;
                                this.setState({
                                    merchantBranchSettings: Object.assign({}, obj1),
                                    deliverySaveButtonRender: false,
                                    endOfDaySaveButtonRender: false,
                                    branchSchedulesButtonRender: false,
                                    productPriceButtonRender: false,
                                    marketPlaceSaveButtonRender: false,
                                    orderDisplaySettingsButtonRender: false
                                });
                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                        }
                    </Fieldset>
                </Col>
                <Col md={3}>
                </Col>
            </Row>

        </div>)
    };

    setChecked = (name, value) => {
        let merchantBranchSettings = this.state.merchantBranchSettings;
        merchantBranchSettings[name] = merchantBranchSettings;
        this.setState({merchantBranchSettings: merchantBranchSettings});
    }


    branchSchedules() {

        return (<div>
            <Row>

                <Col md={4}>
                    <Fieldset legend="Branch Opening & Closing Hours">
                        <Row>
                            <Col md="12">

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Monday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="mondayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.mondayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Monday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="mondayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.mondayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Tuesday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="tuesdayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.tuesdayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Tuesday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="tuesdayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.tuesdayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Wednesday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="wednesdayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.wednesdayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Wednesday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="wednesdayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.wednesdayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Thursday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="thursdayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.thursdayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Thursday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="thursdayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.thursdayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Friday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="fridayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.fridayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Friday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="fridayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.fridayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Saturday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="saturdayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.saturdayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Saturday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="saturdayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.saturdayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Sunday
                                                    Open</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="sundayOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.sundayOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Sunday
                                                    Close</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="sundayCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.sundayCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                            </Col>
                        </Row>


                    </Fieldset>
                </Col>
                <Col md={4}>
                    <Fieldset legend="Branch Delivery Hours">
                        <Row>
                            <Col md="12">
                                <Row>

                                </Row>

                                <Row>


                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Monday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="mondayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.mondayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Monday
                                                    To</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="mondayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.mondayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Tuesday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="tuesdayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.tuesdayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Tuesday
                                                    To</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="tuesdayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.tuesdayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Wednesday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="wednesdayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.wednesdayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Wednesday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="wednesdayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.wednesdayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Thursday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="thursdayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.thursdayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Thursday
                                                    To</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="thursdayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.thursdayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Friday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="fridayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.fridayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Friday
                                                    To</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="fridayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.fridayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Saturday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="saturdayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.saturdayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Saturday
                                                    To</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="saturdayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.saturdayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                                <Row>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Sunday
                                                    From</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="sundayDeliveryOpenHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.sundayDeliveryOpenHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                    <Col md="4">

                                        <AvGroup>
                                            <Label for="emailAddress">
                                                <Translate contentKey="merchantAccount.emailAddress">Sunday
                                                    To</Translate>
                                            </Label>

                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="sundayDeliveryCloseHour"
                                                disabled={!this.state.branchSchedulesButtonRender}
                                                value={this.state.merchantBranchSettings.sundayDeliveryCloseHour}
                                                onChange={this.__handleChange}>
                                                <option value='' key=''/>
                                                {HOUR_AND_MINUTE.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                        </AvGroup>

                                    </Col>

                                </Row>

                            </Col>
                        </Row>


                    </Fieldset>
                </Col>
                <Col md={3}>
                </Col>
            </Row>

            {this.state.branchSchedulesButtonRender == false &&
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.setState({
                        endOfDaySaveButtonRender: false,
                        deliverySaveButtonRender: false,
                        branchSchedulesButtonRender: true,
                        marketPlaceSaveButtonRender: false,
                        productPriceButtonRender: false,
                        orderDisplaySettingsButtonRender: false
                    });
                }}>
                    <Translate contentKey="entity.action.add">Edit</Translate>
                </Button>}

            {this.state.branchSchedulesButtonRender &&
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.saveMerchantBranchSettings("branchScheduling");
                }}>
                    <Translate contentKey="entity.action.add">Save</Translate>
                </Button>
            }

            {this.state.branchSchedulesButtonRender &&
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    let obj1 = this.state.merchantBranchSettingsTemp;
                    this.setState({
                        merchantBranchSettings: Object.assign({}, obj1),
                        marketPlaceSaveButtonRender: false,
                        endOfDaySaveButtonRender: false,
                        deliverySaveButtonRender: false,
                        branchSchedulesButtonRender: false,
                        productPriceButtonRender: false,
                        orderDisplaySettingsButtonRender: false
                    });
                }}>
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
            }

        </div>)
    };

    saveMerchantBranchSettings = (option) => {
        if (option == "EOF") {
            if (GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.email)) {
                return toast.warn("Please fill the required fields!");
            } else if (this.state.merchantBranchSettings.isAutoEndOfDay && GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.endOfDayHour)) {
                return toast.warn("Please fill the End Of Day Hour");
            }
        } else if (option == "PRODUCTPRICE") {
            if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.priceCalculationMethod) && (this.state.merchantBranchSettings.priceCalculationMethod != "EMPTY") && GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.priceCalculationRate)) {
                return toast.warn("Please fill the rate field!");

            }
        }


        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.id)) {

            this.merchantBranchSettingsService.update(this.state.merchantBranchSettings)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            endOfDaySaveButtonRender: false,
                            merchantBranchSettings: response,
                            deliverySaveButtonRender: false,
                            branchSchedulesButtonRender: false,
                            productPriceButtonRender: false,
                            marketPlaceSaveButtonRender: false,
                            orderDisplaySettingsButtonRender: false,
                            merchantBranchSettingsTemp: Object.assign({}, response)

                        });
                    }
                });
        } else {
            this.merchantBranchSettingsService.create(this.state.merchantBranchSettings)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            endOfDaySaveButtonRender: false,
                            merchantBranchSettings: response,
                            deliverySaveButtonRender: false,
                            branchSchedulesButtonRender: false,
                            productPriceButtonRender: false,
                            marketPlaceSaveButtonRender: false,
                            orderDisplaySettingsButtonRender: false,
                            merchantBranchSettingsTemp: Object.assign({}, response)
                        });
                    }
                });
        }

    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let merchantBranchSettings = this.state.merchantBranchSettings;
        merchantBranchSettings[e.target.name] = value;
        if (e.target.name == "isAutoEndOfDay" && value == "false") {
            merchantBranchSettings["endOfDayHour"] = null;
        } else if (e.target.name == "serviceChargeOptions" && (value == "" || value == "PASSIVE")) {
            merchantBranchSettings["defaultServiceCharge"] = null;
        }else if (e.target.name == "serviceChargeOptionsTableOrder" && (value == "" || value == "PASSIVE")) {
            merchantBranchSettings["defaultServiceChargeTableOrder"] = null;
        }else if (e.target.name == "serviceChargeOptionsDelivery" && (value == "" || value == "PASSIVE")) {
            merchantBranchSettings["defaultServiceChargeDelivery"] = null;
        }
        else if (e.target.name == "priceCalculationMethod" && value == "EMPTY") {
            merchantBranchSettings["priceCalculationRate"] = null;
        }


        this.setState({merchantBranchSettings: merchantBranchSettings});
    }

}

