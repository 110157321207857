import React from 'react';
import {HashRouter, Route} from 'react-router-dom';
import OrdersDisplay from "../../module/storemanagement/OrdersDisplay";

const HashRouterCompDisplayMember =(props)=>(<HashRouter>
    <div >
        <Route path='/' component={OrdersDisplay}/>

    </div>
</HashRouter>);

export default HashRouterCompDisplayMember;
