import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GeneralUtils from "../../utils/GeneralUtils";


export class AppInlineProfileMember extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false,
            noticeCount: 0
        };
        this.onClick = this.onClick.bind(this);

    }

    componentDidMount = () =>{
        //this.noticeService.getCountAllActive().then(response => this.setState({noticeCount: response}));
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        return (
            <div className="profile">

            </div>
        );
    }
}

AppInlineProfileMember.propTypes = {
    logout: PropTypes.func
};
