import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {ACTION_REASON,  LICENSE_STATUS, SERVICE_NAME} from "../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import DateInput from "../../shared/component/form/DateInput";
import LicenseService from "../../shared/service/LicenseService";
import {InputNumber} from "primereact/inputnumber";

const defaultLicense = {
    id: '',
    licenseStatus: '',
    merchantBranchId: '',
    actionReason: '',
    actionReasonDescription: '',
    serviceName: '',
    validDays: null,
    createdDate: null,
    updatedDate: null,
    remainingDaysText: null
};
export default class LicenseSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: defaultLicense,
            licenseTemp:defaultLicense,
            pageMode: null
        }
        this.licenseService = new LicenseService();
    }

    render() {
        return (
            <div class="p-col-12">
                <Fieldset legend={"License"}>
                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">Service
                                        Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="serviceName"
                                    disabled={true}
                                    value={this.state.license.serviceName}
                                    onChange={this.__handleChange}>
                                    {SERVICE_NAME.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">License
                                        Status</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="licenseStatus"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.licenseStatus}
                                    onChange={this.__handleChangeStatus}>
                                    {LICENSE_STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">Action
                                        Reason</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="actionReason"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.actionReason}
                                    onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {ACTION_REASON.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Description</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="actionReasonDescription"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.license.actionReasonDescription}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Valid Days</Translate>
                                </Label>

                                <InputNumber  value={this.state.license.validDays}
                                              disabled={!this.state.licenseSaveButtonRender}
                                              name="validDays"
                                              min={0} max={100}
                                              className="input-group"
                                              onChange={this.__handleChange}
                                              showButtons  />


                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">Remaining Days
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="remainingDaysText"
                                    disabled={true}
                                    value={this.state.license.remainingDaysText}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Created Date</Translate>
                                </Label>

                                <DateInput
                                    name="createdDate"
                                    onChange={this.__handleChange}
                                    disabled={true}
                                    value={this.state.license.createdDate}/>

                            </AvGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Updated Date</Translate>
                                </Label>

                                <DateInput
                                    name="updatedDate"
                                    onChange={this.__handleChange}
                                    disabled={true}
                                    value={this.state.license.updatedDate}/>

                            </AvGroup>

                        </Col>
                    </Row>



                    {!this.state.licenseSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.setState({licenseSaveButtonRender: true});
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.licenseSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.saveLicense();
                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {this.state.licenseSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            let obj1 = this.state.licenseTemp;
                            this.setState({
                                license: Object.assign({}, obj1), licenseSaveButtonRender: false
                            });
                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }

                    <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {

                        this.setState({
                            licenseSaveButtonRender: false
                        });
                        this.props.closeDisplayDialog();

                    }}>
                        <Translate contentKey="entity.action.cancel">Close</Translate>
                    </Button>
                </Fieldset>
            </div>
        );
    }

    saveLicense = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.license.serviceName) ||
            GeneralUtils.isNullOrEmpty(this.state.license.licenseStatus) ||
            GeneralUtils.isNullOrEmpty(this.state.license.actionReason)
        ) {
            return toast.warn("Please fill the required fields!");
        } else if (
            this.state.license.licenseStatus == "DEMO" && GeneralUtils.isNullOrEmpty(this.state.license.validDays)
        ) {
            return toast.warn("Please fill the Valid Days Field!");
        } else {

            this.licenseService.update(this.state.license)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            license: response,
                            licenseTemp: Object.assign({}, response),
                            licenseSaveButtonRender: false
                        })
                        this.props.refreshDataTable();

                    }
                });
        }
    }

    setData = (data) => {
        this.setState({license: data, licenseTemp: Object.assign({}, data),});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultLicense);
        this.setState({license: obj});
        // this.refForm.reset();
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let license = this.state.license;
        license[e.target.name] = value;
        this.setState({license: license});
    }

    __handleChangeStatus = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let license = this.state.license;
        if (value != 'DEMO') {
            license['validDays'] = null;
        }
        license[e.target.name] = value;
        this.setState({license: license});
    }
}

LicenseSave.propTypes = {
    license: PropTypes.object
};
