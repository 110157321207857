import React from 'react';
import DataTableAll from "./DataTableAll";
import {Button} from 'primereact/button';


export default class DataTableWithButton extends DataTableAll {

    constructor(props) {
        super(props);
    }

    complexCell = (rowData, column, col) => {
        if (col.hasOwnProperty('button') == true) {
            let activeKey;
            let isButton = true;
            let className = "p-button-primary"
            if (col.button.hasOwnProperty('activeKey') == true) {
                activeKey = col.button.activeKey;
                if (rowData.hasOwnProperty(activeKey) == false) {
                    isButton = false;
                }
            }
            if (col.button.hasOwnProperty('className')) {
                className =  col.button.className;
            }

            if(rowData.hideButton){
                isButton = false;
            }
            if (isButton) {
                let icon = null;
                let text = null;
                let disabled = false;

                if(col.button.disabled){
                    let colText =  col.button.disabled.split("!=")[0].replace(" ","");
                    let colValue = col.button.disabled.split("!=")[1].replace(" ","");
                    if(rowData[ colText] == colValue){
                        disabled = true;
                    }
                }

                if (col.button.icon) {
                    icon = col.button.icon;
                } else {
                    text = col.button.text;
                }
                let content = [];
                if(rowData[col.field.split(".")[0]] == true){
                    icon = "pi pi-check-circle";
                }
                else if(rowData[col.field.split(".")[0]] == false){
                    icon = "pi pi-times-circle";
                }
                content.push(
                    <Button type="button" style={{border: '0px solid #1982D9',boxShadow:'0px 0px 0px 0px #ccc'}} disabled={disabled} label={text} icon={icon} className={className} onClick={() => this.actionOnclick(rowData, col.button.function)}></Button>
                );
                return <span>{content}</span>;
            }
        }
        else {
            return this.complexCellMultiField(rowData, column, col);
        }
    }

    actionOnclick = (rowData, functionName) => {
        if (this.props[functionName]) {
            this.props[functionName](rowData);
        }
    }

}
