import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GeneralUtils from "../../utils/GeneralUtils";


export class AppInlineProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);

    }

    componentDidMount = () =>{
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        return (
            <div className="profile">
                <div>
                    <i className="pi pi-user" style={{ fontSize: '3rem' }}></i>
                </div>
                <a className="profile-link" onClick={this.onClick}>
                    <span className="username">{GeneralUtils.getUserNameAndFamilyNames()}</span>

                </a>

            </div>
        );
    }
}

AppInlineProfile.propTypes = {
    logout: PropTypes.func
};
