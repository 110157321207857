import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import DepartmentService from "../../shared/service/DepartmentService";

export default class DepartmentTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transferDepartmentList: [], transferDepartmentId: null, departmentId: null
        }
        this.departmentService = new DepartmentService();
    }

    componentDidMount() {

    }

    render() {
        return (<div className="p-col-12">
            <Fieldset legend="Transfer Department">
                <Row>
                    <Col md="3">
                        <AvGroup>
                            <Label for="mobilePhone">
                                <Translate contentKey="contactManagement.mobilePhone">Department</Translate>
                            </Label>

                            <AvField
                                type="select"
                                disabled={false}
                                className="form-control"
                                name="transferDepartmentId"
                                value={this.state.transferDepartmentId}
                                onChange={this.__handleChange}>

                                <option value='' key=''/>
                                {this.state.transferDepartmentList.map(element => (
                                    <option value={element.id} key={element.id}>
                                        {element.name}
                                    </option>))}
                            </AvField>

                        </AvGroup>
                    </Col>
                </Row>
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.delete()
                }}>
                    <Translate contentKey="entity.action.add">Delete</Translate>
                </Button>
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.props.closeDisplayDialog();
                }}>
                    <Translate contentKey="entity.action.add">Cancel</Translate>
                </Button>
            </Fieldset>
        </div>);
    }

    setDepartmentId = (departmentId) => {
        this.setState({departmentId: departmentId});
        this.departmentService.findAll().then(response => this.setState({
            transferDepartmentList: this.filterDepartmentId(response, departmentId)
        }));
    }

    filterDepartmentId = (response, departmentId) => {
        return response.filter(function (el) {
            return el.id != departmentId;
        });
    }

    delete = () => {
        let transferDepartmentId = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.transferDepartmentId)) {
            transferDepartmentId = this.state.transferDepartmentId;

        }
        this.departmentService.deleteAndTransfer(this.state.departmentId, transferDepartmentId)
            .then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    window.location.href = "#departmentList";
                }
            });
        return undefined;
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({transferDepartmentId: value});
    }

}

DepartmentTransfer.propTypes = {
    order: PropTypes.object
};
