import React from 'react';
import {AvForm} from 'availity-reactstrap-validation';
import CompletedOrdersColumn from "../../shared/component/dataTable/columns/CompletedOrdersColumn.json";
import {Fieldset} from "primereact/fieldset";
import ReactCountdownClock from "react-countdown-clock";
import DataTable from "../../shared/component/dataTable/DataTable";
import {Row} from "reactstrap";
import Dialog from "../../shared/component/dialog/Dialog";
import OrderHistoryDetail from "./OrderHistoryDetail";
import OrdersHistoryColumn from "../../shared/component/dataTable/columns/OrdersHistoryColumn.json";
import GeneralUtils from "../../shared/utils/GeneralUtils";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds:60,
            orderNo:null
        };
    }

    componentDidMount() {
        this.getCompletedOrders();
    }

    getCompletedOrders(){

        this.refDataTable.refresh();
        this.setState({
            seconds : this.state.seconds + 0.00000001
        });

    }

    refreshDataTable = () => {
        this.getCompletedOrders();
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false,orderNo:null});
    }


    render() {
        return (<div>
            <AvForm>
                {this.completedOrderList()}
            </AvForm>
        </div>);
    }

    countDownClock = () => {
        this.getCompletedOrders();
    }

    completedOrderList() {

        return (<div>
            <Fieldset legend="Order History">

                <DataTable ref={(elem) => this.refDataTable = elem} fields={OrdersHistoryColumn.fields}
                           objectName={OrdersHistoryColumn.name}
                           dataURL="storemanagement/order/completedOrders"
                           rows={10}
                           emptyMessage="Not any order"
                           selectionMode="single" onDataSelect={this.onDataSelect}
                           loading={this.state.loading}/>


                <Dialog visible={this.state.displayDialog}
                        header={this.state.orderNo == null ? "Order Details" :this.state.orderNo}
                        onHide={() => this.setState({displayDialog: false})} style={GeneralUtils.getDialogStyleMerchantForDialog()}>

                    <OrderHistoryDetail refreshDataTable={this.refreshDataTable} ref={(elem) => this.refModelSave = elem}
                                        closeDisplayDialog={this.closeDisplayDialog}/>
                </Dialog>
            </Fieldset>


        </div>)
    };

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            displayDialog: true,
            orderNo:data.orderNo
        });

    }



}

