import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantBranchService extends Service {
    constructor() {
        super();
        this.baseURL = 'merchant/branch/';
    }

    async findBranchesByAccountIdForCombo(accountId) {
        return fetch(SERVER_URL + this.baseURL + accountId + "/combo", {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateContact(accountId, contact) {
        return fetch(SERVER_URL + this.baseURL + accountId + "/contact", {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(contact)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateBranchContact(branchId, contact) {
        return fetch(SERVER_URL + this.baseURL + branchId + "/contact", {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(contact)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateBranchAddress(branchId, address) {
        return fetch(SERVER_URL + this.baseURL + branchId + "/address", {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(address)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async generateBarcode(data) {
        return fetch(SERVER_URL + this.baseURL + "generatebarcode", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async generateBarcodeForAddress(data) {
        return fetch(SERVER_URL + this.baseURL + "generatebarcodeforaddress", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async createBranch(accountId, data) {
        return fetch(SERVER_URL + this.baseURL + accountId + "/branch", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getBranchIdByDomainName(domainName) {
        return fetch(SERVER_URL + this.baseURL + "domainName/" + domainName, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async getTradingNameByBranchId(branchId) {
        return fetch(SERVER_URL + this.baseURL + "tradingname/merchantBranchId/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async getStoreInfo(branchId) {
        return fetch(SERVER_URL + this.baseURL + "storeInfo/merchantBranchId/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }




}