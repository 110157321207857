import React from 'react';
import {HashRouter, Route} from 'react-router-dom';
import Dashboard from "../../module/dasboard/Dashboard";
import MerchantAccountList from "../../module/merchant/MerchantAccountList";
import MerchantAccountSave from "../../module/merchant/MerchantAccountSave";
import MerchantBranchSave from "../../module/merchant/MerchantBranchSave";
import MerchantUserSave from "../../module/merchantuser/MerchantUserSave";
import AdminUserSave from "../../module/adminuser/AdminUserSave";
import MailSettingsSave from "../../module/settings/MailSettingsSave";
import Licenses from "../../module/merchant/Licenses";
import DataUpload from "../../module/settings/DataUpload";
import BarcodeGenerator from "../../module/settings/BarcodeGenerator";
import TillConnections from "../../module/merchant/TillConnections";
import AdminSaleReport from "../../module/adminreports/AdminSaleReport";

const HashRouterComp =(props)=>(<HashRouter>
    <div >
        <Route path='//' component={Dashboard}/>
        <Route path='/merchantAccountList' component={MerchantAccountList}/>
        <Route path='/merchantAccountSave' component={MerchantAccountSave}/>
        <Route path='/merchantBranchSave' component={MerchantBranchSave}/>
        <Route path='/merchantUserSave' component={MerchantUserSave}/>
        <Route path='/adminUserList' component={AdminUserSave}/>
        <Route path='/mailSettings' component={MailSettingsSave}/>
        <Route path='/dataUpload' component={DataUpload}/>
        <Route path='/licenses' component={Licenses}/>
        <Route path='/barcodeGenerator' component={BarcodeGenerator}/>
        <Route path='/tillConnections' component={TillConnections}/>
        <Route path='/adminSaleReport' component={AdminSaleReport}/>




    </div>
</HashRouter>);

export default HashRouterComp;
