import React, {Component} from 'react';

import PageSettingsService from "../../service/PageSettingsService";
import GeneralUtils from "../../utils/GeneralUtils";
import BasketService from "../../service/BasketService";
import {Badge,  IconButton} from "@material-ui/core";

export class AppFooterMemberMobile extends Component {
    constructor() {
        super();
        this.state = {
            numberOfItems: 0,
            totalPriceString: null
        };
        this.pageSettingsService = new PageSettingsService();
        this.basketService = new BasketService();
    }

    componentDidMount = () => {
        this.refreshData();
    }

    refreshData() {
        this.basketService.findBasketCreatedByMerchantBranchId(localStorage.getItem("merchantBranchId"), null).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                if (this.refOrderCheckout != null) {
                    if (this.refAppFooterMember != null) {
                        this.refAppFooterMember.setData(response.basketDetailList.length, response.totalPriceString);
                    }
                }
                var numberOfItems = 0;
                for (var i = 0; i < response.basketDetailList.length; i++) {
                    if(response.basketDetailList[i].parentBssId == null){
                        if(response.basketDetailList[i].weighable == false){
                            numberOfItems = numberOfItems + response.basketDetailList[i].quantity;
                        }
                        else{
                            numberOfItems = numberOfItems + 1;
                        }
                    }


                }
                this.setState({
                    numberOfItems: numberOfItems,
                    totalPriceString: response.totalPriceString
                });

            }
        });
    }

    render() {
        var buttonText = "View Your Order";
        let totalPrice = ""
        if (this.state.numberOfItems != null && this.state.numberOfItems > 0 && this.state.totalPriceString != null) {
            totalPrice = this.state.totalPriceString
        }

        return (<footer className="footerMemberMobile">
            <IconButton className="footerMemberCartButton"
                        style={{height: "40px"}}
                        onClick={() => {
                            this.props.viewcart();
                        }}
            >
                <div style={{marginRight: "auto"}}>


                    <Badge badgeContent={this.state.numberOfItems} color="primary" showZero>
                        <i className="pi pi-shopping-cart" style={{fontSize: '1.5rem'}}></i>
                    </Badge>
                </div>
                <div>
                    &nbsp;
                    {buttonText}
                </div>
                <div style={{marginLeft: "auto",alignItems:"center",marginTop:"auto"}}>
                    <h6>{totalPrice}</h6>

                </div>

            </IconButton>


        </footer>)
    }
}
