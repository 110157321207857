import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GeneralUtils from "../../utils/GeneralUtils";
import Sticky from 'react-sticky-el';
import MerchantUserSecurityService from "../../service/MerchantUserSecurityService";
import {SERVER_URL} from "../../utils/Constants";

export class AppTopbarMerchant extends Component {
    constructor() {
        super();
        this.state = {
            noticeCount: 0,
            header: '',

        };
        this.merchantUserSecurityService = new MerchantUserSecurityService();
    }

    componentDidMount = () => {

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("userType")) && localStorage.getItem("userType") == "merchant") {
            refreshToken(this.merchantUserSecurityService);

            function refreshToken(service) {
                setInterval(function () {
                    if (!GeneralUtils.isNullOrEmpty(GeneralUtils.getRefreshTokenFromCookie())) {
                        service.refreshToken().then(response => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                            }
                        });
                    }

                }, 1000 * 60 * 4);
            }
        }

    }

    render() {
        return (
            <Sticky stickyStyle={{zIndex: 1}}>
                <header>
                    {this.state.header != '' &&
                        <img src={this.state.header} width="100%" height="100%" style={{maxHeight: '65px'}}/>
                    }
                    {
                        GeneralUtils.isTestEnvironment() &&

                        <div id="testEnvironmentDiv"
                             style={{
                                 backgroundColor: "red",
                                 textAlign: "center",
                                 height: "40px",
                                 fontSize: "25px"
                             }}>Test
                            Environment!</div>
                    }
                    <div className="layout-topbar clearfix">


                        <a className="layout-menu-button" onClick={this.props.onToggleMenu}>
                            <span className="pi pi-bars"/>
                        </a>
                        <div className="layout-topbar-icons">

                            <a onClick={() => {
                                var location = window.location.toString();
                                if (!location.endsWith('#/')) {
                                    window.history.back();
                                }

                            }}>
                                <span className="layout-topbar-icon pi pi-angle-left"/>
                            </a>
                            <a onClick={() => {
                                window.location = '#/'
                            }}>
                                <span className="layout-topbar-icon pi pi-home"/>
                            </a>
                            <a onClick={this.props.logout}>
                                <span className="layout-topbar-icon pi pi-power-off" style={{marginRight: '2em'}}/>
                            </a>
                        </div>
                    </div>
                </header>
            </Sticky>
        );
    }
}

AppTopbarMerchant.propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
    logout: PropTypes.func
};

AppTopbarMerchant.defaultProps = {
    onToggleMenu: null
};
