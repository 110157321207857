import React from 'react';
import {HashRouter, Route} from 'react-router-dom';
import MerchantAccountList from "../../module/merchant/MerchantAccountList";
import MerchantAccountSave from "../../module/merchant/MerchantAccountSave";
import MerchantBranchSave from "../../module/merchant/MerchantBranchSave";
import MerchantUserSave from "../../module/merchantuser/MerchantUserSave";
import AdminUserSave from "../../module/adminuser/AdminUserSave";
import DashboardMerchant from "../../module/dasboard/DashboardMerchant";
import DepartmentSave from "../../module/storemanagement/DepartmentSave";
import TaxSave from "../../module/storemanagement/TaxSave";
import ProductSave from "../../module/storemanagement/ProductSave";
import ProductList from "../../module/storemanagement/ProductList";
import ProductGroupSave from "../../module/storemanagement/ProductGroupSave";
import WebMenuList from "../../module/storemanagement/WebMenuList";
import WebMenuSave from "../../module/storemanagement/WebMenuSave";
import ProductTagSave from "../../module/storemanagement/ProductTagSave";
import MixAndMatchSave from "../../module/storemanagement/MixAndMatchSave";
import FloorPlanningSave from "../../module/storemanagement/FloorPlanningSave";
import Orders from "../../module/storemanagement/Orders";
import OrderHistory from "../../module/storemanagement/OrderHistory";
import SaleReport from "../../module/reports/SaleReport";
import BranchSettings from "../../module/settings/BranchSettings";
import MixAndMatchList from "../../module/storemanagement/MixAndMatchList";
import DepartmentList from "../../module/storemanagement/DepartmentList";
import SupplierSave from "../../module/storemanagement/SupplierSave";
import CustomerList from "../../module/storemanagement/CustomerList";
import CustomerSave from "../../module/storemanagement/CustomerSave";

const HashRouterCompMerchant =(props)=>(<HashRouter>
    <div >
        <Route path='//' component={DashboardMerchant}/>
        <Route path='/merchantAccountList' component={MerchantAccountList}/>
        <Route path='/merchantAccountSave' component={MerchantAccountSave}/>
        <Route path='/merchantBranchSave' component={MerchantBranchSave}/>
        <Route path='/merchantUserSave' component={MerchantUserSave}/>
        <Route path='/adminUserList' component={AdminUserSave}/>
        <Route path='/departmentSave' component={DepartmentSave}/>
        <Route path='/departmentList' component={DepartmentList}/>
        <Route path='/productTagSave' component={ProductTagSave}/>
        <Route path='/productList' component={ProductList}/>
        <Route path='/productSave' component={ProductSave}/>
        <Route path='/taxSave' component={TaxSave}/>
        <Route path='/floorPlanning' component={FloorPlanningSave}/>
        <Route path='/productGroupSave' component={ProductGroupSave}/>
        <Route path='/mixAndMatchList' component={MixAndMatchList}/>
        <Route path='/mixAndMatchSave' component={MixAndMatchSave}/>
        <Route path='/webMenuList' component={WebMenuList}/>
        <Route path='/orders' component={Orders}/>
        <Route path='/orderHistory' component={OrderHistory}/>
        <Route path='/webMenuSave' component={WebMenuSave}/>
        <Route path='/salereport' component={SaleReport}/>
        <Route path='/branchSettings' component={BranchSettings}/>
        <Route path='/supplier' component={SupplierSave}/>
        <Route path='/customer' component={CustomerList}/>
        <Route path='/customerSave' component={CustomerSave}/>

    </div>
</HashRouter>);

export default HashRouterCompMerchant;
