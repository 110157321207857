import React from 'react';
import {Fieldset} from "primereact/fieldset";
import DataTableWithButton from "../../component/dataTable/DataTableWithButton";
import CheckOutProductColumn from "../../component/dataTable/columns/CheckOutProductColumn.json";
import CheckOutProductColumnWithButtons from "../../component/dataTable/columns/CheckOutProductColumnWithButtons.json";
import Translate from "../../../shared/language/Translate";
import {Button, ButtonGroup, Label, Progress} from "reactstrap";
import {Col, Row} from "reactstrap";
import GeneralUtils from "../../utils/GeneralUtils";
import BasketService from "../../service/BasketService";
import RestTableService from "../../service/RestTableService";
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {PAGE_MODE} from "../../utils/Constants";
import {Dialog} from "primereact/dialog";
import {toast} from "react-toastify";

const defaultOrder = {
    id: '', tableNo: null
};

export class OrderCheckOut extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: defaultOrder,
            expanded: false,
            totalPrice: 0,
            hasOrderBefore: false,
            totalTaxPrice: 0,
            subTotalPrice: 0,
            deliveryPrice: 0,
            calculatedServiceCharge: 0,
            id: null,
            checkOutItemList: [],
            tables: [],
            pageMode: PAGE_MODE.CREATE
        };
        this.onClick = this.onClick.bind(this);
        this.basketService = new BasketService();
        this.restTableService = new RestTableService();
    }

    componentDidUpdate(prevProps, prevState) {
        let tableNo = null;
        if (window.location.href.includes("tableNo")) {
            tableNo = window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length);
        } else {
            tableNo = localStorage.getItem("tableNo");
        }
        if (prevState.order["tableNo"] !== tableNo) {
            localStorage.setItem("tableNo", tableNo);
            localStorage.setItem("orderType", "TABLE_ORDER");
            let order = this.state.order;
            order["tableNo"] = tableNo;
            this.setState({order: order});
        }

    }

    componentDidMount = async () => {


        this.restTableService.findByMerchantBranchId(localStorage.getItem("merchantBranchId")).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({tables: response});
            }
        });

        if (window.location.href.includes("tableNo")) {
            localStorage.setItem("tableNo", GeneralUtils.getParameterByName('tableNo'));
            let order = this.state.order;
            order["tableNo"] = window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length);
            this.setState({order: order});
        } else {
            if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("tableNo"))) {
                let order = this.state.order;
                this.setState({order: order});
            }
        }


    }


    setPageMode = (pageMode) => {
        this.setState({
            pageMode: pageMode
        });
    }

    addItem = (item) => {
        item.merchantBranchId = localStorage.getItem("merchantBranchId");
        if (GeneralUtils.isNullOrEmpty(item.quantity)) {
            item.quantity = 1;
        }
        this.basketService.addItemToBasket(item).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setDataToState(response);
                this.getBasketInfo();
            }
        });

    }

    setData = (data) => {
        let orderTemp = this.state.order;
        this.setState({
            order: orderTemp
        });
        this.setDataToState(data);

    }

    getData = () => {
        return this.state;
    }


    setBranchSettings = (orderType, minimumDeliveryBasketPrice, isClosed, serviceChargeOptions) => {
        this.setState({
            orderType: orderType,
            minimumDeliveryBasketPrice: minimumDeliveryBasketPrice,
            isClosed: isClosed,
            serviceChargeOptions: serviceChargeOptions,
        });

    }

    setDataToState = (data) => {
        localStorage.setItem(data.merchantBranchId, data.bssId);
        this.setState({
            checkOutItemList: data.basketDetailList,
            totalPrice: data.totalPrice,
            subTotalPrice: data.subTotalPrice,
            totalTaxPrice: data.totalTaxPrice,
            deliveryPrice: data.deliveryPrice,
            id: data.id,
            deliveryFeeString: data.deliveryFeeString,
            isClosed: data.isClosed,
            calculatedServiceCharge: data.calculatedServiceCharge,
            serviceChargeIndex: data.serviceCharge
        });

    }

    addItemSetMenu = (item) => {
        item.merchantBranchId = localStorage.getItem("merchantBranchId");
        this.basketService.addSetMenuItemToBasket(item).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setDataToState(response);
                this.getBasketInfo();

            }
        });
    }

    addServiceChargeToBasket = (serviceCharge) => {
        this.basketService.addServiceChargeToBasket(localStorage.getItem("merchantBranchId"), serviceCharge).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setDataToState(response);
                this.props.createDojoTokenAgain();
            }
        });
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    add = (rowData) => {
        rowData.merchantBranchId = localStorage.getItem("merchantBranchId");
        this.basketService.addOneQuantityToItem(rowData).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setDataToState(response);
                this.getBasketInfo();
            }
        });


    }

    subtract = (rowData) => {
        rowData.merchantBranchId = localStorage.getItem("merchantBranchId");
        this.basketService.removeItemFromBasket(rowData).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setDataToState(response);
                this.getBasketInfo();
            }
        });

    }

    getBasketInfo = () => {
        this.props.getBasketInfo();
    }

    onDataSelect = (rowData) => {
        if (this.state.pageMode == PAGE_MODE.CREATE) {
            if (!GeneralUtils.isNullOrEmpty(rowData.code)) {
                this.basketService.getSetMenuItemDetails(rowData.id).then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.props.openItemDetails(response);
                    }
                });
            }
        }

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let order = this.state.order;
        order[e.target.name] = value;
        if (e.target.name == 'tableNo') {
            localStorage.setItem("tableNo", value);
        }
        this.setState({order: order});
    }

    setChecked = (name, value) => {
        let order = this.state.order;
        order[name] = value;
        this.setState({order: order});
    }

    openAggrementDialog = () => {
        this.setState({displayDialog: true});
    }

    render() {
        return (<div>
            <AvForm>
                <Fieldset legend={"Your Order"} toggleable>
                    <DataTableWithButton data={this.state.checkOutItemList} subtract={this.subtract} add={this.add}
                                         ref={(elem) => this.refDataTable = elem}
                                         onDataSelect={this.onDataSelect}
                                         selectionMode='single'
                                         emptyMessage="Start your order"
                                         paginator={false}
                                         fields={this.state.pageMode == PAGE_MODE.CREATE ? CheckOutProductColumnWithButtons.fields : CheckOutProductColumn.fields}> </DataTableWithButton>
                    <p></p>

                    {this.renderServiceCharge()}
                    <p></p>

                    <Row>

                        <Col style={{textAlign: "left"}}>
                            <label htmlFor="productItem">&nbsp;&nbsp;SubTotal:
                            </label>
                        </Col>
                        <Col style={{textAlign: "right"}}>
                            <b> £{this.state.subTotalPrice.toFixed(2)}</b>

                        </Col>

                    </Row>
                    <p></p>


                    {this.state.pageMode != PAGE_MODE.CREATE && this.state.orderType == "Delivery" && this.state.deliveryPrice != null &&
                        <Row>

                            <Col style={{textAlign: "left"}}>
                                <span htmlFor="productItem">&nbsp;&nbsp;Delivery Fee:
                                </span>
                            </Col>
                            <Col style={{textAlign: "right"}}>
                                £{this.state.deliveryPrice.toFixed(2)}
                            </Col>


                        </Row>}
                    {this.state.pageMode != PAGE_MODE.CREATE && this.state.orderType == "Delivery" && this.state.deliveryPrice != null &&

                        <p></p>
                    }

                    {this.state.pageMode != PAGE_MODE.CREATE && this.state.calculatedServiceCharge != null &&
                        <Row>

                            <Col style={{textAlign: "left"}}>
                                <span htmlFor="productItem">&nbsp;&nbsp;Service Charge:
                                </span>
                            </Col>
                            <Col style={{textAlign: "right"}}>
                                £{this.state.calculatedServiceCharge.toFixed(2)}
                            </Col>


                        </Row>}
                    {this.state.pageMode != PAGE_MODE.CREATE && this.state.calculatedServiceCharge != null &&

                        <p></p>
                    }
                    <Row>
                        <Col style={{textAlign: "left"}}>
                                <span htmlFor="productItem">&nbsp;&nbsp;VAT:
                                </span>
                        </Col>
                        <Col style={{textAlign: "right"}}>
                            £{this.state.totalTaxPrice.toFixed(2)}
                        </Col>


                    </Row>
                    <p></p>


                    <Row>
                        <Col style={{textAlign: "left"}}>
                            <label htmlFor="productItem">&nbsp;&nbsp;Total(Incl.VAT):
                            </label>
                        </Col>
                        <Col style={{textAlign: "right"}}>
                            <b> £{this.state.totalPrice.toFixed(2)}</b>

                        </Col>
                        <hr></hr>

                    </Row>
                    {this.getWarnings()}


                    {this.renderApprovement()}


                    <div align={"center"}>

                        {this.state.pageMode == PAGE_MODE.CREATE && <Button color="outline-primary"
                                                                            disabled={this.state.checkOutItemList.length == 0  || this.state.isClosed == true}
                                                                            style={{width: '100%', marginBottom: '5px'}}
                                                                            onClick={() => {

                                                                                var validations = this.props.checkDeliveryPostCodeIsValid();
                                                                                if (GeneralUtils.isNullOrEmpty(validations)) {
                                                                                    if (GeneralUtils.isAuthenticated()) {
                                                                                        window.location.href = "/approveOrder";
                                                                                    } else {
                                                                                        window.location.href = "/login?" + localStorage.getItem(localStorage.getItem("merchantBranchId"));
                                                                                    }
                                                                                }
                                                                                else{
                                                                                    return toast.warn(validations);
                                                                                }




                                                                            }}>
                            <Translate contentKey="entity.action.add">Checkout</Translate>
                        </Button>}

                        {this.state.pageMode == PAGE_MODE.CREATE && GeneralUtils.isMobile() &&
                            <Button color="outline-primary" disabled={false}
                                    style={{width: '100%', marginBottom: '5px'}}
                                    onClick={() => {
                                        this.props.hidecart();
                                    }}>
                                <Translate contentKey="entity.action.add">Continue Shopping</Translate>
                            </Button>}


                    </div>

                </Fieldset>
            </AvForm>
        </div>);
    }

    getTermsAndConditionText() {
        let form = [];
        form.push(<div dangerouslySetInnerHTML={{
            __html: "<b><p>Refund Policy Online Orders</p></b>\n" +
                "<p>We value your satisfaction and aim to provide the best products and services possible. We understand that there may be instances where you need to request a refund for your online order. Please take a moment to review our refund policy to understand the procedures and conditions.</p>\n" +
                "<b><p>1. Refund Eligibility:</p></b>\n" +
                "<p>We offer refunds under the following circumstances:</p>\n" +
                "<p>a. Order Cancellation: If you wish to cancel your order before it has been processed or shipped, please contact us as soon as possible. We will issue a full refund for orders canceled within 3 days of placement.</p>\n" +
                "<p>b. Damaged or Defective Products: If you receive a damaged or defective product, please notify us within 3 days of receiving your order. We will arrange for a refund or replacement, depending on your preference and product availability.</p>\n" +
                "<p>c. Incorrect Items: If you receive items that are different from what you ordered, please contact us within 3 days of receiving your order. We will either send the correct items or issue a refund.</p>\n" +
                "<b><p>2. Refund Process:</p></b>\n" +
                "<p>To request a refund, please follow these steps:</p>\n" +
                "<p>a. Contact Customer Support: Reach out to our customer support team via store contact email or phone number with your order number and a clear description of the issue.</p>\n" +
                "<p>b. Provide Evidence: For damaged, defective, or incorrect items, please provide clear photographs of the problem to help us assess the situation accurately.</p>\n" +
                "<p>c. Review and Approval: Our team will review your refund request and may require additional information if necessary. We will notify you of our decision regarding your refund request.</p>\n" +
                "<p>d. Refund Issuance: If your refund is approved, we will initiate the refund process. Refunds will be processed through the same payment method used for the original order unless otherwise specified.</p>\n" +
                "<b><p>3. Non-Refundable Items:</p></b>\n" +
                "<p>a. Change of Mind: We do not offer refunds for orders where the customer changes their mind or is dissatisfied with the product but the product is as described and not defective.</p>\n" +
                "<p>b. Shipping Costs: Shipping fees are non-refundable unless there was an error on our part in shipping the incorrect items or if the items arrived damaged or defective.</p>\n" +
                "<b><p>4. Refund Timelines:</p></b>\n" +
                "<p>Refunds will typically be processed within 3 business days after approval. Please note that the time it takes for the refund to appear in your account may vary depending on your bank or payment provider.</p>\n" +
                "<b><p>5. Contact Us:</p></b>\n" +
                "<p>If you have any questions or need to initiate a refund request, please contact our customer support team.</p>\n" +
                "<b><p>6. Policy Changes:</p></b>\n" +
                "<p>We reserve the right to update or modify this refund policy at any time. Any changes will be effective immediately upon posting on our website.</p>\n" +
                "<p>By placing an order on our website, you acknowledge that you have read and agree to our refund policy.</p>\n" +
                "<p>Thank you for choosing us for your online orders. We appreciate your trust in our service.</p>\n" +
                "<p><br></p>"
        }}/>);
        return form;
    }

    renderApprovement() {
        let form = [];
        if (this.state.pageMode == PAGE_MODE.CREATE && this.state.hasOrderBefore == false) {
            form.push(<Row>


                <Col md={"12"}>


                    <label htmlFor="approveAggrement">&nbsp;By placing an order you agree to <a
                        id="myLink"
                        href="javascript:void(0);" onClick={this.openAggrementDialog}>our terms and
                        conditions</a></label>


                </Col>

                <Col md={"1"}>
                </Col>
                <p>

                </p>


                <Dialog header="Terms And Conditions" visible={this.state.displayDialog}
                        style={GeneralUtils.getDialogStyleForDialog()}
                        onHide={() => this.setState({displayDialog: false})}>


                    <p className="m-0">
                        {this.getTermsAndConditionText()}

                    </p>

                    <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                            onClick={() => {
                                this.setState({displayDialog: false})

                            }}>
                        <Translate contentKey="entity.action.cancel">Close</Translate>
                    </Button>

                </Dialog>
            </Row>)

        }
        return form;
    }

    renderServiceCharge() {

        let form = [];

        if (this.state.pageMode != PAGE_MODE.CREATE && (this.state.serviceChargeOptions == "ACTIVE" || this.state.serviceChargeOptions == "ENFORCED")) {
            form.push(
                <Row>
                    <Col>

                        <label htmlFor="productItem">&nbsp;&nbsp;Add a service charge?</label>
                        {this.state.serviceChargeOptions == "ACTIVE" &&
                            <label htmlFor="approveAggrement">&nbsp;&nbsp;<a
                                id="notThisTime"
                                href="javascript:void(0);" onClick={() => {
                                this.setState({serviceChargeIndex: "0%"});
                                this.addServiceChargeToBasket("0%")
                            }}>Not this
                                time</a></label>
                        }
                        <ButtonGroup style={{display: "flex", zIndex: 0}}>
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    this.setState({serviceChargeIndex: "5%"});
                                    this.addServiceChargeToBasket("5%")
                                }}
                                active={this.state.serviceChargeIndex === "5%"}
                            >
                                5%
                            </Button>

                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    this.setState({serviceChargeIndex: "10%"});
                                    this.addServiceChargeToBasket("10%")
                                }}
                                active={this.state.serviceChargeIndex === "10%"}
                            >
                                10%
                            </Button>
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    this.setState({serviceChargeIndex: "20%"});
                                    this.addServiceChargeToBasket("20%")
                                }}
                                active={this.state.serviceChargeIndex === "20%"}
                            >
                                20%
                            </Button>


                        </ButtonGroup>

                    </Col>


                </Row>
            );
        }
        if (this.state.pageMode != PAGE_MODE.CREATE && (this.state.serviceChargeOptions == "ACTIVE" || this.state.serviceChargeOptions == "ENFORCED")) {
            form.push(<hr></hr>);
        }

        return form;
    }

    getWarnings() {
        return <>
            {this.state.pageMode != PAGE_MODE.CREATE && this.state.orderType == "Delivery" && !GeneralUtils.isNullOrEmpty(this.state.deliveryFeeString) &&
                <Row>

                    <Col>
                        <label htmlFor="productItem">&nbsp;&nbsp;
                            {this.state.deliveryFeeString}</label>

                    </Col>


                </Row>}
            {this.state.pageMode != PAGE_MODE.CREATE && this.state.orderType == "Delivery" && !GeneralUtils.isNullOrEmpty(this.state.minimumDeliveryBasketPrice) &&

                <p></p>
            }


            {this.state.pageMode != PAGE_MODE.CREATE && this.state.orderType == "Delivery" && !GeneralUtils.isNullOrEmpty(this.state.minimumDeliveryBasketPrice) &&

                <Row>

                    <Col>
                        <label htmlFor="productItem">&nbsp;&nbsp;Minimum Basket Total:
                            £{this.state.minimumDeliveryBasketPrice.toFixed(2)}</label>

                    </Col>

                </Row>
            }
            {this.state.pageMode != PAGE_MODE.CREATE && this.state.orderType == "Delivery" && !GeneralUtils.isNullOrEmpty(this.state.minimumDeliveryBasketPrice) &&
                <p></p>
            }


            <p></p>

        </>;
    }
}

OrderCheckOut.propTypes = {};
