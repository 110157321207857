import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Loading from "../../shared/component/others/Loading";
import AdminSaleReportColumn from "../../shared/component/dataTable/columns/AdminSaleReportColumn.json";
import Dialog from "../../shared/component/dialog/Dialog";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AdminZXReportService from "../../shared/service/AdminZXReportService";
import DataTable from "../../shared/component/dataTable/DataTable";
import AdminSaleReportDetail from "./AdminSaleReportDetail";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import {Button, Col, Label, Row} from "reactstrap";
import Translate from "../../shared/language/Translate";
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import {BASKET_STATUS, LAST_NDAYS} from "../../shared/utils/Constants";
import NumberFormat from "../../shared/component/form/NumberFormat";

const defaultSummary = {
    netSale: 0,
    grossSale: 0,
    refunds: 0,
    netSaleCnt: 0,
    grossSaleCnt: 0,
    refundsCnt: 0
};

export default class AdminSaleReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saleReportList: [],
            loading: false,
            displayCalculateConfirmDialog: false,
            noticeResponse: "",
            reportId: null,
            merchantBranchList: [],
            summary: Object.assign(defaultSummary),

        };
        this.adminZXReportService = new AdminZXReportService();
        this.merchantBranchService = new MerchantBranchService();

    }

    componentDidMount() {
        this.merchantBranchService.findAll().then(response => this.setState({
            merchantBranchList: response
        }));

        this.refreshSummary();

    }


    render() {
        return (<div>
            <AvForm>
                <Fieldset legend="Sale Report">
                    {this.renderFilter()}
                    {this.renderSummary()}
                    {this.renderReports()}
                </Fieldset>

            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    __handleChangeMerchantBranch = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({merchantBranchId: value});
    }

    __handleChangeStartDate = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({startDate: value, lastNDays: null});
    }

    __handleChangeLastNDays = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            let startDate = new Date();
            startDate.setDate(startDate.getDate() - value);
            this.setState({endDate: new Date().getTime(), startDate: startDate.getTime()});
        } else {
            this.setState({endDate: null, startDate: null});
        }
        this.setState({lastNDays: value});
    }

    __handleChangeEndDate = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({endDate: value, lastNDays: null});
    }

    renderSummary() {
        if (this.refDataTable != undefined) {


            return (<div><Row>
                    <Col md={2}>
                        <h6 style={{fontWeight: "bold"}}>Net Sale:&nbsp;{GeneralUtils.formatGBP(this.state.summary.netSale)}</h6>
                    </Col>

                    <Col md={2}>
                        <h6 style={{fontWeight: "bold"}}>Gross Sale:&nbsp;{GeneralUtils.formatGBP(this.state.summary.grossSale)}</h6>
                    </Col>

                    <Col md={2}>
                        <h6 style={{fontWeight: "bold"}}>Refund Sale:&nbsp;{GeneralUtils.formatGBP(this.state.summary.refunds)}</h6>
                    </Col>

                    <Col md={2}>
                        <h6 style={{fontWeight: "bold"}}>Net Sale Cnt:&nbsp;{this.state.summary.netSaleCnt}</h6>
                    </Col>
                    <Col md={2}>
                        <h6 style={{fontWeight: "bold"}}>Gross Sale Cnt:&nbsp;{this.state.summary.grossSaleCnt}</h6>
                    </Col>
                    <Col md={2}>
                        <h6 style={{fontWeight: "bold"}}>Refunds Cnt:&nbsp;{this.state.summary.refundsCnt}</h6>
                    </Col>


                </Row>
                </div>

            )
        }

    }

    renderFilter() {
        return (<div><Row>

            <Col md="2">

                <AvGroup>
                    <Label for="mobilePhone">
                        <Translate contentKey="contactManagement.mobilePhone">Merchant
                            Branch</Translate>
                    </Label>

                    <AvField
                        type="select"
                        className="form-control"
                        name="merchantBranchId"
                        value={this.state.merchantBranchId}
                        onChange={this.__handleChangeMerchantBranch}>

                        <option value='' key=''/>
                        {this.state.merchantBranchList.map(element => (
                            <option value={element.id} key={element.id}>
                                {element.tradingName}
                            </option>))}
                    </AvField>

                </AvGroup>
            </Col>

            <Col md="2">
                <AvGroup>
                    <Label for="tradingName">
                        <Translate contentKey="merchantBranch.tradingName">
                            Duration</Translate>
                    </Label>
                    <AvField
                        type="select"
                        className="form-control"
                        name="lastNDays"
                        value={this.state.lastNDays}
                        onChange={this.__handleChangeLastNDays}>
                        <option value='' key=''/>
                        {LAST_NDAYS.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>))}
                    </AvField>
                </AvGroup>
            </Col>

            <Col md="2">
                <AvGroup>
                    <Label for="tradingName">
                        <Translate
                            contentKey="merchantBranch.tradingName">From Start Date</Translate>
                    </Label>

                    <DateInput
                        name="startDate"
                        format={"DD/MM/YYYY"}
                        onChange={this.__handleChangeStartDate}
                        disabled={false}
                        value={this.state.startDate}/>

                </AvGroup>

            </Col>

            <Col md="2">
                <AvGroup>
                    <Label for="tradingName">
                        <Translate
                            contentKey="merchantBranch.tradingName">From End Date</Translate>
                    </Label>

                    <DateInput
                        name="endDate"
                        format={"DD/MM/YYYY"}
                        onChange={this.__handleChangeEndDate}
                        disabled={false}
                        value={this.state.endDate}/>

                </AvGroup>

            </Col>


        </Row>
            <Row>
                <Col>
                    <Button color="outline-primary" style={{marginBottom: "10px"}}
                            onClick={() => {
                                this.refDataTable.refresh();
                                this.refreshSummary();


                            }}>
                        <Translate contentKey="entity.action.add">Search</Translate>
                    </Button>
                </Col>

            </Row>

        </div>)
    }

    refreshSummary() {
        let merchantBranchId = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranchId)) {
            merchantBranchId = this.state.merchantBranchId;
        }

        let startDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.startDate)) {
            startDate = this.state.startDate;
        }
        let endDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.endDate)) {
            endDate = this.state.endDate;
        }

        this.adminZXReportService.getSummary(merchantBranchId, startDate, endDate).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({summary: response});
            }

        });

    }


    renderReports() {
        let merchantBranchId = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranchId)) {
            merchantBranchId = this.state.merchantBranchId;
        }
        let startDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.startDate)) {
            startDate = this.state.startDate;
        }
        let endDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.endDate)) {
            endDate = this.state.endDate;
        }

        return (<div>


            <DataTable fields={AdminSaleReportColumn.fields}
                       ref={(elem) => this.refDataTable = elem}
                       objectName={AdminSaleReportColumn.name}
                       onDataSelect={this.onDataSelect}
                       rows={10}
                       reportName="Sale Reports"
                       paginator={true}
                       dataURL={"admin/zxreport/merchantBranchId/" + merchantBranchId + "/" + startDate + "/" + endDate}
                       selectionMode="single"
                       loading={this.state.loading}/>


            <Dialog visible={this.state.displayDialog}
                    header={"Sale Report Details"}
                    onHide={() => this.setState({displayDialog: false})}
                    style={GeneralUtils.getDialogStyleForDialog()}>

                <AdminSaleReportDetail closeDisplayDialog={this.closeDisplayDialog}
                                       ref={(elem) => this.refModelSave = elem}
                />
            </Dialog>


        </div>);
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            displayDialog: true
        });
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
        this.refDataTable.refresh();
        this.refreshSummary();
    }


}

