import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class AddressService extends Service {
    constructor() {
        super();
        this.baseURL = 'v1/api/address/';
    }

    async getAddresses(branchId, postcode) {
        return fetch(SERVER_URL + this.baseURL + "branchId/" + branchId  +"/postcode/" + postcode, {
            crossDomain: true,
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async isServicableThisPostCode(branchId, postCode) {
        return fetch(SERVER_URL + this.baseURL + "servicable/merchantBranchId/" + branchId  +"/postCode/" + postCode, {
            crossDomain: true,
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}