import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import Loading from "../../shared/component/others/Loading";
import DataTable from "../../shared/component/dataTable/DataTable";
import SaleReportColumn from "../../shared/component/dataTable/columns/SaleReportColumn.json";
import {Button, Col, Row} from "reactstrap";
import Translate from "../../shared/language/Translate";
import GeneralUtils from "../../shared/utils/GeneralUtils";

export default class SaleReportDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            loading: false
        };
    }

    componentDidMount() {

    }


    render() {
        return (<div>
            <AvForm>
                {this.renderReport()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    exportPdf = () => {
        this.refDataTable.exportPdf();
    }

    sendEmail = () => {
        this.refDataTable.sendEmail();
    }

    renderReport() {
        return (<div>

            <Fieldset legend="Sale Report">

                <Row>
                    <Col md="12">
                        <Button color="outline-primary" style={{float: 'left',   width: '140px',marginRight: '5px'}}
                                onClick={this.exportPdf} className="pi pi-file-pdf">
                            &nbsp;
                            <Translate contentKey="entity.action.csv">Export To PDF</Translate>
                        </Button>
                        <Button color="outline-primary" style={{float: 'left',  width: '140px',marginRight: '5px'}}
                                onClick={this.sendEmail} className="pi-send">
                            &nbsp;
                            <Translate contentKey="entity.action.csv">Send Email</Translate>
                        </Button>

                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <DataTable fields={SaleReportColumn.fields}
                                   refresh={false}
                                   ref={(elem) => this.refDataTable = elem}
                                   objectName={SaleReportColumn.name}
                                   rows={100}
                                   reportName="Sale Report"
                                   paginator={false}
                                   parameter={!GeneralUtils.isNullOrEmpty(this.state.id) ? this.state.id : null}
                                   dataURL={!GeneralUtils.isNullOrEmpty(this.state.id) ? "storemanagement/zxreport/salereport/" + this.state.id : null}
                                   selectionMode="single"
                                   loading={this.state.loading}/>

                    </Col>
                </Row>


            </Fieldset>

            <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                    onClick={() => {
                        this.props.closeDisplayDialog();
                    }}>
                <Translate contentKey="entity.action.add">Close</Translate>
            </Button>


        </div>);
    }

    setData = (data) => {
        this.setState({
            id: data.id
        }, () => {
            this.refDataTable.refresh(data.id);
        });

    }

}

