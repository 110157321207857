import React from 'react';
import './App.css';
import GeneralUtils from './shared/utils/GeneralUtils';
import Login from './module/login/Login';
import AppMaster from './shared/layaout/sigma/AppMaster';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginMerchant from "./module/login/LoginMerchant";
import AppMasterMerchant from "./shared/layaout/sigma/AppMasterMerchant";
import AppMasterMember from "./shared/layaout/sigma/AppMasterMember";
import PasswordResetFinishMember from "./module/login/PasswordResetFinishMember";
import Dialog from "./shared/component/dialog/Dialog";
import MerchantUserSecurityService from "./shared/service/MerchantUserSecurityService";
import LicenseService from "./shared/service/LicenseService";
import TillService from "./shared/service/TillService";
import Loading from "./shared/component/others/Loading";
import CheckPageService from "./shared/service/CheckPageService";
import AppMasterDisplayMember from "./shared/layaout/sigma/AppMasterDisplayMember";
import MerchantBranchService from "./shared/service/MerchantBranchService";
import {WEB_MENU_SMALL_IMAGE} from "./shared/utils/Constants";

class App extends React.Component {

    constructor(props) {
        super(props);
        let isAuth = localStorage.getItem("isAuthenticated") && !GeneralUtils.isNullOrEmpty(localStorage.getItem("isAuthenticated"));
        this.state = {
            isAuthenticated: isAuth,
            appMode: 'member',
            forgotPassword: false,
            noticeList: []
        };

        this.merchantUserSecurityService = new MerchantUserSecurityService();
        this.merchantBranchService = new MerchantBranchService();
        this.licenseService = new LicenseService();
        this.tillService = new TillService();
        this.checkPageService = new CheckPageService();

    }

    componentDidMount = async () => {
        localStorage.setItem("orgHref", window.location.href);
        if (window.location.href.includes("admin") || window.location.href.includes("admintest")) {
            this.setState({appMode: 'admin'});
        } else if (window.location.href.includes("merchant") || window.location.href.includes("merchanttest")) {
            this.setState({appMode: 'merchant'});
        } else {
            this.setState({appMode: 'member'});
        }

        if (window.location.href.includes("tableNo")) {
            localStorage.setItem("tableNo",GeneralUtils.getParameterByName('tableNo'));
            localStorage.setItem("orderType", "TABLE_ORDER");
        }
        else{
            localStorage.removeItem("tableNo");
        }


        if (window.location.href.includes("token_url")) {
            localStorage.setItem("token_url", GeneralUtils.getParameterByName('token_url'));
            if (GeneralUtils.getParameterByName('scope').toUpperCase().indexOf("CUSTOMER") >= 0 ) {
                this.setState({appMode: 'displaymember', loading: true});
            } else {
                this.setState({appMode: 'merchant', loading: true});
                localStorage.setItem("scope", GeneralUtils.getParameterByName('scope'));
            }
            await this.getTokensFromTill();
        }
        else {
            localStorage.removeItem("token_url");
            localStorage.removeItem("scope");
        }
        if (window.location.href.includes("successfulMessage")) {
            var successful = GeneralUtils.getParameterByName('successfulMessage');
            if(successful){
                toast.success("Your order has been placed");
            }
            else{
                toast.error("An unexpected error has occurred.");
            }
            GeneralUtils.removeQueryParam("successfulMessage")
        }

        if (window.location.href.includes("cancelledMessage")) {
            var successful = GeneralUtils.getParameterByName('cancelledMessage');
            if(successful){
                toast.success("Your order has been cancelled");
            }
            else{
                toast.error("An unexpected error has occurred.");
            }
            GeneralUtils.removeQueryParam("cancelledMessage")
        }


        if (window.location.href.includes("passwordChangeKey")) {
            this.setState({forgotPassword: true, displayDialog: true});
        } else if (window.location.href.includes("login")) {
            window.location.hash = "#"
        }


        await this.checkLoginStatus();

    }

    async checkLoginStatus() {
        if (this.state.isAuthenticated === true && (this.state.appMode == "merchant" || this.state.appMode == "admin" || this.state.appMode == "member")) {
            const response = (await this.checkPageService.checkPage());
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                this.setState({isAuthenticated: true});
                localStorage.setItem("isAuthenticated", true);
            } else {
                this.setState({isAuthenticated: false});
                localStorage.setItem("isAuthenticated", false);
            }
        }
    }


    /* render2() {

         return (

             <HandleCache>

                 {({ isLoading, isLatestVersionAvailable, clearCacheAndReload}) => {

                     if (isLoading) return null;
                     if (!isLoading && !isLatestVersionAvailable) {
                         clearCacheAndReload();
                     }

                     if (this.state.appMode == 'admin') {
                         return this.renderAdmin();
                     }
                     else if (this.state.appMode == 'merchant') {
                         return this.renderMerchant();
                     }
                     else
                         return this.renderMember();

                 }}

             </HandleCache>

         );

     }*/

    render() {
        if (this.state.appMode == 'admin') {
            return this.renderAdmin();
        } else if (this.state.appMode == 'merchant') {
           return this.renderMerchant();
        } else if (this.state.appMode == 'displaymember') {
            return this.renderDisplayMember();
        }
        else
            return this.renderMember();

    }


    renderDisplayMember() {
        return <div>
            <AppMasterDisplayMember />
            {this.getToastContainer()}
        </div>;
    }

    renderAdmin() {

        if (this.state.isAuthenticated === true) {
            return this.getHasAuth();
        } else {
            return this.getLogin();
        }
    }

    closeForgotPasswordDisplayDialog = () => {
        this.setState({displayDialog: false, forgotPassword: false});
        window.location.hash = "/";
    }

    renderMember() {
        if (this.state.forgotPassword === false) {
            return this.getHasAuthMember();
        } else {
            return <div>
                <AppMasterMember  logout={this.logoutMember} />
                <Dialog visible={this.state.displayDialog}
                        header={"Change Password!"}
                        closeOnEscape={true}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '30vw', height: '35vw'}}>

                    <PasswordResetFinishMember ref={(elem) => this.refModelSave = elem}
                                               onHide={() => this.closeForgotPasswordDisplayDialog}
                                               closeDisplayDialog={this.closeForgotPasswordDisplayDialog}/>
                </Dialog>
                {this.getToastContainer()}
            </div>;
        }


    }


    async getTokensFromTill() {
        const response = (await this.tillService.getTokens());
        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
            this.setState({loading: false});
            let responseLogin = response;
            let merchantAccountId = responseLogin.userDTO.merchantAccountId;

            localStorage.setItem("clientId", responseLogin.userDTO.domainName);
            localStorage.setItem("hsmUser", JSON.stringify(responseLogin.userDTO));

            localStorage.setItem("hsmPrivilegeList", JSON.stringify(GeneralUtils.getPrivilegeListFromRoleList()));
            this.licenseService.checkLicensesBranchId(responseLogin.userDTO.merchantBranchId).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    localStorage.setItem("userType", "tillUser");
                    localStorage.setItem("merchantBranchId", responseLogin.userDTO.merchantBranchId);
                    localStorage.setItem("domainName", responseLogin.userDTO.domainName);
                    localStorage.setItem("merchantAccountId", merchantAccountId);
                    localStorage.setItem("isAuthenticated", true);
                    this.setState({isAuthenticated: true});
                }

            });

        } else {
            this.setState({loading: false});
            GeneralUtils.clearCookieAndLocalStorage()
            window.location = "";
        }
    }

    renderMerchant() {
        if (this.state.loading) {
            return <Loading></Loading>
        }
        if (this.state.isAuthenticated === true) {
            if (!GeneralUtils.isNullOrEmpty(this.state.landingPage)) {
                window.location.href = this.state.landingPage;
            }
            return this.getHasAuthMerchant();

        } else if (window.location.href.includes("token_url")) {
            return ""
        } else {
            return this.getLoginMerchant();
        }
    }

    getLoginMerchant = () => {
        return <div>
            <LoginMerchant loginSuccess={(landingPage) => {
                this.setState({isAuthenticated: true, landingPage: landingPage});
            }}
            />
            {this.getToastContainer()}
        </div>;
    }
    getLogin = () => {
        return <div>
            <Login loginSuccess={() => {
                this.setState({isAuthenticated: true});

            }}
            />
            {this.getToastContainer()}
        </div>;
    }


    logout = () => {
        this.merchantUserSecurityService.logout().then((response) => {
            GeneralUtils.clearCookieAndLocalStorage();
            window.location.hash = "/";
            this.setState({isAuthenticated: false})
        });


    }

    logoutMember = () => {
        this.merchantUserSecurityService.logout().then(async (response) => {
            GeneralUtils.clearCookieAndLocalStorage();
            this.setState({isAuthenticated: false})

            if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId")) && localStorage.getItem("merchantBranchId") != -1) {
                const response = (await this.merchantBranchService.getStoreInfo(localStorage.getItem("merchantBranchId")));
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && !GeneralUtils.isNullOrEmpty(response.domainName)) {
                    window.location.href = response.domainName;
                } else {
                    window.location.href = "/"
                }
            } else {
                window.location.href = "/"
            }
        });


    }

    getHasAuthMerchant = () => {
        return <div>
            <AppMasterMerchant logout={this.logout}/>
            {this.getToastContainer()}
        </div>;
    }

    getHasAuthMember = () => {
        return <div>
            <AppMasterMember   logout={this.logoutMember} />
            {this.getToastContainer()}
        </div>;
    }

    getHasAuth = () => {
        return <div>
            <AppMaster logout={this.logout}/>
            {this.getToastContainer()}

        </div>;
    }

    getToastContainer = () => {
        return <ToastContainer
            position={toast.POSITION.TOP_CENTER}
            className="toastify-container"
            toastClassName="toastify-toast"
        />;
    }


}

export default App;

