import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {Password} from 'primereact/password';
import NotificationService from "../../shared/service/NotificationService";

const defaultMailSettings = {
    id: null,
    to: null,
    from: null,
    host: null,
    port: null,
    userName: null,
    password: null,
    merchantBranchId:-1
};
export default class MailSettingsSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mailSettings: defaultMailSettings, mailSettingsSaveButtonRender: true,
            mailSettingsTemp:defaultMailSettings,
        }
        this.notificationService = new NotificationService();
    }

    componentDidMount() {
        let id = -1;
        if(this.props.id && this.props.id != null){
           id =  this.props.id;
           defaultMailSettings.merchantBranchId = id;
        }

        this.notificationService.findById(id).then(response => {
            this.setState({
                mailSettings: response, mailSettingsSaveButtonRender: false,
                mailSettingsTemp: Object.assign({}, response)
            });
        });
    }

    render() {
        return (<div>
            <AvForm>
                {this.mailSettingsDetails()}
            </AvForm>
        </div>);
    }

    mailSettingsDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Mail Settings">
                <Row>
                    <Col>
                        <Row>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="companyNo">
                                        <Translate contentKey="adminUser.companyNo">From</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        className="form-control"
                                        disabled={!this.state.mailSettingsSaveButtonRender}
                                        type="mail"
                                        className="form-control"
                                        name="from"
                                        value={this.state.mailSettings.from}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="adminUser.firstName">Host</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="host"
                                        disabled={!this.state.mailSettingsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.mailSettings.host}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="adminUser.lastName">Port</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="port"
                                        disabled={!this.state.mailSettingsSaveButtonRender}
                                        value={this.state.mailSettings.port}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="adminUser.emailAddress">User Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="userName"
                                        disabled={!this.state.mailSettingsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.mailSettings.userName}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="adminUser.lastName">Password</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <Password
                                        value={this.state.mailSettings.password}
                                        name="password"
                                        feedback={false}
                                        className="form-control"
                                        disabled={!this.state.mailSettingsSaveButtonRender}
                                        onChange={this.__handleChange}

                                    />
                                </AvGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {this.state.mailSettingsSaveButtonRender == false && <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.setState({mailSettingsSaveButtonRender: true});
                }}>
                    <Translate contentKey="entity.action.add">Edit</Translate>
                </Button>}

                {this.state.mailSettingsSaveButtonRender && <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.saveMailSettings();
                }}>
                    <Translate contentKey="entity.action.add">Save</Translate>
                </Button>
                }

                {this.state.mailSettingsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.mailSettingsTemp;
                        this.setState({
                            mailSettings: Object.assign({}, obj1), mailSettingsSaveButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>
        </div>)
    };


    saveMailSettings = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.mailSettings.from) ||
            GeneralUtils.isNullOrEmpty(this.state.mailSettings.host) ||
            GeneralUtils.isNullOrEmpty(this.state.mailSettings.port) ||
            GeneralUtils.isNullOrEmpty(this.state.mailSettings.merchantBranchId) ||
            GeneralUtils.isNullOrEmpty(this.state.mailSettings.userName) ||
            GeneralUtils.isNullOrEmpty(this.state.mailSettings.password)
        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.mailSettings.id)) {
                this.notificationService.update(this.state.mailSettings)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                mailSettingsSaveButtonRender: false, mailSettings: response,
                                mailSettingsTemp: Object.assign({}, response)

                            });
                        }
                    });
            } else {
                this.notificationService.create(this.state.mailSettings)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                mailSettingsSaveButtonRender: false, mailSettings: response,
                                mailSettingsTemp: Object.assign({}, response)
                            });
                        }
                    });
            }
        }

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let mailSettings = this.state.mailSettings;
        mailSettings[e.target.name] = value;
        this.setState({mailSettings: mailSettings});
    }

}

