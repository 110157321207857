import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Loading from "../../shared/component/others/Loading";
import DataTable from "../../shared/component/dataTable/DataTable";
import ZXReportService from "../../shared/service/ZXReportService";
import SaleReportColumn from "../../shared/component/dataTable/columns/SaleReportColumn.json";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {Button, Row} from "reactstrap";
import Translate from "../../shared/language/Translate";
import {TabView, TabPanel} from 'primereact/tabview';
import SaleReportArchieveColumn from "../../shared/component/dataTable/columns/SaleReportArchieveColumn.json";
import Dialog from "../../shared/component/dialog/Dialog";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import SaleReportDetail from "./SaleReportDetail";

export default class SaleReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saleReportList: [],
            loading: false
        };
        this.zxReportService = new ZXReportService();

    }

    componentDidMount() {


    }


    render() {
        return (<div className={"col-md-10"}>
            <AvForm>
                <TabView>
                    <TabPanel header="Sale Report" >
                        {this.renderReport()}
                    </TabPanel>
                    <TabPanel header="Sale Report Archive">
                        {this.renderArchieves()}
                    </TabPanel>
                </TabView>
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    renderReport() {
        const conditionalRowStyles = [
            {
                when: row => row.toggleSelected,
                style: {
                    backgroundColor: "green",
                    userSelect: "none"
                }
            }
        ];

        return (<div >

                    <ModalConfirm header='Warning' ok='Ok' onOkClick={this.finaliseSaleReport}
                                  onCancelClick={() => this.setState({displayConfirmDialog: false})}
                                  show={this.state.displayConfirmDialog}
                                  message={this.state.noticeResponse}/>
                    <Button color="outline-primary" style={{marginBottom: '5px', width: '12%'}} onClick={() => {
                        this.showConfirmDialog();
                    }}>
                        <Translate contentKey="entity.action.delete">Finalise</Translate>
                    </Button>

            <Button color="outline-primary" style={{float: 'left',width: '12%',marginRight: '5px'}}  onClick={this.exportPdf} className="pi pi-file-pdf" >
                &nbsp;
                <Translate contentKey="entity.action.csv">Export To PDF</Translate>
            </Button>
            <Button color="outline-primary" style={{float: 'left',width: '12%',marginRight: '5px'}}  onClick={this.sendEmail} className="pi-send" >
                &nbsp;
                <Translate contentKey="entity.action.csv">Send Email</Translate>
            </Button>


            <DataTable fields={SaleReportColumn.fields}
                       ref={(elem) => this.refDataTable = elem}
                       objectName={SaleReportColumn.name}
                       rows={100}
                       conditionalRowStyles={conditionalRowStyles}
                       reportName="Sale Report"
                       paginator={false}
                       dataURL={"storemanagement/zxreport/salereport"}
                       selectionMode="single"
                       loading={this.state.loading}/>

        </div>);
    }

    exportPdf = () => {
        this.refDataTable.exportPdf();
    }

    sendEmail = () => {
        this.refDataTable.sendEmail();
    }
    showConfirmDialog = () => {
        this.setState({
            displayConfirmDialog: true,
            noticeResponse: "Do you want to finalise Sale Report?"
        });
    }

    finaliseSaleReport = () => {
        this.setState({
            loading:true,
        });
        let that = this;
        this.zxReportService.finaliseSaleReport()
            .then((response) => {
                setTimeout(function(){
                    that.setState({
                        loading:false,
                    });
                    that.refDataTable.refresh();
                }, 1000);


            });

    }

    renderArchieves() {
        return (<div>

            <DataTable fields={SaleReportArchieveColumn.fields}
                       ref={(elem) => this.refDataTable = elem}
                       objectName={SaleReportArchieveColumn.name}
                       onDataSelect={this.onDataSelect}
                       rows={10}
                       reportName="Sale Report"
                       paginator={true}
                       dataURL={"storemanagement/zxreport/salereport/archieve"}
                       selectionMode="single"
                       loading={this.state.loading}/>

            <Dialog visible={this.state.displayDialog}
                    header={"Sale Report Details"}
                    onHide={() => this.setState({displayDialog: false})}
                    style={GeneralUtils.getDialogStyleForDialog()}>

                <SaleReportDetail closeDisplayDialog={this.closeDisplayDialog} ref={(elem) => this.refModelSave = elem}
                />
            </Dialog>


        </div>);
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            displayDialog: true
        });

    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }


}

