import React from "react";
import {AvField as AvFieldRS} from 'availity-reactstrap-validation';
import GeneralUtils from "../../../shared/utils/GeneralUtils";

export default class AvField extends React.Component {
    render() {
        return (
            <AvFieldRS {...this.props}  value={GeneralUtils.__isValueEmpty(this.props.value) ? '' : this.props.value}/>
        );
    }
}