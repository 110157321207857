import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ProductService extends Service {
    constructor() {
        super();
        this.baseURL = 'storemanagement/product/';
    }

    async findAllMenu() {
        return fetch(SERVER_URL + this.baseURL+"menu", {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findProductByBarcode(barcode) {
        return fetch(SERVER_URL + this.baseURL + "barcode/" + barcode, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async searchProductsByName(name) {
        return fetch(SERVER_URL + this.baseURL + "name/" + name, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getNextBarcode() {
        return fetch(SERVER_URL + this.baseURL + "barcode/generate", {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async calculateWebPrice(data) {
        return fetch(SERVER_URL + this.baseURL+"calculate/webprice", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateStock(data) {
        return fetch(SERVER_URL + this.baseURL+"stock", {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}