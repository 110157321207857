import React, {Component} from 'react';


export class AppFooter extends Component {
    constructor() {
        super();
        this.state = {};

    }

    componentDidMount = () => {
    }

    render() {
        return("");
    }
}
