import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MemberUserSecurityService extends Service {
    constructor() {
        super();
        this.baseURL = 'memberuser/security/';
    }

    async checkEmail(email) {
        return fetch(SERVER_URL + this.baseURL + "checkemail/" + email, {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getGoogleClientId() {
        return fetch(SERVER_URL + this.baseURL + "google/clientid" , {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    login(data) {
        return fetch(SERVER_URL + this.baseURL + "login", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getTokens(authCode) {
        return fetch(SERVER_URL + this.baseURL + "tokens/code/" + authCode, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    register(data) {
        return fetch(SERVER_URL + this.baseURL + "register", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    resetPasswordInit(data){
        fetch(SERVER_URL + this.baseURL +'password/reset/init', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    resetPasswordFinish(data){
        fetch(SERVER_URL + this.baseURL +'password/reset/finish', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    loginWithGoogle(token,userData) {
        let data = {
            key: token,
            branchName: GeneralUtils.getDomainName(),
            mobilePhone: userData.mobilePhone,
            title: userData.title
        };
        return fetch(SERVER_URL +  this.baseURL + "login/google" , {
            crossDomain: true,
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    refreshToken() {
        let data = {
            refreshToken: GeneralUtils.getRefreshTokenFromCookie()
        };
        return fetch(SERVER_URL +  this.baseURL + "tokens/refresh" , {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}