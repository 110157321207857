import GeneralUtils from "../utils/GeneralUtils";

export default class TillService {
    constructor(props) {
        this.baseURL = props;
    }

    async getTokens() {
        return await new Promise(function (resolve, reject) {
            if( !GeneralUtils.isNullOrEmpty(localStorage.getItem("token_url"))){
                let url = 'http://' +localStorage.getItem("token_url") +  ':8080';
                return fetch(url + '/artpos/dispatcher/webmarket/auth/tokens', {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    let result = response.clone();
                    resolve(GeneralUtils.responseToJson(result));
                }).catch(error => {
                    GeneralUtils.notification(error);
                    reject(GeneralUtils.responseToJson(error));
                });
            }
            else{
                return null;
            }

        });

    }


}