import React from 'react';

import {Card, Col, Row} from "reactstrap";

import {Fieldset} from "primereact/fieldset";


export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount = () => {
    }

    render() {
        let style = {width: '20rem', marginBottom: '1em', marginLeft: '1em', marginRight: '1em'};
        return (
            <div>
                <Fieldset legend="Merchant Accounts And Users">
                    <Row>
                        <a href="#/merchantAccountList">
                            <Card title="Merchant Accounts" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Merchant Accounts</p>
                            </Card>
                        </a>

                        <a href="#/adminUserList">
                            <Card title="Admin Users" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Admin Users</p>
                            </Card>
                        </a>

                    </Row>
                </Fieldset>

                <Fieldset legend="Settings & Data">
                    <Row>
                        <a href="#/mailSettings">
                            <Card title="Mail" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Mail</p>
                            </Card>
                        </a>

                        <a href="#/licenses">
                            <Card title="Licenses" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Licenses</p>
                            </Card>
                        </a>

                        <a href="#/dataUpload">
                            <Card title="Data" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Data</p>
                            </Card>
                        </a>

                        <a href="#/barcodeGenerator">
                            <Card title="Qr Code" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Qr Code</p>
                            </Card>
                        </a>

                        <a href="#/tillConnections">
                            <Card title="Till Connections" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Till Connections</p>
                            </Card>
                        </a>

                    </Row>
                </Fieldset>

                <Fieldset legend="Reports">
                    <Row>
                        <a href="#/adminSaleReport">
                            <Card title="Sale Report" style={style}>
                                <p className="m-2" align={"center"} style={{lineHeight: '2'}}>Sale Report</p>
                            </Card>
                        </a>


                    </Row>
                </Fieldset>


            </div>
        );
    }

}
