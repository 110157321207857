import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import DepartmentService from "../../shared/service/DepartmentService";
import {InputNumber} from "primereact/inputnumber";
import {PRODUCT_TYPE, PRODUCT_UNIT} from "../../shared/utils/Constants";
import ProductService from "../../shared/service/ProductService";


const defaultProduct = {
    id: '',
    name: '',
    status: 'ACTIVE',
    barcode: '',
    departmentId: null,
    departmentProfitMargin: null,
    departmentTaxId: null,
    tax: null,
    price: null,
    oldPrice: null,
    webPrice: null,
    price2: null,
    price3: null,
    unit: 'each',
    quantity: 1,
    weighable: false,
    allowZeroPrice: false,
    allowNegativePrice: false,
    productType: 'PRODUCT_LINE',
    fileType: '',
    fileName: 'Choose',
    fileTypeTill: '',
    fileNameTill: 'Choose',
    productTags: [],
    mixAndMatchName: '',
    minStock: null,
    maxStock: null,
    supplierId: null,
    countryId: null,
    productClassId: null,
    description: '',
    rowNumber: 1000,
    colNo: 10,
    costPrice: 0,
    boxQuantity: null,
    boxUnit: 'each',
    profitMargin: null,
    boxPrice: 0,
    unitCost: 0,
    actualMargin: null,
    retailPrice: 0,
    codeLength: 7
};
export default class ProductBulkSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: Object.assign({}, defaultProduct),
            productTemp: Object.assign({}, defaultProduct),
            productDetailsSaveButtonRender: true,
            departmentList: [],
        }
        this.departmentService = new DepartmentService();
        this.productService = new ProductService();
    }

    componentDidMount() {
        this.departmentService.findAll().then(response => this.setState({departmentList: response}));
        this.generateNextBarcode();
    }

    clearData = () => {
        this.setState({
            product: Object.assign({}, defaultProduct),
            productTemp: Object.assign({}, defaultProduct),
        });

    }


    render() {
        return (<div className="p-col-12">
            <Fieldset legend="Product Definition">
                <Row>
                    <Col md="6">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Barcode</Translate>
                            </Label>
                            <AvField
                                type="number"
                                className="form-control"
                                name="barcode"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                max="99999999999999"
                                value={this.state.product.barcode}
                                onChange={this.__handleChange}/>


                        </AvGroup>
                    </Col>

                    <Col md="3">
                        <Button color="primary"
                                style={{width: '100%'}}
                                onClick={() => {
                                    this.productService.getNextBarcode().then(response => {
                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                            let product = this.state.product;
                                            product["barcode"] = response.barcode;
                                            this.setState({product: product});
                                        }

                                    });

                                }}>
                            <Translate contentKey="entity.action.add">Generate Barcode</Translate>
                        </Button>
                    </Col>

                </Row>
                <Row>

                    <Col md="6">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Name</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="name"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.name}
                                onChange={this.__handleChange}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Price</Translate>
                            </Label>

                            <InputNumber
                                name="price"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.price}
                                onChange={this.__handleChange}
                                mode="currency"
                                className="table-responsive"
                                currency="GBP" locale="en-US"
                            />
                        </AvGroup>
                    </Col>

                    <Col>
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Price2</Translate>
                            </Label>

                            <InputNumber
                                name="price2"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.price2}
                                onChange={this.__handleChange}
                                mode="currency"
                                className="table-responsive"
                                currency="GBP" locale="en-US"
                            />
                        </AvGroup>
                    </Col>

                    <Col>
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Price3</Translate>
                            </Label>

                            <InputNumber
                                name="price3"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.price3}
                                onChange={this.__handleChange}
                                mode="currency"
                                className="table-responsive"
                                currency="GBP" locale="en-US"
                            />
                        </AvGroup>
                    </Col>

                </Row>
                <Row>

                    <Col md="3">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Web Price</Translate>
                            </Label>
                            <InputNumber
                                name="webPrice"
                                disabled={true}
                                value={this.state.product.webPrice}
                                onChange={this.__handleChange}
                                mode="currency"
                                className="table-responsive"
                                currency="GBP" locale="en-US"
                            />

                        </AvGroup>
                    </Col>

                    <Col md="3">
                        <Button color="outline-primary" style={{marginRight: '5px'}}
                                onClick={() => {
                                    this.calculateWebPrice()
                                }}>
                            <Translate contentKey="entity.action.add">Calculate</Translate>
                        </Button>
                    </Col>

                </Row>
                <Row>


                    <Col md="3">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Quantity</Translate>
                            </Label>

                            <InputNumber
                                name="quantity"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.quantity}
                                onChange={this.__handleChange}
                                className="table-responsive"
                                locale="en-US"
                            />

                        </AvGroup>
                    </Col>


                    <Col md="3">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Unit</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="unit"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.unit}
                                onChange={this.__handleChange}>
                                {PRODUCT_UNIT.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                            </AvField>
                        </AvGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md="3">
                        <AvGroup>
                            <Label for="mobilePhone">
                                <Translate contentKey="contactManagement.mobilePhone">Department</Translate>
                            </Label>

                            <AvField
                                type="select"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                className="form-control"
                                name="departmentId"
                                value={this.state.product.departmentId}
                                onChange={this.__handleChange}>
                                <option value='' key=''/>
                                {this.state.departmentList.map(element => (
                                    <option value={element.id} key={element.id}>
                                        {element.name}
                                    </option>))}
                            </AvField>

                        </AvGroup>
                    </Col>
                </Row>
                <Row>

                    <Col md="3">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate contentKey="producttradingName">Product Type</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="productType"
                                disabled={!this.state.productDetailsSaveButtonRender}
                                value={this.state.product.productType}
                                onChange={this.__handleChange}>
                                {PRODUCT_TYPE.map(element => (
                                    <option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                            </AvField>
                        </AvGroup>
                    </Col>


                </Row>
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.saveProduct()
                }}>
                    <Translate contentKey="entity.action.add">Save</Translate>
                </Button>
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.props.closeDisplayDialog();
                }}>
                    <Translate contentKey="entity.action.add">Close</Translate>
                </Button>
            </Fieldset>
        </div>);
    }

    generateNextBarcode = () => {
        this.productService.getNextBarcode().then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                let product = this.state.product;
                product["barcode"] = response.barcode;
                this.setState({product: product});
            }

        });
    }
    saveProduct = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.product.name) ||
            GeneralUtils.isNullOrEmpty(this.state.product.barcode) ||
            GeneralUtils.isNullOrEmpty(this.state.product.quantity) ||
            GeneralUtils.isNullOrEmpty(this.state.product.departmentId) ||
            GeneralUtils.isNullOrEmpty(this.state.product.unit)
        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            this.productService.create(this.state.product)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let obj1 = Object.assign({}, response);
                        obj1.id = null;
                        obj1.bssId = null;
                        obj1.barcode = null;
                        obj1.name = null;
                        obj1.price = null;
                        obj1.price2 = null;
                        obj1.price3 = null;
                        obj1.webPrice = null;
                        this.setState({
                            product: obj1, productTemp: Object.assign({}, obj1)

                        });

                        this.props.refreshDataTable();
                    }
                });
        }
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let product = this.state.product;
        product[e.target.name] = value;
        this.setState({product: product});
    }

    calculateWebPrice = () => {
        let product = this.state.product;
        if (!GeneralUtils.isNullOrEmpty(product.price)) {
            this.productService.calculateWebPrice(product).then(response => {
                product["webPrice"] = response;
                this.setState({product: product});
            });
        }


    }

}

ProductBulkSave.propTypes = {
    order: PropTypes.object
};
