import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import SupplierColumn from "../../shared/component/dataTable/columns/SupplierColumn.json";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import NumberFormat from "react-number-format";
import SupplierService from "../../shared/service/SupplierService";
import {PhoneField} from "../../shared/component/form/PhoneField";

const defaultSupplier = {
    id: '', name: '', status: 'ACTIVE', email: '', phone:''
};
export default class SupplierSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplier: defaultSupplier,
            supplierTemp: defaultSupplier,
            supplierDetailsSaveButtonRender: true, defaultStatus: 'ACTIVE', supplierList: []
        }
        this.supplierService = new SupplierService();
    }

    componentDidMount() {
        let obj1 = Object.assign({}, defaultSupplier);
        this.setState({
            supplier: obj1, supplierDetailsSaveButtonRender: false,
            supplierTemp: Object.assign({}, obj1)
        });
        this.refresh();

    }

    refresh() {
        this.supplierService.findAll().then(response => this.setState({
            supplierList: response
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.supplierDetails()}
            </AvForm>
        </div>);
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.supplier.id)) {
            this.supplierService.delete(this.state.supplier.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let obj1 = Object.assign({}, defaultSupplier);
                        this.setState({
                            supplier: obj1, supplierDetailsSaveButtonRender: false,
                            supplierTemp: Object.assign({}, obj1)
                        });
                        this.refresh();
                    }
                });
        } 

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "Are you sure to delete?"
        });
    }
    supplierDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Supplier Details">
                <Row>
                    <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                  onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                  show={this.state.displayShowStatusConfirmDialog}
                                  message={this.state.noticeResponse}/>

                    <Col md="12">
                        <Row>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="tax.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.supplierDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.supplier.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Email</Translate>
                                    </Label>
                                    <AvField
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        disabled={!this.state.supplierDetailsSaveButtonRender}
                                        validate={{
                                            minLength: {
                                                "value": 5, "errorMessage": "Not Valid Email"
                                            }, maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.supplier.email}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>
                        </Row>

                        <Row>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">
                                            Phone</Translate>
                                    </Label>
                                    <PhoneField kind={'mobile'}
                                                disabled={!this.state.supplierDetailsSaveButtonRender}
                                                name="phone" errorMessage="Not Valid Phone Number"
                                                value={this.state.supplier.phone}
                                                onChange={this.__handleChange}
                                    />
                                </AvGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {GeneralUtils.checkPrivilege("Supplier", "Create") && this.state.supplierDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.supplier.id) &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({supplierDetailsSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("Supplier", "Create") && this.state.supplierDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveSupplier();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("Supplier", "Delete") && this.state.supplierDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.supplier.id) &&

                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.showChangeStatusConfirmDialog(true)
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("Supplier", "Create") && this.state.supplierDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.supplierTemp;
                        this.setState({
                            supplier: Object.assign({}, obj1), supplierDetailsSaveButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>

            <Fieldset legend="Suppliers">
                {GeneralUtils.checkPrivilege("Supplier", "Add") &&
                    <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                        this.addNew();
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("Supplier", "View") &&
                    <DataTableWithButton ref={(elem) => this.refDataTable = elem} fields={SupplierColumn.fields}
                                         objectName={SupplierColumn.name}
                                         data={this.state.supplierList}
                                         rows={5}
                                         selectionMode="single" onDataSelect={this.onDataSelect}
                                         loading={this.state.loading}/>

                }
            </Fieldset>
        </div>)
    };

    onDataSelect = (data) => {
        this.supplierService.checkHealth().then(response => {
            this.supplierService.findById(data.id).then(response => {
                this.setState({supplier: response, supplierDetailsSaveButtonRender: false, supplierTemp: Object.assign({}, response)});

            });
        });

    }
    saveSupplier = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.supplier.name) ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.supplier.id)) {
                this.supplierService.update(this.state.supplier)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                supplierDetailsSaveButtonRender: false, supplier: response,
                                supplierTemp: Object.assign({}, response)

                            });
                            this.refresh();
                        }
                    });
            } else {
                this.supplierService.create(this.state.supplier)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                supplierDetailsSaveButtonRender: false, supplier: response,
                                supplierTemp: Object.assign({}, response)
                            });
                            this.refresh();
                        }
                    });
            }
        }

    }

    addNew() {
        let obj1 = Object.assign({}, defaultSupplier);
        this.setState({
            supplier: obj1, supplierDetailsSaveButtonRender: true, supplierTemp: Object.assign({}, obj1)
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let supplier = this.state.supplier;
        supplier[e.target.name] = value;
        this.setState({supplier: supplier});
    }

}

