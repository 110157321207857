import React from "react";
import GeneralUtils from "../../../shared/utils/GeneralUtils";
import {InputText as InputTextPR} from "primereact/inputtext";

export default class InputText extends React.Component {

    render() {
        return (
            <InputTextPR  {...this.props} value={GeneralUtils.__isValueEmpty(this.props.value)? '' : this.props.value}/>
        );

    }
}