import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import TillConnectionService from "../../shared/service/TillConnectionService";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import TillConnectionColumn from "../../shared/component/dataTable/columns/TillConnectionColumn.json";

export default class TillConnections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tillConnectionList: []
        }

        this.tillConnectionService = new TillConnectionService();
    }

    componentDidMount() {
       this.refreshDataTable();
    }

    render() {
        return (<div>
            <AvForm>
                {this.tillConnections()}
            </AvForm>
        </div>);
    }

    tillConnections() {
        return (
            <div>
            <Fieldset legend="Till Connections">
                <DataTableWithButton ref={(elem) => this.refDataTable = elem} fields={TillConnectionColumn.fields}
                                     objectName={TillConnectionColumn.name}
                                     data={this.state.tillConnectionList}
                                     rows={10}
                                     selectionMode="single"
                                     loading={this.state.loading}/>



            </Fieldset>
        </div>)
    }

    refreshDataTable = () => {
        this.tillConnectionService.findAll().then(response => this.setState({
            tillConnectionList: response
        }));
    }



}

