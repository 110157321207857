import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class PaymentService extends Service {
    constructor() {
        super();
        this.baseURL = 'storemanagement/payment/';
    }

    async createToken(merchantBranchId,crossReference,address) {
        let data = {
            "merchantBranchId" : merchantBranchId,
            "crossReference" : crossReference,
            "webHookUrl":SERVER_URL,
            "address":address,
            "basketBssId":localStorage.getItem(localStorage.getItem("merchantBranchId"))
        };
        return fetch(SERVER_URL + this.baseURL + "token", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async createApplePayToken(merchantBranchId) {
        let data = {
            "merchantBranchId" : merchantBranchId,
            "domainName" : GeneralUtils.getUiUrl().replace("/approveOrder","").replace("/confirmOrder",""),
            "basketBssId":localStorage.getItem(localStorage.getItem("merchantBranchId"))
        };
        return fetch(SERVER_URL + this.baseURL + "applepay/token", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}