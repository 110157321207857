import React from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {AvGroup, AvForm} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from "react-toastify";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import MemberUserAddressService from "../../shared/service/MemberUserAddressService";
import AddressService from "../../shared/service/AddressService";
import MemberBillingAddressService from "../../shared/service/MemberBillingAddressService";
import {TabPanel, TabView} from "primereact/tabview";

const defaultMemberUserAddress = {
    id: null,
    description: '',
    postCode: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    defaultAddress: true
};

const defaultMemberBillingAddress = {
    id: null,
    description: '',
    postCode: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    defaultAddress: true
};

export default class MemberUserAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberUserAddress: Object.assign({}, defaultMemberUserAddress),
            memberUserAddressTemp: Object.assign({}, defaultMemberUserAddress),
            addressList: [],
            filteredAddresses: [],
            memberUserAddressId: null,
            memberBillingAddress: Object.assign({}, defaultMemberBillingAddress),
            memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress),
            billingAddressList: [],
            billingFilteredAddresses: [],
            memberBillingAddressId: null
        }
        this.memberUserAddressService = new MemberUserAddressService();
        this.memberBillingAddressService = new MemberBillingAddressService();
        this.addressService = new AddressService();

    }

    componentDidMount() {
        this.refreshCombo();
    }

    refreshCombo() {
        this.memberUserAddressService.findAll().then(response => this.setState({addressList: response}));
        this.memberBillingAddressService.findAll().then(response => this.setState({billingAddressList: response}));
    }

    __handleChangeList = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.memberUserAddressService.findById(value).then(response => this.setState({
                memberUserAddress: Object.assign({}, response),
                memberUserAddressTemp: Object.assign({}, response),
                memberUserAddressId: value,
                memberUserAddressSaveButtonRender: false
            }));


        } else {
            this.setState({
                memberUserAddressSaveButtonRender: false,
                memberUserAddress: Object.assign({}, defaultMemberUserAddress),
                memberUserAddressTemp:  Object.assign({}, defaultMemberUserAddress),
                memberUserAddressId: null,

            });
        }
    }

    __handleChangeBillingList = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.memberBillingAddressService.findById(value).then(response => this.setState({
                memberBillingAddress: Object.assign({}, response),
                memberBillingAddressTemp: Object.assign({}, response),
                memberBillingAddressId: value,
                memberBillingAddressSaveButtonRender: false
            }));


        } else {
            this.setState({
                memberUserAddressSaveButtonRender: false,
                memberBillingAddress: Object.assign({}, defaultMemberBillingAddress),
                memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress),
                memberBillingAddressId: null,

            });
        }
    }

    render() {
        return (
            <AvForm>
                <Row>
                    <Col md={2}>
                    </Col>

                    <Col md={6}>
                        <TabView activeIndex={this.state.activeTab} onTabChange={(e) => {
                            this.setState({
                                activeTab: e.index,
                                memberUserAddress: Object.assign({}, defaultMemberUserAddress),
                                memberUserAddressTemp: Object.assign({}, defaultMemberUserAddress),
                                memberUserAddressSaveButtonRender: false,
                                memberBillingAddress: Object.assign({}, defaultMemberBillingAddress),
                                memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress),
                                memberBillingAddressSaveButtonRender: false,
                                memberBillingAddressId: null,
                                memberUserAddressId: null
                            });
                        }}>
                            <TabPanel header="Delivery Addresses">
                                {this.renderMemberUserAddress()}
                            </TabPanel>
                            <TabPanel header="Billing Addresses">
                                {this.renderMemberBillingAddress()}
                            </TabPanel>
                        </TabView>
                    </Col>
                </Row>


            </AvForm>
        );
    }

    renderMemberUserAddress() {
        return (<Row>

            <Col md={12}>

                <Fieldset legend={"My Delivery Addresses"}>
                    <Row>
                        <Col md="12">
                            <AvField
                                type="select"
                                disabled={false}
                                className="form-control"
                                name="tax"
                                value={this.state.memberUserAddressId}
                                onChange={this.__handleChangeList}>

                                <option value='' key=''/>
                                {this.state.addressList.map(element => (
                                    <option value={element.id} key={element.id}>
                                        {element.description}
                                    </option>))}
                            </AvField>

                            {!GeneralUtils.isNullOrEmpty(this.state.memberUserAddressId) &&
                                <Button color="outline-primary"
                                        style={{marginRight: '5px', height: '30px', width: '160px'}}
                                        onClick={() => {
                                            this.memberUserAddressService.updateDefaultAddress(this.state.memberUserAddressId)
                                                .then((response) => {
                                                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                        this.setState({
                                                            memberUserAddressSaveButtonRender: false,
                                                            memberUserAddressId: null,
                                                            memberUserAddress: Object.assign({}, defaultMemberUserAddress),
                                                            memberUserAddressTemp: Object.assign({}, defaultMemberUserAddress)

                                                        });
                                                        this.refreshCombo();
                                                    }
                                                });

                                        }}>
                                    <Translate contentKey="entity.action.cancel">Set as default</Translate>
                                </Button>
                            }


                            <ModalConfirm header='Warning' ok='Delete' onOkClick={this.deleteAddress}
                                          onCancelClick={() => this.setState({displayDeleteConfirmDialog: false})}
                                          show={this.state.displayDeleteConfirmDialog}
                                          message={this.state.noticeResponse}/>
                            {!GeneralUtils.isNullOrEmpty(this.state.memberUserAddressId) &&
                                <Button color="outline-danger"
                                        style={{marginRight: '5px', height: '30px', width: '160px'}}
                                        onClick={() => {
                                            this.showDeleteConfirmDialog();
                                        }}>
                                    <Translate contentKey="entity.action.delete">Remove</Translate>
                                </Button>
                            }

                        </Col>
                    </Row>

                </Fieldset>

                <Fieldset legend={"Delivery Address Details"}>


                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Post Code
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="postCode"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    value={this.state.memberUserAddress.postCode}
                                    onChange={this.__handleChange}>
                                </AvField>


                            </AvGroup>

                        </Col>

                        <Col md={3}>


                            <Button color="outline-primary"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    style={{marginRight: '5px', height: '30px'}}
                                    onClick={() => {
                                        this.searchAddress();
                                    }}>
                                <Translate contentKey="entity.action.add">Lookup</Translate>
                            </Button>
                        </Col>


                        <Col md={3}>
                            <AvField
                                type="select"
                                disabled={!this.state.memberUserAddressSaveButtonRender}
                                className="form-control"
                                name="tax"
                                value={this.state.addressId}
                                onChange={this.__handleChangePostCodeList}>

                                <option value='' key=''/>
                                {this.state.filteredAddresses.map(element => (
                                    <option value={element.displayString} key={element.displayString}>
                                        {element.displayString}
                                    </option>))}
                            </AvField>

                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">City
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.city}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>

                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Label
                                    </Translate>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    value={this.state.memberUserAddress.description}
                                    onChange={this.__handleChange}>
                                </AvField>

                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 1
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine1"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "You can enter up to 50 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine1}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 2
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine2"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine2}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 3
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine3"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine3}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 4
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine4"
                                    disabled={!this.state.memberUserAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberUserAddress.addressLine4}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                    </Row>


                    {!this.state.memberUserAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    this.setState({
                                        memberUserAddressSaveButtonRender: true,
                                        memberUserAddress: Object.assign({}, defaultMemberUserAddress),
                                        memberUserAddressTemp: Object.assign({}, defaultMemberUserAddress),
                                        memberUserAddressId: null
                                    });
                                }}>
                            <Translate contentKey="entity.action.add">Add</Translate>
                        </Button>}

                    {!GeneralUtils.isNullOrEmpty(this.state.memberUserAddressId) && !this.state.memberUserAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    this.setState({memberUserAddressSaveButtonRender: true});
                                }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.memberUserAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    this.saveMemberUserAddress();
                                }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {this.state.memberUserAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    let obj1 = this.state.memberUserAddressTemp;
                                    this.setState({
                                        memberUserAddress: Object.assign({}, obj1),
                                        memberUserAddressSaveButtonRender: false
                                    });
                                }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }


                </Fieldset>

            </Col>
        </Row>);
    }

    renderMemberBillingAddress() {
        return (<Row>

            <Col md={12}>

                <Fieldset legend={"My Billing Addresses"}>
                    <Row>
                        <Col md="12">
                            <AvField
                                type="select"
                                disabled={false}
                                className="form-control"
                                name="tax"
                                value={this.state.memberBillingAddressId}
                                onChange={this.__handleChangeBillingList}>

                                <option value='' key=''/>
                                {this.state.billingAddressList.map(element => (
                                    <option value={element.id} key={element.id}>
                                        {element.description}
                                    </option>))}
                            </AvField>

                            {!GeneralUtils.isNullOrEmpty(this.state.memberBillingAddressId) &&
                                <Button color="outline-primary"
                                        style={{marginRight: '5px', height: '30px', width: '160px'}}
                                        onClick={() => {
                                            this.memberBillingAddressService.updateDefaultAddress(this.state.memberBillingAddressId)
                                                .then((response) => {
                                                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                        this.setState({
                                                            memberBillingAddressSaveButtonRender: false,
                                                            memberBillingAddressId: null,
                                                            memberBillingAddress: Object.assign({}, defaultMemberBillingAddress),
                                                            memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress)

                                                        });
                                                        this.refreshCombo();
                                                    }
                                                });

                                        }}>
                                    <Translate contentKey="entity.action.cancel">Set as default</Translate>
                                </Button>
                            }


                            <ModalConfirm header='Warning' ok='Delete' onOkClick={this.deleteBillingAddress}
                                          onCancelClick={() => this.setState({displayBillingDeleteConfirmDialog: false})}
                                          show={this.state.displayBillingDeleteConfirmDialog}
                                          message={this.state.noticeResponse}/>
                            {!GeneralUtils.isNullOrEmpty(this.state.memberBillingAddressId) &&
                                <Button color="outline-danger"
                                        style={{marginRight: '5px', height: '30px', width: '160px'}}
                                        onClick={() => {
                                            this.showBillingDeleteConfirmDialog();
                                        }}>
                                    <Translate contentKey="entity.action.delete">Remove</Translate>
                                </Button>
                            }

                        </Col>
                    </Row>

                </Fieldset>

                <Fieldset legend={"Billing Address Details"}>


                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Post Code
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="postCode"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    value={this.state.memberBillingAddress.postCode}
                                    onChange={this.__handleChangeBillingAddress}>
                                </AvField>


                            </AvGroup>

                        </Col>

                        <Col md={3}>


                            <Button color="outline-primary"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    style={{marginRight: '5px', height: '30px'}}
                                    onClick={() => {
                                        this.searchBillingAddress();
                                    }}>
                                <Translate contentKey="entity.action.add">Lookup</Translate>
                            </Button>
                        </Col>


                        <Col md={3}>
                            <AvField
                                type="select"
                                disabled={!this.state.memberBillingAddressSaveButtonRender}
                                className="form-control"
                                name="tax"
                                value={this.state.addressId}
                                onChange={this.__handleChangeBillingPostCodeList}>
                                <option value='' key=''/>
                                {this.state.billingFilteredAddresses.map(element => (
                                    <option value={element.displayString} key={element.displayString}>
                                        {element.displayString}
                                    </option>))}
                            </AvField>

                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">City
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberBillingAddress.city}
                                    onChange={this.__handleChangeBillingAddress}/>
                            </AvGroup>
                        </Col>

                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Label
                                    </Translate>
                                </Label>

                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    value={this.state.memberBillingAddress.description}
                                    onChange={this.__handleChangeBillingAddress}>
                                </AvField>

                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 1
                                    </Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine1"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 50,
                                            errorMessage: "You can enter up to 50 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberBillingAddress.addressLine1}
                                    onChange={this.__handleChangeBillingAddress}/>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 2
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine2"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberBillingAddress.addressLine2}
                                    onChange={this.__handleChangeBillingAddress}/>
                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 3
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine3"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberBillingAddress.addressLine3}
                                    onChange={this.__handleChangeBillingAddress}/>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="email">
                                    <Translate contentKey="merchantBranch.tradingName">Address Line 4
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="addressLine4"
                                    disabled={!this.state.memberBillingAddressSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 100 characters in this field."
                                        }
                                    }}
                                    value={this.state.memberBillingAddress.addressLine4}
                                    onChange={this.__handleChangeBillingAddress}/>
                            </AvGroup>
                        </Col>
                    </Row>


                    {!this.state.memberBillingAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    this.setState({
                                        memberBillingAddressSaveButtonRender: true,
                                        memberBillingAddress: Object.assign({}, defaultMemberBillingAddress),
                                        memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress),
                                        memberBillingAddressId: null
                                    });
                                }}>
                            <Translate contentKey="entity.action.add">Add</Translate>
                        </Button>}

                    {!GeneralUtils.isNullOrEmpty(this.state.memberBillingAddressId) && !this.state.memberBillingAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    this.setState({memberBillingAddressSaveButtonRender: true});
                                }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.memberBillingAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    this.saveMemberBillingAddress();
                                }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {this.state.memberBillingAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                onClick={() => {
                                    let obj1 = this.state.memberBillingAddressTemp;
                                    this.setState({
                                        memberBillingAddress: Object.assign({}, obj1),
                                        memberBillingAddressSaveButtonRender: false
                                    });
                                }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }


                </Fieldset>

            </Col>
        </Row>);
    }

    __handleChangePostCodeList = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;

        if (!GeneralUtils.isNullOrEmpty(value)) {

            var data = this.getAddressDetail(value);
            if (!GeneralUtils.isNullOrEmpty(data) && data.length > 0) {
                let memberUserAddress = this.state.memberUserAddress;
                memberUserAddress["postCode"] = data[0].postCode;
                memberUserAddress["description"] = data[0].postCode;
                memberUserAddress["addressLine1"] = data[0].addressLines[0];
                memberUserAddress["addressLine2"] = data[0].addressLines[1];
                memberUserAddress["addressLine3"] = data[0].addressLines[2];
                memberUserAddress["addressLine4"] = data[0].addressLines[3];
                memberUserAddress["city"] = data[0].postTown;

                this.setState({
                    memberUserAddress: memberUserAddress,
                    filteredAddresses: []
                });
            }

        } else {

        }
    }

    getAddressDetail = (displayString) => {
        return this.state.filteredAddresses.filter(function (el) {
            return el.displayString == displayString;
        });
    }

    __handleChangeBillingPostCodeList = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;

        if (!GeneralUtils.isNullOrEmpty(value)) {

            var data = this.getBillingAddressDetail(value);
            if (!GeneralUtils.isNullOrEmpty(data) && data.length > 0) {
                let memberBillingAddress = this.state.memberBillingAddress;
                memberBillingAddress["postCode"] = data[0].postCode;
                memberBillingAddress["description"] = data[0].postCode;
                memberBillingAddress["addressLine1"] = data[0].addressLines[0];
                memberBillingAddress["addressLine2"] = data[0].addressLines[1];
                memberBillingAddress["addressLine3"] = data[0].addressLines[2];
                memberBillingAddress["addressLine4"] = data[0].addressLines[3];
                memberBillingAddress["city"] = data[0].postTown;

                this.setState({
                    memberBillingAddress: memberBillingAddress,
                    billingFilteredAddresses: []
                });
            }

        } else {

        }
    }

    getBillingAddressDetail = (displayString) => {
        return this.state.billingFilteredAddresses.filter(function (el) {
            return el.displayString == displayString;
        });
    }

    searchAddress = () => {
        if (this.state.memberUserAddress.postCode.length >= 6) {
            this.addressService.getAddresses(localStorage.getItem("merchantBranchId"), this.state.memberUserAddress.postCode).then(response => {
                this.setState({filteredAddresses: response});

            });

        } else {
            this.setState({filteredAddresses: []});
        }

    }

    searchBillingAddress = () => {
        if (this.state.memberBillingAddress.postCode.length >= 6) {
            this.addressService.getAddresses(localStorage.getItem("merchantBranchId"), this.state.memberBillingAddress.postCode).then(response => {
                this.setState({billingFilteredAddresses: response});

            });

        } else {
            this.setState({billingFilteredAddresses: []});
        }

    }

    showDeleteConfirmDialog = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.memberUserAddressId)) {
            return toast.warn("Select an address!");
        } else {
            this.setState({
                displayDeleteConfirmDialog: true,
                noticeResponse: "The address will be removed. Do you want to continue?"
            });
        }
    }
    showBillingDeleteConfirmDialog = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.memberBillingAddressId)) {
            return toast.warn("Select an address!");
        } else {
            this.setState({
                displayBillingDeleteConfirmDialog: true,
                noticeResponse: "The address will be removed. Do you want to continue?"
            });
        }
    }

    deleteAddress = () => {

        if (!GeneralUtils.isNullOrEmpty(this.state.memberUserAddressId)) {
            this.memberUserAddressService.delete(this.state.memberUserAddressId)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            memberUserAddressSaveButtonRender: false,
                            memberUserAddressId: null,
                            memberUserAddress: Object.assign({}, defaultMemberUserAddress),
                            memberUserAddressTemp: Object.assign({}, defaultMemberUserAddress)

                        });
                        this.refreshCombo();
                    }
                });
        }
    }
    deleteBillingAddress = () => {

        if (!GeneralUtils.isNullOrEmpty(this.state.memberBillingAddressId)) {
            this.memberBillingAddressService.delete(this.state.memberBillingAddressId)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            memberBillingAddressSaveButtonRender: false,
                            memberBillingAddressId: null,
                            memberBillingAddress: Object.assign({}, defaultMemberBillingAddress),
                            memberBillingAddressTemp: Object.assign({}, defaultMemberBillingAddress)

                        });
                        this.refreshCombo();
                    }
                });
        }
    }

    saveMemberUserAddress = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.postCode) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.city) ||
            GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.addressLine1)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.memberUserAddress.id)) {
                this.memberUserAddressService.update(this.state.memberUserAddress)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                memberUserAddressSaveButtonRender: false,
                                memberUserAddress: Object.assign({}, response),
                                memberUserAddressTemp: Object.assign({}, response)

                            });
                            this.refreshCombo();
                        }
                    });
            } else {
                this.memberUserAddressService.create(this.state.memberUserAddress)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                memberUserAddressSaveButtonRender: false,
                                memberUserAddress: Object.assign({}, response),
                                memberUserAddressTemp: Object.assign({}, response)
                            });
                            this.refreshCombo();
                        }
                    });
            }
        }
    }

    saveMemberBillingAddress = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.postCode) ||
            GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.city) ||
            GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.addressLine1)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.memberBillingAddress.id)) {
                this.memberBillingAddressService.update(this.state.memberBillingAddress)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                memberBillingAddressSaveButtonRender: false,
                                memberBillingAddress: Object.assign({}, response),
                                memberBillingAddressTemp: Object.assign({}, response)

                            });
                            this.refreshCombo();
                        }
                    });
            } else {
                this.memberBillingAddressService.create(this.state.memberBillingAddress)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                memberBillingAddressSaveButtonRender: false,
                                memberBillingAddress: Object.assign({}, response),
                                memberBillingAddressTemp: Object.assign({}, response)
                            });
                            this.refreshCombo();
                        }
                    });
            }
        }
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberUserAddress = this.state.memberUserAddress;
        memberUserAddress[e.target.name] = value;
        this.setState({memberUserAddress: memberUserAddress});
    }
    __handleChangeBillingAddress = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberBillingAddress = this.state.memberBillingAddress;
        memberBillingAddress[e.target.name] = value;
        this.setState({memberBillingAddress: memberBillingAddress});

    }

}


