import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MemberBillingAddressService extends Service {
    constructor() {
        super();
        this.baseURL = 'memberbilling/address/';
    }

    async updateDefaultAddress(id) {
        return fetch(SERVER_URL + this.baseURL + "updateDefaultAddress/id/" + id, {
            crossDomain: true,
            method: 'put',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async createWithoutNotification( data) {
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
        });
    }



}