import React from 'react';

class Translate extends React.Component {
    render() {
        const {contentKey} = this.props;
        return (<span>{this.props.children}</span>);
    }
}

export default Translate;

export const translate = (contentKey, interpolate, children) => {
    return contentKey.split('.').pop();
};