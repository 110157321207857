import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import DashboardMember from "../../module/dasboard/DashboardMember";
import MyOrders from "../../module/order/MyOrders";
import ApproveOrder from "../../module/order/ApproveOrder";
import MemberUserCard from "../../module/member/MemberUserCard";
import ComplaintSuggestion from "../../module/member/ComplaintSuggestion";
import MemberUser from "../../module/member/MemberUser";
import LoginMember from "../../module/login/LoginMember";
import MemberUserAddress from "../../module/member/MemberUserAddress";
import MarketPlace from "../../module/dasboard/MarketPlace";
import ConfirmOrder from "../../module/order/ConfirmOrder";

const HashRouterCompMember = (props) => (<BrowserRouter basename="/">
    <div>
        <Switch>
            <Route exact path='/approveOrder' component={ApproveOrder}/>
            <Route exact path='/confirmOrder' component={ConfirmOrder}/>
            <Route exact path='/login' component={LoginMember}/>
            <Route exact path='/address' component={MemberUserAddress}/>
            <Route exact path='/myOrders' component={MyOrders}/>
            <Route exact path='/accountDetails' component={MemberUser}/>
            <Route exact path='/paymentMethods' component={MemberUserCard}/>
            <Route exact path='/complaintSuggestion' component={ComplaintSuggestion}/>
            <Route exact path='/marketplace' component={MarketPlace}/>
            <Route exact path='/tableNo/:id' component={DashboardMember}/>
            <Route exact path='/:name' component={DashboardMember}/>
            <Route exact path='/' component={DashboardMember}/>
        </Switch>

    </div>
</BrowserRouter>);


export default HashRouterCompMember;
