import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import WebMenuService from "../../shared/service/WebMenuService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {Card} from "primereact/card";
import {BreadCrumb} from "primereact/breadcrumb";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {RadioButton} from "primereact/radiobutton";
import AvField from "../../shared/component/form/AvField";
import {AutoComplete} from "primereact/autocomplete";
import ProductService from "../../shared/service/ProductService";
import ProductGroupService from "../../shared/service/ProductGroupService";
import {toast} from "react-toastify";
import {MENU_DISPLAY_TYPE, YESNO} from "../../shared/utils/Constants";
import ProductDataWareHouseService from "../../shared/service/ProductDataWareHouseService";

const defaultWebMenu = {
    id: '',
    parentId: '',
    productId: '',
    longname: '',
    name: '',
    status: 'ACTIVE',
    fileType: '',
    fileName: 'Choose',
    fileTypeTill: '',
    fileNameTill: 'Choose',
    grpContent: null,
    grpContentX: null,
    hasSubMenu: false,
    subTitle: null,
    productTags: [],
    grpMain: null,
    grpOption1: null,
    grpOption2: null,
    grpOption3: null,
    grpOption4: null,
    grpOption1Label: 'opt1',
    grpOption2Label: 'opt2',
    grpOption3Label: 'opt3',
    grpOption4Label: 'opt4',
    kitchenPrinter1: '',
    kitchenPrinter2: '',
    kitchenPrinter3: '',
    kitchenPrinter4: '',
    fontSize: '3.0vmin',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
    menuStyleHeightFlex: 1,
    rowNo: 1,
    colNo: 1,
    borderRadius: '1vmin',
    pageSize: 1,
    rowSize: 1,
    spaceLength: null,
    pinned: false,
    lining: false,
    dummy: false,
    displaySiblings: false,
    displayGrandChilds: false,
    tillIdList: '',
    showInline: false,
    singleLine: false,
    condiment: false,
    printOrder: 0,
    webEnabled: true


};

export default class WebMenuList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webMenu: defaultWebMenu,
            webMenuList: [],
            originalWebMenuList: [],
            crumbItems: [],
            selectValue: 'Barcode',
            mainGroupList: [],
            productList: [],
            searchHasImage: null,
            barcode: '',
            searchBarcode: '',
            selectedProduct: null,
            productId: null,
            webMenuId: null
        }
        this.webMenuService = new WebMenuService();
        this.productService = new ProductService();
        this.productGroupService = new ProductGroupService();
        this.productDataWareHouseService = new ProductDataWareHouseService();
    }

    async componentDidMount() {
        let id = window.location.href.split('?')[1];
        if (!GeneralUtils.isNullOrEmpty(id)) {
            const str = sessionStorage.getItem('crumbItems');
            if (!GeneralUtils.isNullOrEmpty(str)) {
                const parsedArray = JSON.parse(str);
                var crumbItems = [];
                for (let data of parsedArray) {
                    crumbItems.push({
                        label: data.label, id: data.id, order: data.order, command: (e) => {
                            this.refreshCrumbItems(data)
                            this.getWebMenuList(data.id);
                        }
                    })
                }
                await this.setState({
                    crumbItems: crumbItems,
                });
            } else {
                await this.setState({
                    crumbItems: [],
                });
            }
            this.refresh();
        } else {
            this.refresh();
        }

    }

    refresh() {
        this.setState({
            barcode: '',
            selectedProduct: null,
            selectValue: 'Barcode',
            productId: null,
            webMenuId: null,
            searchHasImage:null,
            searchBarcode:null

        });

        let parentId = this.getParentId();
        if (GeneralUtils.isNullOrEmpty(parentId)) {
            this.webMenuService.findParents().then(response => this.setState({
                webMenuList: response,
                originalWebMenuList: response
            }));
        } else {
            this.webMenuService.findSubMenusByParentId(parentId).then(response => this.setState({
                webMenuList: response,
                originalWebMenuList: response
            }));
        }

        this.productGroupService.findAll().then(response => this.setState({mainGroupList: response}));


    }

    render() {
        return (<div>
            <AvForm>
                {this.webMenuList()}
            </AvForm>
        </div>);

    }

    renderSearchItems() {
        return (<div class="p-col-12">

                <Fieldset legend="Search Webmenu">
                    <Row>
                        <Col md="3">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="productGroup.tradingName">Barcode</Translate>
                                </Label>
                                <AvField
                                    type="number"
                                    name="searchBarcode"
                                    className="form-control"
                                    disabled={false}
                                    max="99999999999999"
                                    value={this.state.searchBarcode}
                                    onChange={this.__handleChangeSearchBarcode}/>
                            </AvGroup>
                        </Col>

                        <Col md="3">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="productGroup.tradingName">Has Image?</Translate>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="searchHasImage"
                                    disabled={false}
                                    value={this.state.searchHasImage}
                                    onChange={this.__handleChangeSearchHasImage}>
                                    <option value='' key=''/>
                                    {YESNO.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>

                </Fieldset>
            </div>
        )
    }

    renderSelectItems() {

        return (<div class="p-col-12">
            <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                          show={this.state.displayShowStatusConfirmDialog}
                          message={this.state.noticeResponse}/>

            <Fieldset legend="Select Product">


                <Row>
                    <Col md={2}>


                        <div className="flex align-items-center">
                            <RadioButton inputId="ingredient1" value="Barcode" onChange={(e) => {
                                this.setState({
                                    selectValue: e.value,
                                });


                            }} checked={this.state.selectValue === 'Barcode'}/>
                            <label htmlFor="ingredient1" className="ml-2">Barcode</label>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="flex align-items-center">
                            <RadioButton inputId="ingredient2" value="Name" onChange={(e) => {
                                this.setState({
                                    selectValue: e.value,
                                });


                            }} checked={this.state.selectValue === 'Name'}/>
                            <label htmlFor="ingredient2" className="ml-2">Name</label>
                        </div>
                    </Col>
                    <Col md={2}>


                        <div className="flex align-items-center">
                            <RadioButton inputId="ingredient3" value="Product Group" onChange={(e) => {
                                this.setState({
                                    selectValue: e.value,
                                });


                            }} checked={this.state.selectValue === 'Product Group'}/>
                            <label htmlFor="ingredient3" className="ml-2">Product Group</label>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col md={6}>

                        <Row>
                            {this.state.selectValue === "Barcode" &&
                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Barcode</Translate>
                                        </Label>
                                        <AvField
                                            type="number"
                                            name="barcode"
                                            className="form-control"
                                            disabled={false}
                                            max="99999999999999"
                                            onKeyDown={this._handleKeyDown}
                                            value={this.state.barcode}
                                            onChange={this.__handleChangeBarcode}/>
                                    </AvGroup>
                                </Col>
                            }
                            {this.state.selectValue === "Name" &&
                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Name</Translate>
                                        </Label>
                                        <AutoComplete value={this.state.selectedProduct}
                                                      suggestions={this.state.filteredProducts}
                                                      completeMethod={this.searchProduct}
                                                      field="longname"
                                                      disabled={false}
                                                      onChange={(e) => {
                                                          let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
                                                          this.setState({selectedProduct: value});
                                                          if (!GeneralUtils.isNullOrEmpty(e.value.id)) {

                                                              this.webMenuService.addItemToWebMenu(this.getParentId(), e.value.id, -1).then((response) => {
                                                                  if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                                      this.refresh();
                                                                  }
                                                              });

                                                          }
                                                      }}

                                        />
                                    </AvGroup>
                                </Col>
                            }
                        </Row>

                        <Row>
                            {this.state.selectValue === "Product Group" &&
                                <Col md="6">

                                    <AvGroup>

                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Main Group</Translate>
                                        </Label>
                                        <AvField type="select" className="form-control" name="grpMain"
                                                 disabled={false}
                                                 value={this.state.webMenu.grpMain}
                                                 onChange={this.__handleChangeMainGroup}>
                                            <option value='' key=''/>
                                            {this.state.mainGroupList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            }
                            {this.state.selectValue === "Product Group" &&
                                <Col md="6">
                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Product</Translate>
                                        </Label>
                                        <AvField type="select" className="form-control" name="productId"
                                                 disabled={false}
                                                 value={this.state.webMenu.productId}
                                                 onChange={this.__handleChangeProduct}>
                                            <option value='' key=''/>
                                            {this.state.productList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.longname}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            }

                        </Row>


                    </Col>

                </Row>


            </Fieldset>

        </div>)
    };

    renderItems() {

        let form = [];


        if (GeneralUtils.checkPrivilege("WebMenu", "View")) {
            form.push(this.renderCrumbs());
            form.push(this.state.crumbItems.length > 0 && this.renderSearchItems())
            form.push(this.state.crumbItems.length > 0 && this.renderSelectItems())
            form.push(
                <Row>
                    {this.renderCards()}
                </Row>
            );
        }

        return form;
    }

    renderCrumbs() {
        const home = {
            icon: 'pi pi-home', id: -1, command: (e) => {
                this.webMenuService.findParents().then(response => this.setState({
                    webMenuList: response,
                    originalWebMenuList: response
                }));
                this.setState({
                    crumbItems: []
                });

            }
        }


        if (this.state.crumbItems.length > 0)
            return (
                <div className="card" style={{border: '0'}}>
                    <BreadCrumb model={this.state.crumbItems} home={home}/>
                </div>
            );
    }

    refreshCrumbItems(itemSub) {
        let crumbItems = this.state.crumbItems;
        const objWithIdIndex = crumbItems.findIndex((obj) => obj.order === itemSub.order);
        if (objWithIdIndex > -1) {
            crumbItems.length = objWithIdIndex + 1;
        }
        this.setState({crumbItems: crumbItems});
    }

    renderAddNewIconHandler(e, item) {
        this.onItemClick(item);
        e.stopPropagation();
    }

    renderEditIconHandler(e, item) {
        const jsonArray = JSON.stringify(this.state.crumbItems);
        sessionStorage.setItem("crumbItems", jsonArray);
        window.location.href = "#webMenuSave?" + item.id;
        e.stopPropagation();
    }

    renderEditIcon(item) {
        if (GeneralUtils.checkPrivilege("WebMenu", "Add")) {
            return <Button color="outline-primary" onClick={(e) => this.renderEditIconHandler(e, item)}
                           style={{border: '0', marginLeft: '0em', marginTop: '1em'}}>
                <Translate contentKey="entity.action.cancel">Details</Translate>
            </Button>
        }

    }

    renderAddNewIcon(item) {
        if (GeneralUtils.checkPrivilege("WebMenu", "Add") && item.hasSubMenu) {
            return <Button color="outline-primary" onClick={(e) => this.renderAddNewIconHandler(e, item)}
                           style={{border: '0', marginLeft: '0em', marginTop: '1em'}}>
                <Translate contentKey="entity.action.cancel">SubMenu</Translate>
            </Button>
        }

    }

    renderName(itemSub) {
        return (
            <AvGroup>
                <Label for="tradingName">
                    <Translate contentKey="webMenu.tradingName">Name</Translate>
                </Label>
                <AvField
                    type="text"
                    className="form-control"
                    name="name"
                    disabled={false}
                    validate={{
                        maxLength: {
                            value: 255,
                            errorMessage: "You can enter up to 255 characters in this field."
                        }
                    }}
                    value={itemSub.name}
                    onChange={(e) => this.__handleChange(e, itemSub)}/>

            </AvGroup>


        )
    }

    renderMenuSorting(itemSub) {
        if (itemSub.menuDisplayTypeParent != "e.menuDisplayNo, e.name") {
            return;
        } else {
            return (


                <AvGroup>
                    <Label for="firstName">
                        <Translate contentKey="productfirstName">Menu Sorting</Translate>
                    </Label>


                    <AvField
                        type="select"
                        className="form-control"
                        name="menuDisplayTypeParent"
                        disabled={true}
                        value={itemSub.menuDisplayTypeParent}
                        onChange={(e) => this.__handleChange(e, itemSub)}>
                        {MENU_DISPLAY_TYPE.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>))}
                    </AvField>


                </AvGroup>


            )
        }

    }

    renderMenuSortingNo(itemSub) {
        if (itemSub.menuDisplayTypeParent == "e.menuDisplayNo, e.name")
            return (


                <AvGroup>
                    <Label for="firstName">
                        <Translate contentKey="productfirstName">Menu Sorting No</Translate>
                    </Label>

                    <AvField
                        name="menuDisplayNo"
                        type="number"
                        disabled={false}
                        value={itemSub.menuDisplayNo}
                        onChange={(e) => this.__handleChange(e, itemSub)}
                        className="table-responsive"

                    />


                </AvGroup>
            )
    }


    renderSubMenuSorting(itemSub) {
        if (itemSub.hasSubMenu)
            return (


                <AvGroup>
                    <Label for="firstName">
                        <Translate contentKey="productfirstName">SubMenu Sorting</Translate>
                    </Label>


                    <AvField
                        type="select"
                        className="form-control"
                        name="menuDisplayType"
                        disabled={false}
                        value={itemSub.menuDisplayType}
                        onChange={(e) => this.__handleChange(e, itemSub)}>
                        {MENU_DISPLAY_TYPE.map(element => (
                            <option value={element.value} key={element.value}>
                                {element.name}
                            </option>))}
                    </AvField>


                </AvGroup>


            )
    }

    saveHandler(e, item) {
        if (GeneralUtils.isNullOrEmpty(item.name)) {
            return toast.warn("Please fill the name field!");
        } else {
            if (GeneralUtils.checkPrivilege("WebMenu", "Add")) {
                this.webMenuService.updateWebMenuDisplay(item).then(response => {
                    this.refresh();
                });
            }
        }


        e.stopPropagation();
    }

    editHandler = (e, item) => {
        this.setState({
            webMenuId: item.id,
        })

        e.stopPropagation();
    }

    renderMenuSortingEdit(itemSub) {
        if (this.state.webMenuId == null) {
            return <Button color="outline-primary" onClick={(e) => this.editHandler(e, itemSub)}
                           style={{border: '0', marginLeft: '0em'}}>
                <Translate contentKey="entity.action.cancel">Edit</Translate>
            </Button>
        }

    }

    renderMenuSortingSave(itemSub) {
        return <Button color="outline-primary" onClick={(e) => this.saveHandler(e, itemSub)}
                       style={{border: '0', marginLeft: '0em'}}>
            <Translate contentKey="entity.action.cancel">Save</Translate>
        </Button>

    }

    __handleChange = (e, itemSub) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        itemSub[e.target.name] = value;
    }


    renderCards() {
        let form = [];
        if (this.state.webMenuList != undefined && !GeneralUtils.isNullOrEmpty(this.state.webMenuList) && this.state.webMenuList.length > 0) {
            this.state.webMenuList.forEach(itemSub => {
                form.push(
                    <div

                        style={{
                            margin: "10px 10px 10px 30px",
                            cursor: "pointer",
                            borderLeft: "6px solid #00bcd4 !important;"
                        }}>
                        <Card className={"check"} hoverable
                              style={{width: '28em', height: '24em'}}>
                            <Fieldset legend={itemSub.name}>
                                <Row>
                                    <Col md={12}>
                                        {this.renderName(itemSub)}
                                    </Col>
                                    <Col md={6}>
                                        {this.renderMenuSorting(itemSub)}
                                    </Col>
                                    <Col md={6}>
                                        {this.renderMenuSortingNo(itemSub)}
                                    </Col>
                                    <Col md={6}>
                                        {this.renderSubMenuSorting(itemSub)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        {this.renderMenuSortingSave(itemSub)}
                                    </Col>
                                </Row>


                            </Fieldset>

                            <Row>
                                <Col md={4}>
                                    {this.renderEditIcon(itemSub)}
                                </Col>
                                <Col md={4}>
                                    {this.renderAddNewIcon(itemSub)}
                                </Col>

                            </Row>


                        </Card>
                    </div>
                )
                ;
            });
        }
        return form;
    }

    webMenuList() {

        return (<div>
            <Fieldset legend="Web Menus">

                {GeneralUtils.checkPrivilege("WebMenu", "Add") &&
                    <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                        this.addNew();
                    }}>
                        <Translate contentKey="entity.action.add">Add Menu</Translate>
                    </Button>
                }


                {this.renderItems()}

            </Fieldset>


        </div>)
    }
    ;

    onDataSelect = (data) => {
        const jsonArray = JSON.stringify(this.state.crumbItems);
        sessionStorage.setItem("crumbItems", jsonArray);
        window.location.href = "#webMenuSave?" + data.id;
    }

    onItemClick = async (data) => {
        let crumbItems = this.state.crumbItems;
        let length = crumbItems.length;
        if (data.hasSubMenu) {
            crumbItems.push({
                label: data.name, id: data.id, order: length, command: (e) => {
                    data.order = length;
                    this.refreshCrumbItems(data)
                    this.getWebMenuList(data.id);
                }
            })
            this.getWebMenuList(data.id);
        }

    }

    getParentId() {
        let parentId = null;
        let crumbItems = this.state.crumbItems;
        if (crumbItems.length > 0) {
            parentId = crumbItems[crumbItems.length - 1].id;
        }
        return parentId;

    }

    addNew() {
        let parentId = this.getParentId();
        const jsonArray = JSON.stringify(this.state.crumbItems);
        sessionStorage.setItem("crumbItems", jsonArray);
        window.location.href = "#webMenuSave?" + "?" + parentId;
    }

    getWebMenuList(value) {
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.webMenuService.findSubMenusByParentId(value).then(response => this.setState({
                webMenuList: response,
                originalWebMenuList: response
            }));


        }
    }

    showDeleteConfirmDialog = (data) => {
        this.setState({
            deleteDataId: data.id,
            displayShowStatusConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }

    delete = () => {

        if (!GeneralUtils.isNullOrEmpty(this.state.deleteDataId)) {
            this.webMenuService.delete(this.state.deleteDataId)
                .then((response) => {
                    this.setState({
                        deleteDataId: null,
                    });
                    this.refresh();
                });
        }
    }
    __handleChangeBarcode = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({barcode: value});
    }

    __handleChangeSearchBarcode = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = [];
        if (!GeneralUtils.isNullOrEmpty(value)) {
            data = this.state.webMenuList.filter(function (el) {
                return !GeneralUtils.isNullOrEmpty(el.productBarcode) && el.productBarcode.indexOf(value) != -1;
            });
        } else {
            data = this.state.originalWebMenuList;
        }

        this.setState({searchBarcode: value, webMenuList: data, searchHasImage: null});
    }


    __handleChangeSearchHasImage = async (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let data = [];

        if (!GeneralUtils.isNullOrEmpty(value)) {
            let originalWebMenuList = this.state.originalWebMenuList;
            let that = this;
            if (value == "true") {
                let arrayNew= [];
                for(var i = 0; i<originalWebMenuList.length;i++){
                    let hasImage = false;
                    let el = originalWebMenuList[i];

                    await that.productHasImage(el.productBarcode, el.productName).then((value) => {
                        hasImage = value;
                    });

                    if(!GeneralUtils.isNullOrEmpty(el.fileType) || !GeneralUtils.isNullOrEmpty(el.productFileType) || hasImage){
                        arrayNew.push(el);
                    }
                }
                this.setState({webMenuList: arrayNew});
            } else {

                 let arrayNew= [];
                 for(var i = 0; i<originalWebMenuList.length;i++){
                     let hasImage = false;
                     let el = originalWebMenuList[i];

                     await that.productHasImage(el.productBarcode, el.productName).then((value) => {
                         hasImage = value;
                     });

                     if(GeneralUtils.isNullOrEmpty(el.fileType) && GeneralUtils.isNullOrEmpty(el.productFileType) && hasImage == false){
                         arrayNew.push(el);
                     }
                 }

                this.setState({webMenuList: arrayNew});
            }

        } else {
            data = this.state.originalWebMenuList;
            this.setState({ webMenuList: data});
        }

        this.setState({searchHasImage: value,  searchBarcode: null});
    }

    async productHasImage(barcode, name) {
        let hasImage = false;
        const response = (await this.productDataWareHouseService.productHasImage(barcode, GeneralUtils.removeSlashes(name)));
        if (!GeneralUtils.isNullOrEmpty(response)) {
            hasImage = response;
        }
        return hasImage;
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.findByBarcode();
        }
    }

    searchProduct = (event) => {
        setTimeout(() => {
            this.productService.searchProductsByName(event.query).then(response => {
                this.setState({filteredProducts: response});

            });
        }, 250);
    }


    findByBarcode() {
        if (!GeneralUtils.isNullOrEmpty(this.state.barcode)) {
            this.productService.findProductByBarcode(this.state.barcode)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let data = response;
                        this.webMenuService.addItemToWebMenu(this.getParentId(), data.id, -1).then((response) => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                this.refresh();
                            }

                        });


                    } else {
                        this.setState({
                            barcode: '', selectedProduct: null, productId: null, productList: []
                        });
                        return toast.warn("Barcode not Found!");
                    }
                });
        }
        this.setState({barcode: ''});

    }

    __handleChangeMainGroup = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let webMenu = this.state.webMenu;
        webMenu[e.target.name] = value;
        this.setState({webMenu: webMenu});

        if (!GeneralUtils.isNullOrEmpty(value) && !GeneralUtils.isNullOrEmpty(this.state.mainGroupList)) {
            for (var i = 0; i < this.state.mainGroupList.length; i++) {
                if (this.state.mainGroupList[i].id == value) {
                    this.setState({productList: this.state.mainGroupList[i].products});
                }

            }
        } else {
            this.setState({productList: []});
        }
    }
    __handleChangeProduct = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.webMenuService.addItemToWebMenu(this.getParentId(), value, this.state.webMenu.grpMain).then((response) => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.refresh();
                }
            });
        }

    }


}

