import Service from './Service';
import {
    SERVER_URL,
    SERVER_URL2,
    SERVER_URL_PRODUCT_DATAWARE_HOUSE,
    SERVER_URL_PRODUCT_DATAWAREHOUSE
} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ProductDataWareHouseService extends Service {
    constructor() {
        super();
        this.baseURL = 'product/';
    }


    async productHasImage(barcode,name) {
        return fetch(SERVER_URL_PRODUCT_DATAWAREHOUSE + this.baseURL + "hasImage/barcode/" + barcode + "/name/"+ name, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }




}